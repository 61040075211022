export const ItemsTypes = {
  CREATE_ITEM_REQUEST: 'items/CREATE_ITEM_REQUEST',
  CREATE_VOICE_ITEM_REQUEST: 'items/CREATE_VOICE_ITEM_REQUEST',
  REMOVE_VOICE_ITEM_REQUEST: 'items/REMOVE_VOICE_ITEM_REQUEST',
  CLONE_VOICE_ITEM_REQUEST: 'items/CLONE_VOICE_ITEM_REQUEST',
  UPDATE_ITEM_IN_LIST_STATE: 'items/UPDATE_ITEM_IN_LIST_STATE',
  REMOVE_ITEM_IN_LIST_STATE: 'items/REMOVE_ITEM_IN_LIST_STATE',
  CHANGE_ITEM_ANCESTOR_REQUEST: 'items/CHANGE_ITEM_ANCESTOR_REQUEST',
  UPDATE_SELECTED_ITEM: 'items/UPDATE_SELECTED_ITEM',
  CREATE_ITEM_SUCCESS: 'items/CREATE_ITEM_SUCCESS',
  EDIT_ITEM: 'items/EDIT_ITEM',
  LIST_ITEMS_REQUEST: 'items/LIST_ITEMS_REQUEST',
  LIST_ITEMS_SUCCESS: 'items/LIST_ITEMS_SUCCESS',
  REMOVE_ITEM_REQUEST: 'items/REMOVE_ITEM_REQUEST',
  CLONE_ITEM_REQUEST: 'items/CLONE_ITEM_REQUEST',
  REMOVE_ITEM_SUCCESS: 'items/REMOVE_ITEM_SUCCESS',
  SET_CURRENT_ITEM: 'items/SET_CURRENT_ITEM',
  SET_CURRENT_PATH: 'items/SET_CURRENT_PATH',
  SET_SELECTED_MENU_OPTION: 'items/SET_SELECTED_MENU_OPTION',
  EDIT_FILE_ITEM_REQUEST: 'items/EDIT_FILE_ITEM_REQUEST',
  REMOVE_FILE_ITEM_REQUEST: 'items/REMOVE_FILE_ITEM_REQUEST',
  EDIT_CARD_IMAGE_REQUEST: 'items/EDIT_CARD_IMAGE_REQUEST',
  EDIT_ITEM_UPLOAD_PROGRESS: 'items/EDIT_ITEM_UPLOAD_PROGRESS',
  EDIT_URL_ITEM_REQUEST: 'items/EDIT_URL_ITEM_REQUEST',
  ITEM_REMOVE_NOTIFICATION_SHOW: 'items/ITEM_REMOVE_NOTIFICATION_SHOW',
  ITEM_REMOVE_NOTIFICATION_REVERT: 'items/ITEM_REMOVE_NOTIFICATION_REVERT',
  ITEM_REMOVE_NOTIFICATION_DISMISS: 'items/ITEM_REMOVE_NOTIFICATION_DISMISS',
  ITEM_CONNECTOR_REMOVE_NOTIFICATION_SHOW:
    'items/ITEM_REMOVE_CONNECTOR_NOTIFICATION_SHOW',
  ITEM_CONNECTOR_REMOVE_NOTIFICATION_REVERT:
    'items/ITEM_CONNECTOR_REMOVE_NOTIFICATION_REVERT',
  ITEM_CHANGE_CURRENT_FLOW: 'items/ITEM_CHANGE_CURRENT_FLOW',
  LIST_SUBFLOW_ITEMS_REQUEST: 'items/LIST_SUBFLOW_ITEMS_REQUEST',
  LIST_ALL_ITEMS_REQUEST: 'items/LIST_ALL_ITEMS_REQUEST',
  LIST_ALL_ITEMS_SUCCESS: 'items/LIST_ALL_ITEMS_SUCCESS',
  UPDATE_ALL_ITEMS: 'items/UPDATE_ALL_ITEMS',
  RESET_ITEMS: 'items/RESET_ITEMS',
};

/* Actions */

export const ItemsCreators = {
  createItemRequest: (botid, item, rootId) => ({
    type: ItemsTypes.CREATE_ITEM_REQUEST,
    payload: {
      botid,
      item,
      rootId,
    },
  }),

  createVoiceItemRequest: ({ botid, item }) => ({
    type: ItemsTypes.CREATE_VOICE_ITEM_REQUEST,
    payload: {
      botid,
      item,
    },
  }),

  removeVoiceItemRequest: (item) => ({
    type: ItemsTypes.REMOVE_VOICE_ITEM_REQUEST,
    payload: { item },
  }),

  cloneVoiceItemRequest: (item, targetWidth) => ({
    type: ItemsTypes.CLONE_VOICE_ITEM_REQUEST,
    payload: { item, targetWidth },
  }),

  updateItemInListState: ({ item }) => ({
    type: ItemsTypes.UPDATE_ITEM_IN_LIST_STATE,
    payload: { item },
  }),

  removeItemInListState: ({ item }) => ({
    type: ItemsTypes.REMOVE_ITEM_IN_LIST_STATE,
    payload: { item },
  }),

  createItemSuccess: (item) => ({
    type: ItemsTypes.CREATE_ITEM_SUCCESS,
    payload: item,
  }),

  updateSelectedItem: (item) => ({
    type: ItemsTypes.UPDATE_SELECTED_ITEM,
    payload: item,
  }),

  removeItemRequest: (item) => ({
    type: ItemsTypes.REMOVE_ITEM_REQUEST,
    payload: item,
  }),

  cloneItemRequest: (item, targetWidth) => ({
    type: ItemsTypes.CLONE_ITEM_REQUEST,
    payload: { item, targetWidth },
  }),

  removeItemSuccess: (itemid) => ({
    type: ItemsTypes.REMOVE_ITEM_SUCCESS,
    payload: itemid,
  }),

  editItem: (payload) => ({
    type: ItemsTypes.EDIT_ITEM,
    payload,
  }),

  listItemsRequest: (botId) => ({
    type: ItemsTypes.LIST_ITEMS_REQUEST,
    payload: botId,
  }),

  listItemsSuccess: (items) => ({
    type: ItemsTypes.LIST_ITEMS_SUCCESS,
    payload: items,
  }),

  setCurrentItem: (item) => ({
    type: ItemsTypes.SET_CURRENT_ITEM,
    payload: item,
  }),

  setCurrentPath: (item) => ({
    type: ItemsTypes.SET_CURRENT_PATH,
    payload: item,
  }),

  setSelectedMenuOption: (payload) => ({
    type: ItemsTypes.SET_SELECTED_MENU_OPTION,
    payload,
  }),

  editItemFileRequest: (botId, itemid, file, item) => ({
    type: ItemsTypes.EDIT_FILE_ITEM_REQUEST,
    payload: {
      botId,
      itemid,
      file,
      item,
    },
  }),

  removeItemFileRequest: (botId, itemid, item) => ({
    type: ItemsTypes.REMOVE_FILE_ITEM_REQUEST,
    payload: {
      botId,
      itemid,
      item,
    },
  }),

  editCardImageRequest: ({ file, item, cardId }) => ({
    type: ItemsTypes.EDIT_CARD_IMAGE_REQUEST,
    payload: { file, item, cardId },
  }),

  editItemUploadProgress: (item, progress) => ({
    type: ItemsTypes.EDIT_ITEM_UPLOAD_PROGRESS,
    payload: {
      item,
      progress,
    },
  }),

  editItemUrlRequest: (botId, itemid, url, item) => ({
    type: ItemsTypes.EDIT_URL_ITEM_REQUEST,
    payload: {
      botId,
      itemid,
      url,
      item,
    },
  }),

  itemRemoveNotificationShow: (payload) => ({
    type: ItemsTypes.ITEM_REMOVE_NOTIFICATION_SHOW,
    payload,
  }),
  itemRemoveNotificationRevert: (payload) => ({
    type: ItemsTypes.ITEM_REMOVE_NOTIFICATION_REVERT,
    payload,
  }),
  itemRemoveNotificationDismiss: (payload) => ({
    type: ItemsTypes.ITEM_REMOVE_NOTIFICATION_DISMISS,
    payload,
  }),
  itemConnectorRemoveNotificationShow: (payload) => ({
    type: ItemsTypes.ITEM_CONNECTOR_REMOVE_NOTIFICATION_SHOW,
    payload,
  }),
  itemConnectorRemoveNotificationRevert: (payload) => ({
    type: ItemsTypes.ITEM_CONNECTOR_REMOVE_NOTIFICATION_REVERT,
    payload,
  }),

  changeCurrentFlow: (payload) => ({
    type: ItemsTypes.ITEM_CHANGE_CURRENT_FLOW,
    payload,
  }),

  getSubflowItems: (payload) => ({
    type: ItemsTypes.LIST_SUBFLOW_ITEMS_REQUEST,
    payload,
  }),

  getAllItems: (payload) => ({
    type: ItemsTypes.LIST_ALL_ITEMS_REQUEST,
    payload,
  }),

  updateAllItems: (payload) => ({
    type: ItemsTypes.UPDATE_ALL_ITEMS,
    payload,
  }),

  resetItems: () => ({
    type: ItemsTypes.RESET_ITEMS,
  }),
};
