import axios from 'axios';

export const API_TOKEN = 'API_TOKEN';

const url = process.env.REACT_APP_API_URL || 'http://localhost:3333';

axios.defaults.baseURL = `${url}/api`;

export const AxiosWithInterceptors = axios.create({
  timeout: 10000,
  headers: {
    common: { authorization: `Bearer ${localStorage.getItem(API_TOKEN)}` },
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers':
      'Origin, X-Requested-With, Content-Type, Accept',
  },
});

AxiosWithInterceptors.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem(API_TOKEN);
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export const AxiosWithoutInterceptors = axios.create();

export default AxiosWithInterceptors;
