/* eslint-disable import/no-cycle */
import { call, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import store from '..';
import api from '../../services/api';
import { UserTypes, UserActions } from '../actions/users';
import { PermissionTypes } from '../actions/permissions';

export function* getPermissions() {
  try {
    const { data } = yield call(api.get, `/v1/profiles/list`);

    const filtro = data.filter((element) => element.name !== 'Root');

    yield put({
      type: PermissionTypes.GET_PERMISSIONS_SUCCESS,
      payload: filtro,
    });
  } catch (e) {
    console.log('getUsers erro: ', e);
    toastr.error('Erro', 'Ocorreu um erro obtendo as permissões.');
  }
}

export function* updatePermission(action) {
  const { payload } = action;
  try {
    const { _id, claim, organizationIds, codeUser, companyId } = payload;

    yield call(api.put, `/v1/admins/update/${_id}`, {
      _id,
      claim,
      organizations: organizationIds,
      codeUser,
      companyId,
    });

    yield put({ type: PermissionTypes.UPDATE_PERMISSION_SUCCESS });
    store.dispatch(UserActions.getUsers());

    toastr.success('Sucesso', 'Usuário atualizado com sucesso.');
  } catch (e) {
    console.log('updatePermission erro: ', e);
    toastr.error('Erro', 'Ocorreu um erro atualizando usuário.');
  }
}
