import * as random from 'random-int';
import {
  ValidationType,
  ValidationTypeExp,
  ValidationTypeExpFlags,
} from './validation-type';

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  b64Data = b64Data
    .replace('data:application/pdf;base64,', '')
    .replace('data:application/zip;base64,', '');
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const stopPropagation = (e) => {
  // console.log('stopPropagation')
  if (e) {
    if (e.stopPropagation) {
      e.stopPropagation(); // W3C model
    } else {
      e.cancelBubble = true; // IE model
    }
  }
};

export const generateSecretToken = (maxDigits = 6) => {
  if (maxDigits === 0) {
    maxDigits = 1;
  }

  let token = '';

  for (let i = 0; i < maxDigits; i++) {
    token += Math.abs(random(-9, 9));
  }

  return token;
};

export const isEmail = (str) => {
  const reg = new RegExp(
    ValidationTypeExp[ValidationType.Email],
    ValidationTypeExpFlags[ValidationType.Email]
  );
  return reg.test(str);
};

export const isHost = (str) => {
  const reg = new RegExp(
    ValidationTypeExp[ValidationType.Url],
    ValidationTypeExpFlags[ValidationType.Url]
  );
  return reg.test(str);
};

export const isPassa = (str) => {
  const reg = new RegExp(
    ValidationTypeExp[ValidationType.Any],
    ValidationTypeExpFlags[ValidationType.Any]
  );
  return reg.test(str);
};

export function formatBytes(bytes, decimals = 2) {

  const parseBytes = parseFloat(bytes);
  if (parseBytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(parseBytes) / Math.log(k));

  return parseFloat((parseBytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

// export const inputOnlyText = (input: any, callback: (value: string) => void) => {
//   input.keyup(function(e) {
//     // a-z => allow all lowercase alphabets
//     // A-Z => allow all uppercase alphabets
//     const regex = /^[a-zA-Z\u00C0-\u00FF ]+$/
//     if (regex.test(this.value) !== true) {
//       // You can replace the invalid characters by:
//       this.value = this.value.replace(/[^a-zA-Z\u00C0-\u00FF ]+/, '');
//       callback(this.value)
//     }
//   })
// }
