import { call, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import api from '../../services/api';
import { PlanTypes } from '../actions/plans';

export function* getPlans() {
  try {
    const { data } = yield call(api.get, `v1/plans`);

    yield put({
      type: PlanTypes.GET_PLANS_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error(e);
    toastr.error('Erro', 'Ocorreu um erro ao buscar os planos');
  }
}

export function* getHistoryCompanyPlan(action) {
  const { payload } = action;
  const { companyId } = payload;
  try {
    const { data } = yield call(api.get, `v1/plans/history/${companyId}`);
    yield put({
      type: PlanTypes.GET_HISTORY_COMPANY_PLAN_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error(e);
    toastr.error('Erro', 'Ocorreu um erro ao buscar o histórico do plano');
  }
}

export function* editAlert(action) {
  const { payload } = action;
  const { companyId, alertsA } = payload;
  try {
    const { data } = yield call(
      api.put,
      `/v1/companies/plans/alerts/${companyId}`,
      {
        alerts: alertsA,
      }
    );
    yield put({
      type: PlanTypes.PUT_ALERTS_SUCCESS,
      payload: data,
    });
    toastr.success('Sucesso', 'O alerta foi salvo com sucesso');
  } catch (e) {
    toastr.error('Erro', 'Ocorreu um erro ao atualizar o alerta');
  }
}

export function* getPlanConsumption({ payload }) {
  try {
    const params = {
      company: payload.companyId,
      date: payload.date,
      timezone: payload.timezone,
      isFreemium: payload.isFreemium || false,
      isReport: payload.isReport || false,
    };

    const { data } = yield call(
      api.get,
      `/v1/companies/plans/consumption/bots`,
      { params }
    );
    yield put({
      type: PlanTypes.GET_PLAN_CONSUMPTION_SUCCESS,
      payload: data,
    });
  } catch (e) {
    toastr.error('Erro', 'Ocorreu um erro ao obter os dados de consumo!');
  }
}
