import React, {useState} from 'react';
// import {withRouter} from 'react-router-dom'

const Footer = props => {
  // console.log('Location: ', props.location.pathname)
  const [showFooter] = useState(false)
  return showFooter && <footer className="row linha-rodape">
    <div className="col-md-12">
      <p>YAKY FAQ Bot. Powered by Code7 2</p>
    </div>
  </footer>
};

export default Footer;
