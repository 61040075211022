import { call, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import api from '../../services/api';
import { RdStationTypes } from '../actions/rd-station';
import { CompanyTypes } from '../actions/companies';

import { commonActions } from '../actions/common';

const baseUrl = 'https://api.rd.services';

const RD_STATION_APP = {
  dev: {
    clientId: 'bd2a1408-0251-46be-8392-a4f54d5b17eb',
    partnersUrl: 'https://6ccb-45-160-89-222.ngrok.io/partner/rd/code',
  },
  homolog: {
    clientId: 'afccc1c9-1cdb-459a-b340-7adf7c71e540',
    partnersUrl: 'https://api.partners.xlab.work/partner/rd/code',
  },
  prod: {
    clientId: 'ebb385db-d304-4337-ae12-7bb72e50fbb8',
    partnersUrl: 'https://api.partners.code7.com/partner/rd/code',
  },
};

const currentRdStationApp =
  RD_STATION_APP[process.env.REACT_APP_RD_STATION.toLocaleLowerCase()];

const rdStationInfos = {
  partnersUrl: currentRdStationApp.partnersUrl,
  authUrl: `${baseUrl}/auth/dialog`,
  clientId: currentRdStationApp.clientId,
};

export function* onCodeExpires(error) {
  const { companyId } = error?.response?.data?.organization;

  yield put({
    type: CompanyTypes.GET_ORGANIZATIONS,
    payload: { companyId },
  });
  toastr.warning(
    'Aviso',
    'A sessão da integração com RD Station expirou, por favor fazer login novamente'
  );
}

export function rdStationLogin({ payload }) {
  try {
    const { authUrl, clientId, partnersUrl } = rdStationInfos;
    // const callbackUrl = encodeURIComponent(partnersUrl);
    const url = `${authUrl}?client_id=${clientId}&redirect_uri=${partnersUrl}`;

    window.open(url, '_blank');
  } catch (error) {
    console.error(error);
    toastr.error('Erro', 'Falha ao tentar fazer login');
  }
}

export function* generateAccessToken({ payload }) {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });
    const { botId, code } = payload;
    const url = `v1/bots/${botId}/rd-station/auth`;

    const { data } = yield call(api.post, url, { code });

    yield put({
      type: RdStationTypes.GENERATE_ACCESS_TOKEN_SUCCESS,
      payload: data,
    });
    toastr.success('Sucesso', 'Novo access token criado');
  } catch (error) {
    console.error(error);
    if (error?.response?.data?.expiredCode) {
      yield onCodeExpires(error);
    } else {
      toastr.error('Erro', 'Falha ao gerar um novo access token');
    }
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* deleteAccessToken({ payload }) {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });
    const { botId } = payload;
    const url = `v1/bots/${botId}/rd-station/auth`;

    yield call(api.delete, url);

    yield put({
      type: RdStationTypes.GENERATE_ACCESS_TOKEN_SUCCESS,
      payload: null,
    });
    toastr.success('Sucesso', 'Access Token removido');
  } catch (error) {
    console.error(error);
    toastr.error('Erro', 'Falha ao remover um access token');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* getFields({ payload }) {
  try {
    const { botId } = payload;
    const url = `v1/bots/${botId}/rd-station/fields`;
    const { data } = yield call(api.get, url);

    yield put({
      type: RdStationTypes.GET_FIELDS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.error(error);
    if (error?.response?.data?.expiredCode) {
      yield onCodeExpires(error);
    } else {
      toastr.error('Erro', 'Falha ao buscar pelos campos customizados');
    }
  }
}
