import { TemplateBotTypes } from '../actions/template-bot';

const INITIAL_STATE = {
  templateBotData: {},
  newBotTemplateBot: {},
  allTemplateBots: [],
  lastDeletedTemplates: [],
};

export default function templateBot(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TemplateBotTypes.CREATE_TEMPLATE_BOT_SUCCESS: {
      return { ...state, templateBotData: action.payload };
    }
    case TemplateBotTypes.UPDATE_TEMPLATE_BOT_SUCCESS: {
      return { ...state };
    }
    case TemplateBotTypes.EMPTY_TEMPLATE_BOT_SUCCESS: {
      return { ...state, templateBotData: {} };
    }
    case TemplateBotTypes.GET_TEMPLATE_BOT_SUCCESS: {
      return { ...state, templateBotData: action.payload };
    }
    case TemplateBotTypes.GET_ALL_TEMPLATE_BOTS_SUCCESS: {
      return { ...state, allTemplateBots: action.payload };
    }
    case TemplateBotTypes.GET_TEMPLATE_BOT_BY_ID_SUCCESS: {
      return { ...state, newBotTemplateBot: action.payload };
    }
    case TemplateBotTypes.SET_TEMPLATE_BOT_DATA_SUCCESS: {
      return { ...state, templateBotData: action.payload };
    }
    case TemplateBotTypes.DELETE_TEMPLATE_BOT_SUCCESS: {
      return { ...state };
    }
    case TemplateBotTypes.UPDATE_TEMPLATE_BOT_DETAILS_SUCCESS: {
      return { ...state };
    }

    default: {
      return state;
    }
  }
}
