import {call, put, select} from 'redux-saga/effects'

import api from '../../services/api'
import actions from '../actions'
import {delay} from '../../util/delay'
import {isFinished, captureAnswer, lastState, currentState, findNextState} from '../ducks/preview-bot'
import {getListItems, getVariables} from '../ducks/items'
import {toastr} from 'react-redux-toastr'

const {PreviewBotTypes} = actions

export function* saveMessage(action) {
  const item = yield select(lastState)

  try {
    const payload = {
      botMessage: {
        ...action.payload.message,
        item: item.itemId,
        bot: item.botId
      },
    }
    yield call(api.post, '/v1/bots/messages', payload)
  } catch (error) {
    console.log('saveMessage error', error)
  }
  yield call(prepareNextMessage, true)
}

export function* saveFileMessage(action) {
  const item = yield select(currentState)
  console.log('saveFileMessage >>>>> ', item)

  try {
    const formData = new FormData()
    formData.append('file', action.payload.file)
    formData.append('from', action.payload.from)
    formData.append('when', action.payload.message.when)
    formData.append('body', action.payload.message.body)

    const {data} = yield call(
      api.put,
      `/v1/bots/${item.botId}/messages/${item._id}/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: yield progressEvent => {
          const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          put({
            type: PreviewBotTypes.previewSendUserMessageFileProgress,
            payload: {item: item, progress: percentage}
          });
        }
      }
    );

    if (data.error) {
      toastr.error('Ops', 'Algo de errado aconteceu ao realizar o upload para o servidor.');
    } else {
      console.log('', 'Arquivo do usuário enviado com sucesso!');
      yield call(prepareNextMessage, true)
    }
  } catch (error) {
    toastr.error('Ops', 'Algo de errado aconteceu ao editar o arquivo do item.');
  }

}

export function* startBot() {
  yield put({type: PreviewBotTypes.previewResetMessages})
}

export function* resetBot() {
  const states = yield select(getListItems)
  const variables = yield select(getVariables)

  yield put({type: PreviewBotTypes.previewSetStateMachine, payload: {states, variables}})
  yield call(prepareNextMessage)
}

export function* nextState() {
  yield call(prepareNextMessage)
}

function* prepareNextMessage(force) {
  const waitAnswer = yield select(captureAnswer)
  const endState = yield select(isFinished)
  const next = yield select(findNextState)
  // console.log(`waitAnswer: ${waitAnswer}, endState: ${endState}, hasNext: ${next !== null && next !== undefined}`)
  // console.log('prepareNextMessage: ', next)
  if (!(waitAnswer || endState) || force) {
    if (next) {
      yield delay(500)
      yield put({type: PreviewBotTypes.previewSendBotTypping})
      const typing = Math.max(100, next && next.timeTypping ? next.timeTypping * 1000 : 1000)
      // console.log('typing', typing)
      yield delay(typing)
      yield put({type: PreviewBotTypes.previewSendBotTyppingStop})
      yield delay(100)
    }
    yield put({type: PreviewBotTypes.previewSendBotMessage})
  }
}

export function* sendBotMessage() {
  yield put({type: PreviewBotTypes.previewSendBotMessage})
}


export function* closeBot() {
  yield put({type: PreviewBotTypes.previewResetMessages})
}

// Isso não faz sentido. Só funcionaria para um fluxo linear.
// const orderStates = (states) => {
//   const filteredStates = states.filter(state => { return !state.orphan } );
//   const firstState = filteredStates.filter(state => typeof state.ancestorItem === "undefined")[0];
//   const orderedStates = [];
//
//   let currentState = firstState;
//
//   if (currentState) {
//     do {
//       orderedStates.push(currentState);
//       const firstChildrenId = currentState.children ? currentState.children[0] : null;
//
//       currentState = filteredStates.filter(state => state._id === firstChildrenId)[0];
//     } while(currentState);
//   }
//
//   console.log('orderStates', orderedStates)
//
//   return orderedStates;
// };
