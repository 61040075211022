import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _ from 'lodash';

// import { commonActions } from '../actions/common';
import { newBotTypes } from '../actions/new-bot';

/**
 * Set the new current template bot that will be tested on preview
 * @param {Object} action
 */
export function* setCurrentTemplateBotTesting(action) {
  yield put({
    type: newBotTypes.SET_NEW_BOT_CURRENT_TEMPLATE_BOT_TESTING_SUCCESS,
    payload: { ...action.payload },
  });
}

/**
 * Set the new current template bot selected for create a bot from
 * @param {Object} action
 */
export function* setCurrentTemplateBotSelected(action) {
  try {
    yield put({
      type: newBotTypes.SET_NEW_BOT_TEMPLATE_BOT_SELECTED_SUCCESS,
      payload: { ...action.payload },
    });
  } finally {
    if (!_.isEmpty(action.payload)) {
      const url = `/novo-bot/criacao/${action.payload._id}`;
      yield put(push(url));
    }
  }

  // try {
  //   yield put({ type: commonActions.LOADING, payload: { loading: true } });
  // } finally {
  //   yield put({ type: commonActions.LOADING, payload: { loading: false } });
  // }
}

export function* pushToCreateBot() {
  yield put(push('/novo-bot/criacao'));
}
