import { call, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import api from '../../services/api';
import {
  fetchProjectsSuccess,
  fetchGrammarsSuccess,
  fetchStatusSuccess,
  fetchVoicesSuccess,
  fetchVoicesFailiure,
  fetchVoicebotInstancesSuccess,
  fetchVoicebotInstancesFailiure,
  createVoicebotInstanceSuccess,
  updateVoicebotInstanceSuccess,
  fetchVoicebotVariablesSuccess,
  fetchVoicebotWebApiSuccess,
} from '../actions/voice-bots';

import { commonLoadingStart, commonLoadingFinish } from '../actions/common';

export function* fetchProjects({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { params } = payload;

    const { data } = yield call(api.get, `/v1/voice-bots/projects`, {
      params,
      timeout: 30000,
    });

    yield put(fetchProjectsSuccess({ projects: data }));
  } catch (e) {
    console.error(e);
    toastr.error('Erro', 'Ocorreu um erro ao buscar os projetos');
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchGrammars({ payload }) {
  try {
    const { params } = payload;

    const { data } = yield call(api.get, `/v1/voice-bots/grammars`, {
      params,
      timeout: 30000,
    });

    yield put(fetchGrammarsSuccess({ grammars: data }));
  } catch (e) {
    console.error(e);
    toastr.error('Erro', 'Ocorreu um erro ao buscar as gramáticas');
  }
}

export function* fetchStatus({ payload }) {
  try {
    const { params } = payload;

    const { data } = yield call(api.get, `/v1/voice-bots/status`, {
      params,
      timeout: 30000,
    });

    yield put(fetchStatusSuccess({ status: data }));
  } catch (e) {
    console.error(e);
    toastr.error('Erro', 'Ocorreu um erro ao buscar os status');
  }
}

export function* fetchVoices({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { params } = payload;

    const { data } = yield call(api.get, `/v1/voice-bots/voices`, {
      params,
      timeout: 30000,
    });

    yield put(fetchVoicesSuccess({ voices: data }));
  } catch (e) {
    yield put(fetchVoicesFailiure());
    toastr.error('Erro', 'Ocorreu um erro ao buscar a lista de vozes');
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchVoicebotInstances({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { organizationId } = payload;

    const { data } = yield call(api.get, `/v1/voice-bots/instance`, {
      params: { organizationId },
      timeout: 30000,
    });

    yield put(fetchVoicebotInstancesSuccess({ instances: data }));
  } catch (e) {
    yield put(fetchVoicebotInstancesFailiure());
    toastr.error('Erro', 'Ocorreu um erro ao buscar as instâncias');
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createVoicebotInstance({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { instance, callback } = payload;

    const { data } = yield call(api.post, `/v1/voice-bots/instance`, instance);

    yield put(createVoicebotInstanceSuccess(data));

    if (callback) {
      callback();
    }
  } catch (err) {
    const errorMessage = err.response?.data?.message;
    toastr.error(
      'Erro',
      errorMessage || 'Ocorreu um erro ao criar a instância'
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateVoicebotInstance({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { instance, callback } = payload;

    const { data } = yield call(
      api.put,
      `/v1/voice-bots/instance/${instance?._id}`,
      instance
    );

    yield put(updateVoicebotInstanceSuccess(data));

    if (callback) {
      callback();
    }
  } catch (err) {
    const errorMessage = err.response?.data?.message;
    toastr.error(
      'Erro',
      errorMessage || 'Ocorreu um erro ao atualizar a instância'
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchVoicebotVariables({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { params } = payload;

    const { data } = yield call(api.get, `/v1/voice-bots/variables`, {
      params,
      timeout: 30000,
    });

    yield put(fetchVoicebotVariablesSuccess({ variables: data }));
  } catch (e) {
    toastr.error('Erro', 'Ocorreu um erro ao buscar as variáveis');
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchVoicebotWebApiList({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { params } = payload;

    const { data } = yield call(api.get, `/v1/voice-bots/web-api`, {
      params,
      timeout: 30000,
    });

    yield put(fetchVoicebotWebApiSuccess({ webApiList: data }));
  } catch (e) {
    toastr.error('Erro', 'Ocorreu um erro ao buscar as webservices');
  } finally {
    yield put(commonLoadingFinish());
  }
}
