import { ReportsTypes } from '../actions/reports';

/* Reducer */

const INITIAL_STATE = {
  botClients: [],
  botClientsGraphic: [],
  botMessagesByChannel: [],
  botTotalMessagesByChannel: [],
  botMessages: [],
  botMessagesWithAvg: [],
  botSessions: [],
  botSessionsGraphic: [],
  attendanceTime: {},
  botAttendanceTimeGraphic: [],
  sessionAttandanceTime: {},
  orgBotsNumber: [],
  orgBotsNumberGraphic: [],
  orgBotsTotalMessages: [],
  orgBotsTotalMessagesGraphic: [],
  orgBotsTotalSessions: [],
  orgBotsTotalSessionsGraphic: [],
  orgBotsTotalUsers: [],
  orgBotsTotalUsersGraphic: [],
  orgListUsers: [],
  orgBotsTotalMessageByChannel: [],
  orgBotsTotalMessageByChannelGraphic: [],
  orgUsersBotsTotal: [],
  orgUsersBotsAverage: [],
  companyMessagesPerBot: [],
  dashboardReport: {},
  botSessionTags: [],
  botCustomReports: [],
  errorLogs: {
    docs: [],
    page: 1,
    totalPages: 1,
    limit: 20,
  },
};

export default function reports(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ReportsTypes.GET_CLIENTS_REPORTS_SUCCESS:
      return { ...state, botClients: action.payload };
    case ReportsTypes.GET_CLIENTS_REPORTS_GRAPHIC_SUCCESS:
      return { ...state, botClientsGraphic: action.payload };
    case ReportsTypes.GET_SESSIONS_REPORTS_SUCCESS:
      return { ...state, botSessions: action.payload };
    case ReportsTypes.GET_SESSIONS_REPORTS_GRAPHIC_SUCCESS:
      return { ...state, botSessionsGraphic: action.payload };
    case ReportsTypes.GET_SESSION_ATTENDANCE_TIME_REPORTS_SUCCESS:
      return { ...state, sessionAttandanceTime: action.payload };
    case ReportsTypes.GET_ATTENDANCE_TIME_REPORTS_SUCCESS:
      return { ...state, attendanceTime: action.payload };
    case ReportsTypes.GET_ATTENDANCE_TIME_REPORTS_GRAPHIC_SUCCESS:
      return { ...state, botAttendanceTimeGraphic: action.payload };
    case ReportsTypes.GET_AMOUNT_MESSAGE_CHANNEL_REPORTS_SUCCESS:
      return { ...state, botMessagesByChannel: action.payload };
    case ReportsTypes.GET_TOTAL_MESSAGE_CHANNEL_REPORTS_SUCCESS:
      return { ...state, botTotalMessagesByChannel: action.payload };
    case ReportsTypes.GET_BOT_MESSAGES_SUCCESS:
      return { ...state, botMessages: action.payload };
    case ReportsTypes.GET_BOT_MESSAGES_AVG_SUCCESS:
      return { ...state, botMessagesWithAvg: action.payload };
    case ReportsTypes.GET_ORG_BOTS_COUNT_SUCCESS:
      return { ...state, orgBotsNumber: action.payload };
    case ReportsTypes.GET_ORG_BOTS_GRAPHIC_SUCCESS:
      return { ...state, orgBotsNumberGraphic: action.payload };
    case ReportsTypes.GET_ORG_TOTAL_MESSAGES_SUCCESS:
      return { ...state, orgBotsTotalMessages: action.payload };
    case ReportsTypes.GET_ORG_TOTAL_MESSAGES_GRAPHIC_SUCCESS:
      return { ...state, orgBotsTotalMessagesGraphic: action.payload };
    case ReportsTypes.GET_ORG_TOTAL_SESSIONS_SUCCESS:
      return { ...state, orgBotsTotalSessions: action.payload };
    case ReportsTypes.GET_ORG_TOTAL_SESSIONS_GRAPHIC_SUCCESS:
      return { ...state, orgBotsTotalSessionsGraphic: action.payload };
    case ReportsTypes.GET_ORG_TOTAL_USERS_SUCCESS:
      return { ...state, orgBotsTotalUsers: action.payload };
    case ReportsTypes.GET_ORG_TOTAL_USERS_GRAPHIC_SUCCESS:
      return { ...state, orgBotsTotalUsersGraphic: action.payload };
    case ReportsTypes.GET_ORG_LIST_USERS_SUCCESS:
      return { ...state, orgListUsers: action.payload };
    case ReportsTypes.GET_ORG_MESSAGES_BY_CHANNEL_SUCCESS:
      return { ...state, orgBotsTotalMessageByChannel: action.payload };
    case ReportsTypes.GET_ORG_MESSAGES_BY_CHANNEL_GRAPHIC_SUCCESS:
      return { ...state, orgBotsTotalMessageByChannelGraphic: action.payload };
    case ReportsTypes.GET_ORG_USERS_BOTS_SUCCESS:
      return { ...state, orgUsersBotsTotal: action.payload };
    case ReportsTypes.GET_ORG_USERS_BOTS_AVERAGE_SUCCESS:
      return { ...state, orgUsersBotsAverage: action.payload };
    case ReportsTypes.CREATE_DASHBOARD_REPORT_SUCCESS:
    case ReportsTypes.UPDATE_DASHBOARD_REPORT_SUCCESS:
    case ReportsTypes.GET_DASHBOARD_REPORT_SUCCESS:
      return { ...state, dashboardReport: action.payload };
    case ReportsTypes.GET_COMPANY_MESSAGES_PER_BOT_SUCCESS:
      return { ...state, companyMessagesPerBot: action.payload };
    case ReportsTypes.GET_BOT_SESSION_TAGS_SUCCESS:
      return { ...state, botSessionTags: action.payload };
    case ReportsTypes.GET_BOT_CUSTOM_REPORTS_SUCCESS:
      return { ...state, botCustomReports: action.payload };
    case ReportsTypes.GET_ERROR_LOGS_SUCCESS:
      return {
        ...state,
        errorLogs: action.payload,
      };
    default:
      return state;
  }
}
