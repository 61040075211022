import { call, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { UserTypes } from '../actions/users';
import { commonActions } from '../actions/common';
import history from '../../routes/history';
import api, { API_TOKEN } from '../../services/api';

export function* me() {
  try {
    const { data } = yield call(api.get, `v1/users/me`);

    yield put({ type: UserTypes.GET_ME_SUCCESS, payload: { user: data } });
  } catch (e) {
    console.log('me erro: ', e);
    toastr.error('Erro', 'Ocorreu um erro obtendo os dados do usuário.');
  }
}

export function* getUsers(action) {
  try {
    const params =
      action && action.payload && action.payload.searchTerm
        ? `?term=${action.payload.searchTerm}`
        : '';

    const { data } = yield call(api.get, `v1/users${params}`);

    yield put({ type: UserTypes.GET_USERS_SUCCESS, payload: data });
  } catch (e) {
    console.log('getUsers erro: ', e);
    toastr.error('Erro', 'Ocorreu um erro obtendo os usuários.');
  }
}

export function* createUser(action) {
  try {
    const { data } = yield call(api.post, `/v1/admins/create`, action.payload);
    yield put({ type: UserTypes.CREATE_USER_SUCCESS, payload: data });
    yield put({ type: UserTypes.GET_USERS });

    toastr.success('Sucesso', 'Usuário criado com sucesso');
  } catch (e) {
    console.log('createUser erro: ', e);
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* updateUser(action) {
  const { payload } = action;
  try {
    const { data } = yield call(api.put, `v1/users/update/${payload._id}`, {
      ...payload,
    });
    toastr.success('Sucesso', 'Usuário atualizado com sucesso.');
    yield put({ type: UserTypes.UPDATE_USER_SUCCESS, payload: data });
  } catch (e) {
    console.log('createCompany erro: ', e);
    toastr.error('Erro', 'Ocorreu um erro atualizando usuário.');
  } finally {
    history.push('/home');
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* selectOrganization(action) {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });
    const { data } = yield call(
      api.put,
      `v1/users/select-organization`,
      action.payload
    );

    const { _id } = data;

    const response = yield call(api.post, `v1/users/token/refresh/${_id}`);

    const { token } = response.data;

    localStorage.setItem(API_TOKEN, token);
    api.defaults.headers.common.authorization = `Bearer ${token}`;

    yield put({
      type: UserTypes.SELECT_ORGANIZATION_SUCCESS,
      payload: { user: data },
    });

    // toastr.success('Sucesso', 'Troca de setores efetuada');
  } catch (e) {
    console.log('selectOrganization erro: ', e);
    toastr.error('Erro', 'Ocorreu um erro selecionando organização.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* selectCompanyOrganization(action) {
  try {
    const { selectedCompany, selectedOrganization } = action.payload;

    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const { data } = yield call(
      api.put,
      `v1/users/select-company-organization`,
      action.payload
    );

    if (selectedCompany && selectedOrganization) {
      const { _id } = data;

      const response = yield call(api.post, `v1/users/token/refresh/${_id}`);

      const { token } = response.data;

      localStorage.setItem(API_TOKEN, token);
      api.defaults.headers.common.authorization = `Bearer ${token}`;
    }

    yield put({
      type: UserTypes.SELECT_COMPANY_ORGANIZATION_SUCCESS,
      payload: { user: data },
    });
  } catch (e) {
    toastr.error('Erro', 'Ocorreu um erro selecionando a empresa.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}
