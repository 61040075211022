import { ConnectorsTypes } from '../actions/connectors';

/* Reducer */

const INITIAL_STATE = {
  list: [],
  allConnectors: [],
};

export default function connectors(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ConnectorsTypes.GET_CONNECTORS_SUCCESS:
      return { ...state, list: action.payload };

    case ConnectorsTypes.LIST_ALL_CONNECTORS_SUCCESS:
      return { ...state, allConnectors: action.payload };

    case ConnectorsTypes.CREATE_CONNECTOR_SUCCESS:
      return { ...state, list: [...state.list, action.payload] };

    case ConnectorsTypes.DELETE_CONNECTOR_SUCCESS:
      return {
        ...state,
        list: state.list.filter(
          (connector) => connector._id !== action.payload.id
        ),
      };

    default:
      return state;
  }
}
