import actions from '../actions';

const initialState = {
  users: [],
  userEditing: undefined,
  signedUser: undefined,
  signedUserIsRoot: false,
};

export default function usersState(state = initialState, action) {
  switch (action.type) {
    case actions.UserTypes.GET_ME_SUCCESS:
    case actions.authActions.AUTH_LOGIN: {
      const { claim } = action.payload.user;
      const isRoot = claim.find((e) => e.name === 'Root');
      return {
        ...state,
        signedUser: { ...action.payload.user },
        signedUserIsRoot: isRoot?.name === 'Root',
      };
    }
    case actions.UserTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        users: [...action.payload],
      };
    case actions.UserTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
      };
    case actions.UserTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
      };

    case actions.UserTypes.UPDATE_SIGNED_USER_STATE:
      return {
        ...state,
        signedUser: { ...action.payload.user },
      };

    case actions.UserTypes.SELECT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        signedUser: { ...action.payload.user },
      };

    case actions.UserTypes.SELECT_COMPANY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        signedUser: { ...action.payload.user },
      };
    default:
      return {
        ...state,
      };
  }
}
