export const BotCreatorTypes = {
  CHANGE_CONNECTORS_ON_ERROR: 'bot-creator/CHANGE_CONNECTORS_ON_ERROR',
  SET_ITEMS_ON_ERROR: 'bot-creator/SET_ITEMS_ON_ERROR',
  REMOVE_ITEMS_ON_ERROR: 'bot-creator/REMOVE_ITEMS_ON_ERROR',
  RESET_BOT_CREATOR: 'bot-creator/RESET_BOT_CREATOR',
  RESET_FLOW_PATH: 'bot-creator/RESET_FLOW_PATH',
};

export const changeConnectorsOnError = (payload) => ({
  type: BotCreatorTypes.CHANGE_CONNECTORS_ON_ERROR,
  payload,
});

export const setItemsOnError = (payload) => ({
  type: BotCreatorTypes.SET_ITEMS_ON_ERROR,
  payload,
});

export const removeItemsOnError = (id) => ({
  type: BotCreatorTypes.REMOVE_ITEMS_ON_ERROR,
  payload: { id },
});

export const resetBotCreator = () => ({
  type: BotCreatorTypes.RESET_BOT_CREATOR,
});

export const resetFlowPath = (data) => ({
  type: BotCreatorTypes.RESET_FLOW_PATH,
  payload: data,
});
