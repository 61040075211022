/* eslint-disable import/no-cycle */
import { all, takeLatest, takeEvery } from 'redux-saga/effects';

import { ConnectorsTypes } from '../actions/connectors';
import { DebounceTypes } from '../actions/debounce';
import { PlanTypes } from '../actions/plans';
import {
  create as createItem,
  createVoiceItem,
  removeVoiceItem,
  cloneVoiceItem,
  list as listItems,
  listSubflow,
  getAllItems,
  removeItem,
  cloneItem,
  editItem,
  editItemFile,
  editItemUrl,
  editCardImage,
  removeItemFile,
} from './items';

import {
  editBotTitle,
  list,
  newBot,
  newVoiceBot,
  updateVoiceBot,
  removeBot,
  removeVoiceBot,
  cloneVoiceBot,
  creatorBot,
  getBot,
  saveBotViewPosition,
  publishHtmlWidget,
  saveWidgetHtml,
  updateBot,
  historyMessage,
  filterSessions,
  filterDashboard,
  updateBotHumanAttendance,
  getBotQueueHumanAttendance,
  updateBotStatus,
  updateBotIaStatus,
  getBotCollectDataCount,
  getBotCollectDataCsv,
  resetBotCollectCount,
  openNewBotPage,
  updateBotGeneralInformation,
  updateZendeskTicketSettings,
  updateGlpiSettings,
  updateBotQuickAccess,
  updatePublishBotQuickAccess,
  updateJiraSettings,
  updateAttendanceHoursSettings,
  publishBot,
  publishVoiceBot,
  changeStagingArea,
  countStatusCreatorNoSave,
  countStatusCreatorNoSaveRemove,
} from './bots';

import {
  listCurationMessages,
  acceptCurationMessage,
  ignoreCurationMessage,
  listWit,
  listLuis,
  listIAEntities,
  listEntity,
  listIAIntents,
  listAllSamples,
  listSamplesIntent,
  createWit,
  createLuis,
  saveIASample,
  createIntents,
  deleteIntents,
  createCondition,
  updateCondition,
  getConditions,
  deleteCondition,
  createSamples,
  deleteSamples,
  createBoteria,
  createEntities,
  deleteEntities,
  deleteEntitiesObj,
  deleteEntitiesSyn,
  saveAprimoramentos,
  getIaInfo,
  trainLuis,
  iaPreview,
} from './intelligence';

import {
  getConnectors,
  deleteConnector,
  createConnector,
  createVoiceConnector,
  getConnectorsSubflow,
  getAllConnectors,
} from './connectors';

import { infoStateFilter, relatorioFilterInfo } from './filter';

import {
  getClientsReports,
  getClientsGraphicReports,
  getBotSessions,
  getAttendanceTime,
  getSessionAttendanceTime,
  getBotAttendanceTimeGraphic,
  getSessionsGraphicReports,
  getBotMessagesByChannel,
  getBotTotalMessagesByChannel,
  getBotQuantityMessagesData,
  getBotQuantityAndAverageMessageData,
  getCompanyBotsCount,
  getCompanyBotsGraphic,
  getCompanyTotalMessagesCount,
  getCompanyTotalMessagesGraphic,
  getCompanyTotalSessionsCount,
  getCompanyTotalSessionsGraphic,
  getCompanyTotalUsersCount,
  getCompanyTotalUsersGraphic,
  getCompanyListUsers,
  getCompanyMessagesByChannel,
  getCompanyMessagesByChannelGraphic,
  getCompanyUsersBots,
  getCompanyUsersBotsAverage,
  createDashboardReport,
  updateDashboardReport,
  getDashboardReport,
  getCompanyMessagesPerBot,
  getBotSessionTags,
  getBotCustomReports,
  createBotCustomReport,
  getErrorLogs,
} from './reports';

import {
  createTemplateBot,
  updateTemplateBot,
  updateTemplateBotDetails,
  emptyTemplateBot,
  getTemplateBot,
  getTemplateBotById,
  getAllTemplatesBot,
  setTemplateBotData,
  deleteTemplateBot,
} from './template-bot';

import {
  setFacebookAuthState,
  setFacebookSelectedPage,
} from './facebook-messenger';

import {
  setCurrentTemplateBotTesting,
  setCurrentTemplateBotSelected,
  pushToCreateBot,
} from './new-bot';

import * as iaReportsSagas from './iaReports';
import { IaReportTypes } from '../actions/iaReports';

import * as previewBotSaga from './preview-bot';
import { BotsTypes } from '../actions/bots';
import { PreviewBotTypes } from '../actions/preview-bot';
import { ItemsTypes } from '../actions/items';
import { FilterTypes } from '../actions/filter';
import { IaTypes } from '../actions/intelligence';
import { ReportsTypes } from '../actions/reports';

import actions from '../actions';
import {
  login,
  logout,
  setToken,
  notice,
  tfaSetup,
  tfaActive,
  tfaDisable,
  tfaVerify,
  resendEmailConfirmation,
} from './auth';

import { CompanyTypes } from '../actions/companies';
import * as companiesSaga from './companies';
import { UserTypes } from '../actions/users';
import * as usersSaga from './users';
import { PermissionTypes } from '../actions/permissions';
import * as permissionsSaga from './permissions';
import * as debounceSagas from './debounce';

import * as variablesSaga from './variables';
import { VariableTypes } from '../actions/variables';

import { facebookMessengerTypes } from '../actions/facebook-messenger';
import * as plansSagas from './plans';
import * as tagsSaga from './tags';
import { TagTypes } from '../actions/tags';

import * as notificationSagas from './notifications';
import { NotificationTypes } from '../actions/notifications';

import * as voiceBotsSagas from './voice-bots';
import { VoiceBotsTypes } from '../actions/voice-bots';

import * as voiceBotsTtsSagas from './voice-bots-tts';
import { VoiceBotsTtsTypes } from '../actions/voice-bots-tts';

import { TemplateBotTypes } from '../actions/template-bot';
import { newBotTypes } from '../actions/new-bot';

import * as triggerSagas from './triggers';
import { TriggerTypes } from '../actions/triggers';
// import { ConfigPageTypes } from '../actions/page-config';
// const { PreviewBotTypes } = actions;

import * as registerFromSagas from './register-from';
import { registerFromTypes } from '../actions/register-from';

import * as rdStationSagas from './rd-station';
import { RdStationTypes } from '../actions/rd-station';

export default function* rootSaga() {
  yield all([
    takeLatest(actions.AUTH_LOGIN, login),
    takeLatest(actions.AUTH_LOGOUT, logout),
    takeLatest(actions.AUTH_SET_TOKEN, setToken),
    takeLatest(actions.AUTH_NOTICE, notice),
    takeLatest(actions.AUTH_RESEND_EMAIL_CONFIRMATION, resendEmailConfirmation),

    // autenticação de dois fatores
    takeLatest(actions.TFA_SETUP, tfaSetup),
    takeLatest(actions.TFA_ACTIVE, tfaActive),
    takeLatest(actions.TFA_DISABLE, tfaDisable),
    takeLatest(actions.TFA_VERIFY, tfaVerify),
  ]);

  yield all([
    takeLatest(FilterTypes.LIST_FILTER_INFO, infoStateFilter),
    takeLatest(FilterTypes.FILTER_RELATORIO_INFO, relatorioFilterInfo),
  ]);

  yield all([
    takeLatest(VoiceBotsTypes.FETCH_PROJECTS, voiceBotsSagas.fetchProjects),
    takeLatest(VoiceBotsTypes.FETCH_GRAMMARS, voiceBotsSagas.fetchGrammars),
    takeLatest(VoiceBotsTypes.FETCH_STATUS, voiceBotsSagas.fetchStatus),
    takeLatest(VoiceBotsTypes.FETCH_VOICES, voiceBotsSagas.fetchVoices),
    takeLatest(
      VoiceBotsTypes.FETCH_VOICEBOT_INSTANCES,
      voiceBotsSagas.fetchVoicebotInstances
    ),
    takeLatest(
      VoiceBotsTypes.CREATE_VOICEBOT_INSTANCE,
      voiceBotsSagas.createVoicebotInstance
    ),
    takeLatest(
      VoiceBotsTypes.UPDATE_VOICEBOT_INSTANCE,
      voiceBotsSagas.updateVoicebotInstance
    ),
    takeLatest(
      VoiceBotsTypes.FETCH_VARIABLES_REQUEST,
      voiceBotsSagas.fetchVoicebotVariables
    ),
    takeLatest(
      VoiceBotsTypes.FETCH_WEB_API_REQUEST,
      voiceBotsSagas.fetchVoicebotWebApiList
    ),
  ]);

  yield all([
    takeLatest(
      VoiceBotsTtsTypes.UPLOAD_AUDIOS_REQUEST,
      voiceBotsTtsSagas.uploadAudios
    ),
    takeLatest(
      VoiceBotsTtsTypes.DELETE_AUDIO_REQUEST,
      voiceBotsTtsSagas.deleteAudio
    ),
    takeLatest(
      VoiceBotsTtsTypes.FETCH_AUDIOS_REQUEST,
      voiceBotsTtsSagas.fetchAudios
    ),
    takeLatest(
      VoiceBotsTtsTypes.GENERATE_TTS_REQUEST,
      voiceBotsTtsSagas.generateTts
    ),
  ]);

  yield all([
    takeLatest(VariableTypes.LIST, variablesSaga.list),
    takeLatest(VariableTypes.CREATE, variablesSaga.create),
    takeLatest(VariableTypes.EDIT, variablesSaga.edit),
    takeLatest(VariableTypes.REMOVE, variablesSaga.remove),
  ]);

  // -----------PLANOS---------
  yield all([takeLatest(PlanTypes.GET_PLANS_REQUEST, plansSagas.getPlans)]);
  yield all([
    takeLatest(
      PlanTypes.GET_HISTORY_COMPANY_PLAN_REQUEST,
      plansSagas.getHistoryCompanyPlan
    ),
  ]);
  yield all([takeLatest(PlanTypes.PUT_ALERTS, plansSagas.editAlert)]);
  yield all([
    takeLatest(PlanTypes.GET_PLAN_CONSUMPTION, plansSagas.getPlanConsumption),
  ]);

  // -----------TAGS---------
  yield all([
    takeLatest(TagTypes.LIST, tagsSaga.list),
    takeLatest(TagTypes.CREATE, tagsSaga.create),
    takeLatest(TagTypes.EDIT, tagsSaga.edit),
    takeLatest(TagTypes.REMOVE, tagsSaga.remove),
  ]);

  yield all([
    takeLatest(NotificationTypes.LIST, notificationSagas.list),
    takeLatest(NotificationTypes.UPDATE_STATUS, notificationSagas.updateStatus),
  ]);

  // -----------TEMPLATE BOT--------
  yield all([
    takeLatest(TemplateBotTypes.CREATE_TEMPLATE_BOT, createTemplateBot),
    takeLatest(TemplateBotTypes.UPDATE_TEMPLATE_BOT, updateTemplateBot),
    takeLatest(
      TemplateBotTypes.UPDATE_TEMPLATE_BOT_DETAILS,
      updateTemplateBotDetails
    ),
    takeLatest(TemplateBotTypes.GET_TEMPLATE_BOT, getTemplateBot),
    takeLatest(TemplateBotTypes.GET_TEMPLATE_BOT_BY_ID, getTemplateBotById),
    takeLatest(TemplateBotTypes.GET_ALL_TEMPLATE_BOTS, getAllTemplatesBot),
    takeLatest(TemplateBotTypes.EMPTY_TEMPLATE_BOT, emptyTemplateBot),
    takeLatest(TemplateBotTypes.SET_TEMPLATE_BOT_DATA, setTemplateBotData),
    takeLatest(TemplateBotTypes.DELETE_TEMPLATE_BOT, deleteTemplateBot),
  ]);

  // -----------NEW BOT--------
  yield all([
    takeLatest(
      newBotTypes.SET_NEW_BOT_CURRENT_TEMPLATE_BOT_TESTING,
      setCurrentTemplateBotTesting
    ),
    takeLatest(
      newBotTypes.SET_NEW_BOT_TEMPLATE_BOT_SELECTED,
      setCurrentTemplateBotSelected
    ),
    takeLatest(newBotTypes.PUSH_TO_CREATE_BOT, pushToCreateBot),
  ]);

  // -----------RELATORIOS---------

  yield all([
    takeLatest(ReportsTypes.GET_CLIENTS_REPORTS, getClientsReports),
    takeLatest(
      ReportsTypes.GET_CLIENTS_REPORTS_GRAPHIC,
      getClientsGraphicReports
    ),
    takeLatest(ReportsTypes.GET_SESSIONS_REPORTS, getBotSessions),
    takeLatest(
      ReportsTypes.GET_SESSIONS_REPORTS_GRAPHIC,
      getSessionsGraphicReports
    ),
    takeLatest(ReportsTypes.GET_ATTENDANCE_TIME_REPORTS, getAttendanceTime),
    takeLatest(
      ReportsTypes.GET_SESSION_ATTENDANCE_TIME_REPORTS,
      getSessionAttendanceTime
    ),
    takeLatest(
      ReportsTypes.GET_ATTENDANCE_TIME_REPORTS_GRAPHIC,
      getBotAttendanceTimeGraphic
    ),
    takeLatest(
      ReportsTypes.GET_AMOUNT_MESSAGE_CHANNEL_REPORTS,
      getBotMessagesByChannel
    ),
    takeLatest(
      ReportsTypes.GET_TOTAL_MESSAGE_CHANNEL_REPORTS,
      getBotTotalMessagesByChannel
    ),
    takeLatest(ReportsTypes.GET_BOT_MESSAGES, getBotQuantityMessagesData),
    takeLatest(
      ReportsTypes.GET_BOT_MESSAGES_AVG,
      getBotQuantityAndAverageMessageData
    ),
    takeLatest(ReportsTypes.GET_ORG_BOTS_COUNT, getCompanyBotsCount),
    takeLatest(ReportsTypes.GET_ORG_BOTS_GRAPHIC, getCompanyBotsGraphic),
    takeLatest(
      ReportsTypes.GET_ORG_TOTAL_MESSAGES,
      getCompanyTotalMessagesCount
    ),
    takeLatest(
      ReportsTypes.GET_ORG_TOTAL_MESSAGES_GRAPHIC,
      getCompanyTotalMessagesGraphic
    ),
    takeLatest(
      ReportsTypes.GET_ORG_TOTAL_SESSIONS,
      getCompanyTotalSessionsCount
    ),
    takeLatest(
      ReportsTypes.GET_ORG_TOTAL_SESSIONS_GRAPHIC,
      getCompanyTotalSessionsGraphic
    ),
    takeLatest(ReportsTypes.GET_ORG_TOTAL_USERS, getCompanyTotalUsersCount),
    takeLatest(
      ReportsTypes.GET_ORG_TOTAL_USERS_GRAPHIC,
      getCompanyTotalUsersGraphic
    ),
    takeLatest(ReportsTypes.GET_ORG_LIST_USERS, getCompanyListUsers),
    takeLatest(
      ReportsTypes.GET_ORG_MESSAGES_BY_CHANNEL,
      getCompanyMessagesByChannel
    ),
    takeLatest(
      ReportsTypes.GET_ORG_MESSAGES_BY_CHANNEL_GRAPHIC,
      getCompanyMessagesByChannelGraphic
    ),
    takeLatest(ReportsTypes.GET_ORG_USERS_BOTS, getCompanyUsersBots),
    takeLatest(
      ReportsTypes.GET_ORG_USERS_BOTS_AVERAGE,
      getCompanyUsersBotsAverage
    ),
    takeLatest(ReportsTypes.CREATE_DASHBOARD_REPORT, createDashboardReport),
    takeLatest(ReportsTypes.UPDATE_DASHBOARD_REPORT, updateDashboardReport),
    takeLatest(ReportsTypes.GET_DASHBOARD_REPORT, getDashboardReport),
    takeLatest(
      ReportsTypes.GET_COMPANY_MESSAGES_PER_BOT,
      getCompanyMessagesPerBot
    ),
    takeLatest(ReportsTypes.GET_BOT_SESSION_TAGS, getBotSessionTags),

    takeLatest(ReportsTypes.GET_BOT_CUSTOM_REPORTS, getBotCustomReports),
    takeLatest(ReportsTypes.CREATE_BOT_CUSTOM_REPORT, createBotCustomReport),
    takeLatest(ReportsTypes.GET_ERROR_LOGS_REQUEST, getErrorLogs),
  ]);

  // ----------------

  yield all([
    takeLatest(facebookMessengerTypes.SET_FB_AUTH_STATE, setFacebookAuthState),
    takeLatest(
      facebookMessengerTypes.SET_FB_SELECTED_PAGE,
      setFacebookSelectedPage
    ),
  ]);

  yield all([
    takeLatest(PreviewBotTypes.previewDispose, previewBotSaga.closeBot),
    takeLatest(
      PreviewBotTypes.previewSendUserMessage,
      previewBotSaga.saveMessage
    ),
    takeLatest(
      PreviewBotTypes.previewSendUserMessageFile,
      previewBotSaga.saveFileMessage
    ),
    takeLatest(PreviewBotTypes.previewStart, previewBotSaga.startBot),
    takeLatest(PreviewBotTypes.previewResetMessages, previewBotSaga.resetBot),
    takeLatest(PreviewBotTypes.previewSendBotMessage, previewBotSaga.nextState),
  ]);

  yield all([
    takeLatest(BotsTypes.EDIT_TITLE_BOT_REQUEST, editBotTitle),
    takeLatest(BotsTypes.LIST_BOTS_REQUEST, list),
    takeLatest(BotsTypes.NEW_BOT_REQUEST, newBot),
    takeLatest(BotsTypes.NEW_VOICE_BOT_REQUEST, newVoiceBot),
    takeLatest(BotsTypes.UPDATE_VOICE_BOT_REQUEST, updateVoiceBot),
    takeLatest(BotsTypes.REMOVE_BOT_REQUEST, removeBot),
    takeLatest(BotsTypes.REMOVE_VOICE_BOT_REQUEST, removeVoiceBot),
    takeLatest(BotsTypes.CLONE_VOICE_BOT_REQUEST, cloneVoiceBot),
    takeLatest(BotsTypes.CREATOR_BOT_REQUEST, creatorBot),
    takeEvery(BotsTypes.RETRIEVE_BOT_REQUEST, getBot),
    takeEvery(BotsTypes.SAVE_VIEW_POSITION_BOT, saveBotViewPosition),
    takeEvery(BotsTypes.PUBLISH_WIDGET_HTML, publishHtmlWidget),
    takeEvery(BotsTypes.SAVE_WIDGET_HTML, saveWidgetHtml),
    takeEvery(BotsTypes.UPDATE_BOT_REQUEST, updateBot),
    takeEvery(BotsTypes.HISTORY_BOT_MESSAGE, historyMessage),
    takeEvery(BotsTypes.HISTORY_BOT_FILTER_SESSIONS, filterSessions),
    takeEvery(BotsTypes.HISTORY_BOT_FILTER_DASHBOARD, filterDashboard),
    takeEvery(
      BotsTypes.UPDATE_BOT_HUMAN_ATTENDANCE_REQUEST,
      updateBotHumanAttendance
    ),
    takeEvery(
      BotsTypes.UPDATE_ZENDESK_TICKET_SETTINGS,
      updateZendeskTicketSettings
    ),
    takeEvery(BotsTypes.UPDATE_GLPI_SETTINGS, updateGlpiSettings),
    takeEvery(BotsTypes.UPDATE_JIRA_SETTINGS, updateJiraSettings),
    takeEvery(
      BotsTypes.UPDATE_ATTENDANCE_HOUR_SETTINGS,
      updateAttendanceHoursSettings
    ),
    takeEvery(BotsTypes.SAVE_QUICK_ACCESS_SETTINGS, updateBotQuickAccess),
    takeEvery(
      BotsTypes.SAVE_PUBLISH_QUICK_ACCESS_SETTINGS,
      updatePublishBotQuickAccess
    ),
    takeLatest(BotsTypes.GET_QUEUE_ATTENDANCE, getBotQueueHumanAttendance),
    takeEvery(BotsTypes.UPDATE_BOT_STATUS, updateBotStatus),
    takeLatest(BotsTypes.UPDATE_BOT_IA_STATUS, updateBotIaStatus),
    takeLatest(BotsTypes.BOT_COLLECT_DATA_COUNT, getBotCollectDataCount),
    takeLatest(BotsTypes.BOT_COLLECT_DATA_CSV, getBotCollectDataCsv),
    takeLatest(BotsTypes.BOT_COLLECT_DATA_COUNT_RESET, resetBotCollectCount),
    takeLatest(BotsTypes.OPEN_NEW_BOT_PAGE, openNewBotPage),
    takeEvery(BotsTypes.UPDATE_GENERAL_BOT_INFO, updateBotGeneralInformation),
    takeEvery(BotsTypes.PUBLISH_BOT, publishBot),
    takeEvery(BotsTypes.PUBLISH_VOICE_BOT, publishVoiceBot),
    takeEvery(BotsTypes.CHANGE_STAGING_AREA, changeStagingArea),

    takeEvery(BotsTypes.COUNT_STATUS_CREATOR_NO_SAVE, countStatusCreatorNoSave),
    takeEvery(
      BotsTypes.COUNT_STATUS_CREATOR_NO_SAVE_REMOVE,
      countStatusCreatorNoSaveRemove
    ),

    // INTELIGENCIA ARTIFICIAL
    takeLatest(IaTypes.CURATION_BOT_LIST_MESSAGES, listCurationMessages),
    takeEvery(IaTypes.CURATION_BOT_ACCEPT_MESSAGE, acceptCurationMessage),
    takeEvery(IaTypes.CURATION_BOT_IGNORE_MESSAGE, ignoreCurationMessage),
    takeLatest(IaTypes.IA_LIST_LUIS, listLuis),
    takeLatest(IaTypes.IA_LIST_WIT, listWit),
    takeLatest(IaTypes.IA_LIST_ENTITIES, listIAEntities),
    takeLatest(IaTypes.IA_LIST_ENTITY, listEntity),
    takeLatest(IaTypes.IA_LIST_INTENTS, listIAIntents),
    takeLatest(IaTypes.IA_LIST_ALL_SAMPLES, listAllSamples),
    takeLatest(IaTypes.IA_LIST_SAMPLES_INTENT, listSamplesIntent),
    takeEvery(IaTypes.IA_SAVE_SAMPLE, saveIASample),
    takeLatest(IaTypes.IA_CREATE_LUIS, createLuis),
    takeLatest(IaTypes.IA_CREATE_WIT, createWit),
    takeLatest(IaTypes.IA_CREATE_BOTERIA, createBoteria),
    takeLatest(IaTypes.IA_CREATE_INTENTS, createIntents),
    takeLatest(IaTypes.IA_CREATE_ENTITIES, createEntities),
    takeLatest(IaTypes.IA_DELETE_INTENTS, deleteIntents),
    takeLatest(IaTypes.IA_CREATE_CONDITIONS, createCondition),
    takeLatest(IaTypes.IA_LIST_CONDITIONS, getConditions),
    takeLatest(IaTypes.IA_UPDATE_CONDITIONS, updateCondition),
    takeLatest(IaTypes.IA_DELETE_CONDITIONS, deleteCondition),
    takeEvery(IaTypes.IA_DELETE_SAMPLES, deleteSamples),
    takeEvery(IaTypes.IA_DELETE_ENTITIES, deleteEntities),
    takeEvery(IaTypes.IA_DELETE_ENTITIES_OBJ, deleteEntitiesObj),
    takeEvery(IaTypes.IA_DELETE_ENTITIES_SYN, deleteEntitiesSyn),
    takeEvery(IaTypes.IA_PLATFORM_INFO, getIaInfo),
    takeEvery(IaTypes.IA_TRAIN_LUIS, trainLuis),
    takeEvery(IaTypes.IA_CREATE_SAMPLES, createSamples),
    takeEvery(IaTypes.CURATION_BOT_SAVE_APRIMORAMENTOS, saveAprimoramentos),
    takeEvery(IaTypes.IA_PREVIEW, iaPreview),
  ]);

  yield all([
    takeEvery(ItemsTypes.CREATE_ITEM_REQUEST, createItem),
    takeEvery(ItemsTypes.CREATE_VOICE_ITEM_REQUEST, createVoiceItem),
    takeEvery(ItemsTypes.REMOVE_VOICE_ITEM_REQUEST, removeVoiceItem),
    takeEvery(ItemsTypes.CLONE_VOICE_ITEM_REQUEST, cloneVoiceItem),
    takeLatest(ItemsTypes.LIST_ITEMS_REQUEST, listItems),
    takeLatest(ItemsTypes.LIST_SUBFLOW_ITEMS_REQUEST, listSubflow),
    takeLatest(ItemsTypes.LIST_ALL_ITEMS_REQUEST, getAllItems),
    takeEvery(ItemsTypes.EDIT_ITEM, editItem),
    takeEvery(ItemsTypes.REMOVE_ITEM_REQUEST, removeItem),
    takeEvery(ItemsTypes.CLONE_ITEM_REQUEST, cloneItem),
    takeEvery(ItemsTypes.EDIT_FILE_ITEM_REQUEST, editItemFile),
    takeEvery(ItemsTypes.REMOVE_FILE_ITEM_REQUEST, removeItemFile),
    takeEvery(ItemsTypes.EDIT_URL_ITEM_REQUEST, editItemUrl),
    takeEvery(ItemsTypes.EDIT_CARD_IMAGE_REQUEST, editCardImage),
  ]);

  yield all([
    takeEvery(ConnectorsTypes.CREATE_CONNECTOR_REQUEST, createConnector),
    takeEvery(
      ConnectorsTypes.CREATE_VOICE_CONNECTOR_REQUEST,
      createVoiceConnector
    ),
    takeLatest(ConnectorsTypes.GET_CONNECTORS_REQUEST, getConnectors),
    takeLatest(
      ConnectorsTypes.GET_CONNECTORS_SUBFLOW_REQUEST,
      getConnectorsSubflow
    ),
    takeLatest(ConnectorsTypes.LIST_ALL_CONNECTORS_REQUEST, getAllConnectors),
    takeEvery(ConnectorsTypes.DELETE_CONNECTOR_REQUEST, deleteConnector),
  ]);

  yield all([
    takeLatest(CompanyTypes.SET_PLAN_COMPANY, companiesSaga.changePlan),
    takeLatest(PlanTypes.POST_NEW_PLAN, companiesSaga.createNewPlan),
    takeLatest(
      CompanyTypes.POST_ADDITIONAL_MESSAGE,
      companiesSaga.createAdditionalMessage
    ),
    takeLatest(CompanyTypes.UPDATE_PLAN_COMPANY, companiesSaga.updatePlan),
    takeLatest(CompanyTypes.GET_COMPANY_REQUEST, companiesSaga.getCompany),
    takeLatest(CompanyTypes.GET_COMPANIES_REQUEST, companiesSaga.getCompanies),
    takeLatest(
      CompanyTypes.CREATE_COMPANY_REQUEST,
      companiesSaga.createCompany
    ),
    takeLatest(
      CompanyTypes.UPDATE_SELECTED_COMPANY_REQUEST,
      companiesSaga.updateSelectedCompany
    ),
    takeLatest(
      CompanyTypes.UPDATE_COMPANY_REQUEST,
      companiesSaga.updateCompany
    ),
    takeLatest(CompanyTypes.GET_ORGANIZATIONS, companiesSaga.getOrganizations),
    takeLatest(
      CompanyTypes.CREATE_ORGANIZATION,
      companiesSaga.createOrganization
    ),
    takeLatest(
      CompanyTypes.REMOVE_OGANIZATION,
      companiesSaga.removeOrganization
    ),
    takeLatest(
      CompanyTypes.GET_HISTORY_ACTIONS_REQUEST,
      companiesSaga.getHistoryActions
    ),
    takeLatest(
      CompanyTypes.UPDATE_ORGANIZATION_REQUEST,
      companiesSaga.updateOrganization
    ),
  ]);

  yield all([
    takeLatest(UserTypes.GET_USERS, usersSaga.getUsers),
    takeLatest(UserTypes.CREATE_USER, usersSaga.createUser),
    takeLatest(UserTypes.UPDATE_USER, usersSaga.updateUser),
    takeLatest(UserTypes.GET_ME, usersSaga.me),
    takeLatest(UserTypes.SELECT_ORGANIZATION, usersSaga.selectOrganization),
    takeLatest(
      UserTypes.SELECT_COMPANY_ORGANIZATION,
      usersSaga.selectCompanyOrganization
    ),
  ]);

  yield all([
    takeLatest(PermissionTypes.GET_PERMISSIONS, permissionsSaga.getPermissions),
    takeLatest(
      PermissionTypes.UPDATE_PERMISSION,
      permissionsSaga.updatePermission
    ),
  ]);

  yield all([
    takeLatest(DebounceTypes.EXECUTE_DEBOUNCE, debounceSagas.executeDebounce),
  ]);

  yield all([
    takeLatest(TriggerTypes.LOAD, triggerSagas.load),
    takeLatest(TriggerTypes.SAVE_BOT_TRIGGERS, triggerSagas.save),
  ]);

  yield all([
    takeLatest(
      IaReportTypes.GET_NOT_UNDERSTOOD_INTENTIONS,
      iaReportsSagas.getNotUnderstoodIntentions
    ),
    takeLatest(
      IaReportTypes.GET_USE_OF_INTENTIONS,
      iaReportsSagas.getUseOfIntentions
    ),
    takeLatest(
      IaReportTypes.GET_PERFORMANCE_OF_INTENTIONS,
      iaReportsSagas.getPerformanceOfIntentions
    ),
  ]);

  yield all([
    takeLatest(
      registerFromTypes.GET_REGISTERS_REQUEST,
      registerFromSagas.getRegisters
    ),
  ]);

  yield all([
    takeLatest(RdStationTypes.GET_FIELDS_REQUEST, rdStationSagas.getFields),
    takeLatest(RdStationTypes.RD_STATION_LOGIN, rdStationSagas.rdStationLogin),
    takeLatest(
      RdStationTypes.GENERATE_ACCESS_TOKEN_REQUEST,
      rdStationSagas.generateAccessToken
    ),
    takeLatest(
      RdStationTypes.REMOVE_ACCESS_TOKEN_REQUEST,
      rdStationSagas.deleteAccessToken
    ),
  ]);
}
