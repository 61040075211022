export const DebounceTypes = {
  ADD_ITEM_DEBOUNCE: 'debounce/ADD_ITEM_DEBOUNCE',
  DELETE_ITEM_DEBOUNCE: 'debounce/DELETE_ITEM_DEBOUNCE',
  UPDATE_ITEM_DEBOUNCE: 'debounce/UPDATE_ITEM_DEBOUNCE',
  EXECUTE_DEBOUNCE: 'debounce/EXECUTE_DEBOUNCE',
  CLEAR_DEBOUNCE: 'debounce/CLEAR_DEBOUNCE',
  SET_TIME: 'debounce/SET_TIME_DEBOUNCE',
};

export const DebounceCreators = {
  addItemDebounce: (payload) => ({
    type: DebounceTypes.ADD_ITEM_DEBOUNCE,
    payload,
  }),

  deleteItemDebounce: (payload) => ({
    type: DebounceTypes.DELETE_ITEM_DEBOUNCE,
    payload,
  }),

  updateItemDebounce: (payload) => ({
    type: DebounceTypes.UPDATE_ITEM_DEBOUNCE,
    payload,
  }),

  executeDebounce: (payload) => ({
    type: DebounceTypes.EXECUTE_DEBOUNCE,
    payload,
  }),

  clearDebounce: () => ({
    type: DebounceTypes.CLEAR_DEBOUNCE,
  }),

  setTimeDebounce: (payload) => ({
    type: DebounceTypes.SET_TIME,
    payload,
  }),
};
