export const FilterTypes = {
  LIST_FILTER_INFO: 'bot/LIST_FILTER_INFO',
  LIST_FILTER_INFO_SUCCESS: 'bot/LIST_FILTER_INFO_SUCCESS',
  FILTER_RELATORIO_INFO: 'bot/FILTER_RELATORIO_INFO',
  FILTER_RELATORIO_INFO_SUCCESS: 'bot/FILTER_RELATORIO_INFO_SUCCESS',
};

export const FilterCreators = {
  infoStateFilter: (
    startDate,
    endDate,
    vlSelect,
    tags,
    nameTags,
    channels,
    variables
  ) => ({
    type: FilterTypes.LIST_FILTER_INFO,
    payload: {
      startDate,
      endDate,
      vlSelect,
      tags,
      nameTags,
      channels,
      variables,
    },
  }),

  relatorioFilterInfo: (dateFilter) => ({
    type: FilterTypes.FILTER_RELATORIO_INFO,
    payload: {
      dateFilter,
    },
  }),
};
