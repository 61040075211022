export const ConnectorsTypes = {
  CREATE_CONNECTOR_REQUEST: 'connectors/CREATE_CONNECTOR_REQUEST',
  CREATE_VOICE_CONNECTOR_REQUEST: 'connectors/CREATE_VOICE_CONNECTOR_REQUEST',
  CREATE_CONNECTOR_SUCCESS: 'connectors/CREATE_CONNECTOR_SUCCESS',
  DELETE_CONNECTOR_REQUEST: 'connectors/DELETE_CONNECTOR_REQUEST',
  DELETE_CONNECTOR_SUCCESS: 'connectors/DELETE_CONNECTOR_SUCCESS',
  GET_CONNECTORS_REQUEST: 'connectors/GET_CONNECTORS_REQUEST',
  GET_CONNECTORS_SUCCESS: 'connectors/GET_CONNECTORS_SUCCESS',
  GET_CONNECTORS_SUBFLOW_REQUEST: 'connectors/GET_CONNECTORS_SUBFLOW_REQUEST',
  LIST_ALL_CONNECTORS_REQUEST: 'connectors/LIST_ALL_CONNECTORS_REQUEST',
  LIST_ALL_CONNECTORS_SUCCESS: 'connectors/LIST_ALL_CONNECTORS_SUCCESS',
};

export const ConnectorsCreators = {
  createConnectorRequest: ({ from, to, botId, subflowFor }) => ({
    type: ConnectorsTypes.CREATE_CONNECTOR_REQUEST,
    payload: { from, to, botId, subflowFor },
  }),

  createVoiceConnectorRequest: ({ from, to, botId, voiceSettings }) => ({
    type: ConnectorsTypes.CREATE_VOICE_CONNECTOR_REQUEST,
    payload: { from, to, botId, voiceSettings },
  }),

  deleteConnectorRequest: (id, botId) => ({
    type: ConnectorsTypes.DELETE_CONNECTOR_REQUEST,
    payload: { id, botId },
  }),

  getConnectorsRequest: (botId) => ({
    type: ConnectorsTypes.GET_CONNECTORS_REQUEST,
    payload: { botId },
  }),

  getConnectorsSubflowRequest: (botId, itemId) => ({
    type: ConnectorsTypes.GET_CONNECTORS_SUBFLOW_REQUEST,
    payload: { botId, itemId },
  }),

  getAllConnectors: (payload) => ({
    type: ConnectorsTypes.LIST_ALL_CONNECTORS_REQUEST,
    payload,
  }),

  getAllConnectorsSuccess: (payload) => ({
    type: ConnectorsTypes.LIST_ALL_CONNECTORS_SUCCESS,
    payload,
  }),

  getConnectorsSuccess: (connectors) => ({
    type: ConnectorsTypes.GET_CONNECTORS_SUCCESS,
    payload: connectors,
  }),

  createConnectorsSuccess: (connector) => ({
    type: ConnectorsTypes.CREATE_CONNECTOR_SUCCESS,
    payload: connector,
  }),

  deleteConnectorSuccess: (id, botId) => ({
    type: ConnectorsTypes.DELETE_CONNECTOR_SUCCESS,
    payload: { id, botId },
  }),
};
