import { call, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import store from '..';

import Axios, { API_TOKEN } from '../../services/api';
import actions from '../actions';
import api from '../../services/api';

export function* logout() {
  localStorage.removeItem(API_TOKEN);
  localStorage.removeItem('isSelectOrg');

  store.dispatch(actions.CompanyActions.resetCompanies());
  yield put({ type: actions.logout });
}

export function* login({ payload }) {
  yield put({ type: actions.authActions.AUTH_SET_TOKEN, payload });
}

export function* notice({ payload }) {
  yield call(api.put, `/v1/users/${payload?.user?.signedUser?._id}`, {
    ...payload?.user?.signedUser,
    lastNotice: payload.notice,
  });
}

export function setToken({ payload }) {
  const { token } = payload;
  localStorage.setItem(API_TOKEN, token);
  Axios.defaults.headers.common.authorization = `Bearer ${token}`;
}

// autenticação de dois fatores

export function* tfaSetup() {
  try {
    const { data } = yield call(api.post, 'v1/auth/tfa/setup');
    yield put({ type: actions.authActions.TFA_SETUP_SUCCESS, payload: data });
  } catch (e) {
    yield put({
      type: actions.authActions.TFA_SETUP_SUCCESS,
      payload: {
        error: e.message,
      },
    });
  }
}

export function* tfaActive({ payload }) {
  try {
    const { token } = payload;

    const { data } = yield call(api.post, 'v1/auth/tfa/active', {
      token,
    });
    yield put({
      type: actions.authActions.TFA_ACTIVE_SUCCESS,
      payload: { ...data, error: '' },
    });
    store.dispatch(actions.UserActions.me());
    toastr.success('Sucesso', 'Autenticação de dois fatores ligada.');
  } catch (e) {
    yield put({
      type: actions.authActions.TFA_ACTIVE_SUCCESS,
      payload: {
        error: e.message,
      },
    });
  }
}

export function* tfaDisable() {
  try {
    const { data } = yield call(api.post, 'v1/auth/tfa/disable');
    yield put({ type: actions.authActions.TFA_DISABLE_SUCCESS, payload: data });

    store.dispatch(actions.UserActions.me());
    toastr.success('Sucesso', 'Autenticação de dois fatores desligada.');
  } catch (e) {
    toastr.error('Erro', 'Ocorreu um erro ao desativar.');

    yield put({
      type: actions.authActions.TFA_DISABLE_SUCCESS,
      payload: { error: e.message },
    });
  }
}

export function* tfaVerify({ payload }) {
  yield put({
    type: actions.authActions.TFA_VERIFY_SUCCESS,
    payload,
  });
}

export function* resendEmailConfirmation({ payload }) {
  try {
    const { email } = payload;
    const { data } = yield call(
      api.get,
      `v1/auth/resend-email-confirmation?email=${email}`
    );

    yield put({
      type: actions.AUTH_RESEND_EMAIL_CONFIRMATION_SUCCESS,
      payload: data,
    });

    toastr.success('Sucesso', 'Email de confirmação reenviado com sucesso.');
  } catch (error) {
    toastr.error('Erro', 'Ocorreu ao reenviar o email de confirmação.');
  }
}
