export const NotificationTypes = {
  LIST: 'notification/LIST',
  LIST_SUCCESS: 'notification/LIST_SUCCESS',
  UPDATE_STATUS: 'notification/UPDATE_STATUS',
  UPDATE_STATUS_SUCCESS: 'notification/UPDATE_STATUS_SUCCESS',
};

export const NotificationActions = {
  list: ({ companyId, page }) => {
    return {
      type: NotificationTypes.LIST,
      payload: { companyId, page },
    };
  },

  updateStatus: ({ id }) => {
    return {
      type: NotificationTypes.UPDATE_STATUS,
      payload: { id },
    };
  },
};
