export const ValidationType = {
  Any: 0,
  Email: 1,
  Date: 2,
  Cellphone: 3,
  Url: 4,
  Number: 5,
  RegularExpression: 6,
  CPF: 7,
  CNPJ: 8,
  Localization: 9,
};

export const ValidationTypeName = [
  'Qualquer Coisa',
  'Email',
  'Data',
  'Telefone',
  'Url',
  'Número',
  'Expressão Regular',
  'CPF',
  'CNPJ',
  'Localização',
];

export const ValidationTypeIcon = [
  'qualquer_coisa',
  'email',
  'data',
  'celular',
  'url',
  'numero',
  'expressao',
  'cpf',
  'cnpj',
  'localizacao',
];

export const ValidationTypeExp = [
  '',
  '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$', // flags 'i'
  '^(0?[1-9]|[12][0-9]|3[01])[\\/\\-](0?[1-9]|1[012])[\\/\\-]\\d{4}$', // flags 'i'
  '^[\\+]?[(]?[0-9]{2,3}[)]?[-\\s\\.]?[0-9]{3,5}[-\\s\\.]?[0-9]{4,6}$', // flags 'im'
  '(http(s)?:\\/\\/.)?(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', // flags 'g'
  '^\\d*$', // flags 'i'
  undefined,
  undefined,
  undefined,
];

export const ValidationTypeExpFlags = ['', 'i', 'i', 'im', 'g', 'i', 'i'];
