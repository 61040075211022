export const IaReportTypes = {
  GET_NOT_UNDERSTOOD_INTENTIONS: 'iaReports/GET_NOT_UNDERSTOOD_INTENTIONS',
  GET_NOT_UNDERSTOOD_INTENTIONS_SUCCESS:
    'iaReports/GET_NOT_UNDERSTOOD_INTENTIONS_SUCCESS',
  GET_USE_OF_INTENTIONS: 'iaReports/GET_USE_OF_INTENTIONS',
  GET_USE_OF_INTENTIONS_SUCCESS: 'iaReports/GET_USE_OF_INTENTIONS_SUCCESS',
  GET_PERFORMANCE_OF_INTENTIONS: 'iaReports/GET_PERFORMANCE_OF_INTENTIONS',
  GET_PERFORMANCE_OF_INTENTIONS_SUCCESS:
    'iaReports/GET_PERFORMANCE_OF_INTENTIONS_SUCCESS',
};

export const IaReportAction = {
  getNotUnderstoodIntentions: (payload) => ({
    type: IaReportTypes.GET_NOT_UNDERSTOOD_INTENTIONS,
    payload,
  }),

  getNotUnderstoodIntentionsSuccess: (payload) => ({
    type: IaReportTypes.GET_NOT_UNDERSTOOD_INTENTIONS_SUCCESS,
    payload,
  }),

  getUseOfIntentions: (payload) => ({
    type: IaReportTypes.GET_USE_OF_INTENTIONS,
    payload,
  }),

  getUseOfIntentionsSuccess: (payload) => ({
    type: IaReportTypes.GET_USE_OF_INTENTIONS_SUCCESS,
    payload,
  }),

  getPerformanceOfIntentions: (payload) => ({
    type: IaReportTypes.GET_PERFORMANCE_OF_INTENTIONS,
    payload,
  }),

  getPerformanceOfIntentionsSuccess: (payload) => ({
    type: IaReportTypes.GET_PERFORMANCE_OF_INTENTIONS_SUCCESS,
    payload,
  }),
};
