// eslint-disable-next-line no-unused-vars
// import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'typeface-roboto';

import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import App from './app';

ReactDOM.render(<App />, document.getElementById('root'));
