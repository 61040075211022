export const AUTH_LOGOUT = 'auth/AUTH_LOGOUT';
export const AUTH_LOGIN = 'auth/AUTH_LOGIN';
export const AUTH_SET_TOKEN = 'auth/SET_TOKEN';
export const AUTH_NOTICE = 'auth/AUTH_NOTICE';
export const AUTH_RESEND_EMAIL_CONFIRMATION =
  'auth/AUTH_RESEND_EMAIL_CONFIRMATION';
export const AUTH_RESEND_EMAIL_CONFIRMATION_SUCCESS =
  'auth/AUTH_RESEND_EMAIL_CONFIRMATION_SUCCESS';

// autenticação de dois fatores
export const TFA_SETUP = 'auth/TFA_SETUP';
export const TFA_SETUP_SUCCESS = 'auth/TFA_SETUP_SUCCESS';
export const TFA_ACTIVE = 'auth/TFA_ACTIVE';
export const TFA_ACTIVE_SUCCESS = 'auth/TFA_ACTIVE_SUCCESS';
export const TFA_DISABLE = 'auth/TFA_DISABLE';
export const TFA_DISABLE_SUCCESS = 'auth/TFA_DISABLE_SUCCESS';
export const TFA_VERIFY = 'auth/TFA_VERIFY';
export const TFA_VERIFY_SUCCESS = 'auth/TFA_VERIFY_SUCCESS';

export const authActions = {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_NOTICE,
  AUTH_SET_TOKEN,
  AUTH_RESEND_EMAIL_CONFIRMATION,

  // autenticação de dois fatores
  TFA_SETUP,
  TFA_SETUP_SUCCESS,
  TFA_ACTIVE,
  TFA_ACTIVE_SUCCESS,
  TFA_DISABLE,
  TFA_DISABLE_SUCCESS,
  TFA_VERIFY,
  TFA_VERIFY_SUCCESS,
};

export const logout = () => ({
  type: AUTH_LOGOUT,
});

export const login = (token, user) => ({
  type: AUTH_LOGIN,
  payload: {
    token,
    user,
  },
});

// lastNotice
export const notice = (user, notices) => ({
  type: AUTH_NOTICE,
  payload: {
    user,
    notice: notices,
  },
});

export const setToken = () => ({ type: AUTH_SET_TOKEN });

// autenticação de dois fatores
export const tfaSetup = () => ({ type: TFA_SETUP });

export const tfaActive = (token) => ({
  type: TFA_ACTIVE,
  payload: {
    token,
  },
});

export const tfaDisable = () => ({ type: TFA_DISABLE });

export const tfaVerify = (userId, tfaToken) => ({
  type: TFA_VERIFY,
  payload: {
    userId,
    tfaToken,
  },
});

export const resendEmailConfirmation = (email) => ({
  type: AUTH_RESEND_EMAIL_CONFIRMATION,
  payload: { email },
});

export const successResendEmailConfirmation = (data) => ({
  type: AUTH_RESEND_EMAIL_CONFIRMATION_SUCCESS,
  payload: data,
});
