import { saveAs } from 'file-saver';
import { BotsTypes } from '../actions/bots';
import { b64toBlob } from '../../util/utils';
import { ItemsTypes } from '../actions/items';

const INITIAL_STATE = {
  list: null,
  bot: {},
  history: [],
  sessions: {
    docs: [],
    page: 0,
    pages: 1,
    total: 0,
  },
  messages: {
    docs: [],
    page: 0,
    pages: 1,
    total: 0,
  },
  dashboardFilter: [],
  queueAttendance: [],
  botCollectDataCount: [],
  botCollectDataCsvBuffer: null,
  templatesBot: [],
};

export default function items(state = INITIAL_STATE, action) {
  switch (action.type) {
    case BotsTypes.LIST_BOTS_REQUEST:
      return { ...state };
    case BotsTypes.LIST_BOTS_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case BotsTypes.NEW_BOT_REQUEST:
      return { ...state };
    case BotsTypes.NEW_BOT_SUCCESS:
      return { ...state };
    case BotsTypes.REMOVE_BOT_REQUEST:
      return { ...state };
    case BotsTypes.REMOVE_BOT_SUCCESS:
      return {
        ...state,
        list: state.list.filter((bot) => bot._id !== action.payload),
      };
    case BotsTypes.HISTORY_BOT_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: {
          ...state.messages,
          ...action.payload,
          docs: [...state.messages.docs, ...action.payload.docs],
        },
      };
    case BotsTypes.CLEAR_HISTORY_BOT_MESSAGE:
      return {
        ...state,
        messages: {
          docs: [],
          page: 0,
          pages: 1,
          total: 0,
        },
      };
    case BotsTypes.HISTORY_BOT_FILTER_SESSIONS_SUCCESS:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          ...action.payload,
          docs: [...state.sessions.docs, ...action.payload.docs],
        },
      };
    case BotsTypes.HISTORY_BOT_FILTER_SESSIONS_SEARCH_SUCCESS:
      return {
        ...state,
        sessions: {
          ...action.payload,
          docs: action.payload.docs,
        },
      };
    case BotsTypes.CLEAR_HISTORY_BOT_FILTER_SESSIONS:
      return {
        ...state,
        sessions: { docs: [], page: 0, pages: 1, total: 0 },
      };
    case BotsTypes.HISTORY_BOT_FILTER_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardFilter: action.payload,
      };
    case BotsTypes.EDIT_TITLE_BOT_REQUEST:
      return { ...state };
    case BotsTypes.EDIT_TITLE_BOT_SUCCESS:
      return {
        ...state,
        bot: action.payload,
      };
    case BotsTypes.RETRIEVE_BOT_REQUEST:
      return { ...state };
    case BotsTypes.RETRIEVE_BOT_SUCCESS:
      return { ...state, bot: action.payload };
    case BotsTypes.SAVE_VIEW_POSITION_BOT:
      return {
        ...state,
        bot: { ...state.bot, viewPosition: action.payload.viewPosition },
      };
    case BotsTypes.PUBLISH_WIDGET_HTML_SUCCESS: {
      console.log('PUBLISH_WIDGET_HTML_SUCCESS', action.payload);
      try {
        const blob = b64toBlob(action.payload.data, 'application/zip');
        // console.log('blob', blob)
        saveAs(blob, `${action.payload.botId}.zip`);
      } catch (e) {
        console.log(e);
      }
      return { ...state };
    }
    case BotsTypes.SAVE_WIDGET_HTML:
      return { ...state };
    case BotsTypes.SAVE_WIDGET_HTML_SUCCESS: {
      const { payload } = action;
      payload.callback();
      console.log('SAVE_WIDGET_HTML_SUCCESS', payload.data);
      return { ...state, bot: payload.data };
    }
    case BotsTypes.SAVE_ROOT_ITEM: {
      return { ...state };
    }
    case BotsTypes.SAVE_ROOT_ITEM_SUCCESS: {
      const { payload } = action;
      return { ...state, bot: payload };
    }
    case ItemsTypes.LIST_ITEMS_SUCCESS: {
      // TODO - Isso está correto?
      const { payload } = action;

      return { ...state, bot: payload.bot };
    }
    case BotsTypes.UPDATE_BOT_SUCCESS: {
      const { payload } = action;
      return { ...state, bot: payload };
    }

    case BotsTypes.BOT_UPDATE_DATE: {
      const { payload } = action;
      return { ...state, bot: { ...state.bot, lastUpdate: payload } };
    }

    case BotsTypes.SET_QUEUE_ATTENDANCE: {
      const { payload } = action;
      return { ...state, queueAttendance: payload };
    }

    case BotsTypes.UPDATE_BOT_STATUS: {
      const { payload } = action;
      return { ...state, bot: { ...state.bot, ...payload.data } };
    }

    case BotsTypes.UPDATE_BOT_IA_STATUS: {
      const { payload } = action;
      return {
        ...state,
        bot: { ...state.bot, iaStatus: payload.iaStatus },
      };
    }

    case BotsTypes.BOT_COLLECT_DATA_COUNT_SUCCESS: {
      return { ...state, botCollectDataCount: action.payload };
    }

    case BotsTypes.BOT_COLLECT_DATA_COUNT_RESET_SUCCESS: {
      return { ...state, botCollectDataCount: [] };
    }

    case BotsTypes.BOT_COLLECT_DATA_CSV_RESET_SUCCESS: {
      return { ...state, botCollectDataCsvBuffer: action.payload };
    }

    case BotsTypes.UPDATE_GENERAL_BOT_INFO_SUCCESS: {
      return { ...state, bot: action.payload };
    }

    case BotsTypes.UPDATE_VOICE_BOT_SUCCESS: {
      return { ...state, bot: action.payload };
    }

    case BotsTypes.CLONE_VOICE_BOT_SUCCESS: {
      const newBot = action.payload.bot;
      return { ...state, list: [...state.list, newBot] };
    }

    case BotsTypes.RETRIEVE_ZENDESK_TICKET_SETTINGS: {
      const { payload } = action;
      return {
        ...state,
        bot: { ...state.bot, settings: payload.settings },
      };
    }

    case BotsTypes.RETRIEVE_GLPI_SETTINGS: {
      const { payload } = action;
      return {
        ...state,
        bot: { ...state.bot, settings: payload.settings },
      };
    }

    case BotsTypes.RETRIEVE_JIRA_SETTINGS: {
      const { payload } = action;
      return {
        ...state,
        bot: { ...state.bot, settings: payload.settings },
      };
    }

    case BotsTypes.RETRIEVE_ATTENDANCE_HOUR_SETTINGS: {
      const { payload } = action;
      return {
        ...state,
        bot: { ...state.bot, settings: payload.settings },
      };
    }

    case BotsTypes.PUBLISH_BOT_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        bot: {
          ...state.bot,
          versions: payload.versions,
          isActive: payload.isActive,
        },
      };
    }

    case BotsTypes.RETRIEVE_ATTEDANCE_HOUR_SETTINGS: {
      const { payload } = action;
      return {
        ...state,
        bot: { ...state.bot, settings: payload.settings },
      };
    }

    case BotsTypes.SAVE_QUICK_ACCESS_SETTINGS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        bot: {
          ...state.bot,
          quickAccess: payload,
        },
      };
    }

    case BotsTypes.COUNT_STATUS_CREATOR_NO_SAVE_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        bot: {
          ...state.bot,
          countUpdatesNoPublish: payload,
        },
      };
    }
    case BotsTypes.COUNT_STATUS_CREATOR_NO_SAVE_REMOVE_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        bot: {
          ...state.bot,
          countUpdatesNoPublish: payload,
        },
      };
    }

    default:
      return state;
  }
}
