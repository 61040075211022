export const SET_RECAPTCHA = 'recaptcha/SET_RECAPTCHA';
export const DISABLE_RECAPTCHA = 'recaptcha/DISABLE_RECAPTCHA';
export const RESET_RECAPTCHA = 'recaptcha/RESET_RECAPTCHA';

export const recapchaTypes = {
  SET_RECAPTCHA,
  DISABLE_RECAPTCHA,
  RESET_RECAPTCHA,
};

export const recaptchaActions = {
  getRecaptcha: (payload) => ({
    type: recapchaTypes.SET_RECAPTCHA,
    payload: {
      reCaptchaValue: payload,
    },
  }),

  disableRecaptcha: (payload) => ({
    type: recapchaTypes.DISABLE_RECAPTCHA,
    payload: {
      disabled: payload,
    },
  }),

  resetCaptcha: (payload) => ({
    type: recapchaTypes.RESET_RECAPTCHA,
    payload: {
      resetRef: payload,
    },
  }),
};
