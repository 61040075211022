import actions from '../actions'
import {ValidationType, ValidationTypeExp, ValidationTypeExpFlags} from '../../util/validation-type'

const {PreviewBotTypes} = actions

const previewBotInitialState = Object.freeze({
  messages: [],
  visible: false,
  firstTest: true,
  finished: false,
  // currentState: 0,
  states: [],
  botState: undefined,
  botPreviousState: undefined,
  variables: []
})

export function lastState(state) {
  // const { currentState, states } = state.previewBot;
  //
  // let oldState = currentState;
  //
  // if (currentState > 0) {
  //   oldState = currentState - 1;
  // }
  //
  // return { ...states[oldState] };
  return state.previewBot.botPreviousState
}

export function currentState(state) {
  return state.previewBot.botState
}

export function findNextState(state) {
  const next = botNextState(state.previewBot.states, state.previewBot.messages, state.previewBot.botState, false)
  return next
}

export function isFinished(state) {
  return state.previewBot.finished
}

export function captureAnswer(state) {
  const { /*currentState, states,*/ botState} = state.previewBot
  return botState && botState.captureAnswer
}

const previewBot = (state = previewBotInitialState, action) => {
  // eslint-disable-next-line no-unused-vars
  // console.log('previewBot', action.type)
  const {states, messages, botState} = state
  switch (action.type) {
    case PreviewBotTypes.previewSendUserMessage: {
      // console.log('previewSendUserMessage', action.payload)
      let newVariables = [...state.variables]
      if (botState.variable) {
        newVariables = newVariables.filter(i => i.id !== botState._id)
        newVariables.push({id: botState._id, variable: botState.variable, value: action.payload.message.body})
      }
      return {
        ...state,
        messages: [...state.messages, action.payload],
        variables: newVariables
      }
    }
    case PreviewBotTypes.previewResetMessages:
      return {
        ...state,
        messages: [],
        // currentState: 0,
        botState: undefined,
        botPreviousState: undefined,
        finished: false,
      }
    case PreviewBotTypes.previewOpen:
      return {
        ...state,
        visible: true
      }
    case PreviewBotTypes.previewDispose:
      return {
        ...state,
        visible: false,
        firstTest: true,
      }
    case PreviewBotTypes.previewStart:
      return {
        ...state,
        firstTest: false,
      }
    case PreviewBotTypes.previewSetStateMachine: {
      // if (action.payload.states) {
      //   for (let st of action.payload.states) {
      //     st.descriptionError = undefined
      //     st.errors = 0
      //   }
      // }
      return {
        ...state,
        // states: [...action.payload.states],
        // variables: [...action.payload.variables]
      }
    }
    case PreviewBotTypes.previewSendBotPrepareNextMessage: {
      return {
        ...state
      }
    }
    case PreviewBotTypes.previewSendBotTyppingStop: {
      let newMessages = [...state.messages]
      newMessages = newMessages.filter(i => i.from !== 'BOT_TYPPING')
      return {
        ...state,
        messages: newMessages,
      }
    }
    // case PreviewBotTypes.previewNextStatePreview:
    // console.log('previewNextStatePreview', states)
    // if (states.length > currentState + 1) {
    //   return {
    //     ...state,
    //     currentState: state.currentState + 1
    //   };
    // }
    // return {
    //   ...state,
    //   finished: true,
    // };
    // return {
    //   ...state
    // }
    case PreviewBotTypes.previewSendBotTypping:
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            from: 'BOT_TYPPING',
            message: {when: new Date(), body: '...', state: undefined}
          }
        ],
      }
    case PreviewBotTypes.previewSendBotMessage: {
      if (state.finished) {
        return state
      }

      const previewState = botState ? Object.assign({}, botState) : undefined

      const nextBotState = botNextState(states, messages, botState)
      if (!nextBotState) {
        return {
          ...state,
          finished: true
        }
      }

      let body = nextBotState.description && nextBotState.description.length > 0 ? nextBotState.description[0] : ''
      if (nextBotState.description && nextBotState.description.length > 1) {
        const randomIndex = Math.floor(Math.random() * nextBotState.description.length)
        body = nextBotState.description[randomIndex]
      }
      if (nextBotState && nextBotState.descriptionError) {
        body = nextBotState.descriptionError
      }

      body = checkVariable(state.variables, body)

      const newMessages = [...state.messages]

      newMessages.push({
        from: 'BOT',
        message: {when: new Date(), body: body, state: nextBotState}
      })

      return {
        ...state,
        // botPreviousState: previewState,
        // botState: nextBotState,
        // messages: newMessages,
      }
    }
    default:
      return state
  }
}

const checkVariable = (variables, body) => {
  while (body.indexOf('{') >= 0 && body.indexOf('}') >= 0) {
    const variable = body.substring(body.indexOf('{') + 1, body.indexOf('}'))
    const _var = variables.find(i => i.variable === variable)
    if (_var) {
      body = body.replace(`{${variable}}`, _var.value)
    }
  }
  return body
}

const botNextState = (states, messages, botState, checkError = true) => {
  // console.log('botState', botState)
  // console.log('messages', messages)
  if (!botState) {
    return states.filter(state => typeof state.ancestorItem === 'undefined')[0]
  } else {
    if (botState.children.length === 0) {
      return null
    } else if (botState.children.length === 1 || botState.type !== 'Menu') {
      if (botState.captureAnswer) {
        if (botState.validationType > ValidationType.Any) {
          const userMessages = messages.filter(msg => msg.from === 'USER')
          if (userMessages.length > 0) {
            const reg = new RegExp(ValidationTypeExp[botState.validationType], ValidationTypeExpFlags[botState.validationType])
            // const exp = ValidationTypeExp[botState.validationType]
            // const flags = ValidationTypeExpFlags[botState.validationType]
            const msg = userMessages[userMessages.length - 1].message.body
            if (checkError && !reg.test(msg)) {
              // console.log('Erro')
              return handleError(botState)
            // } else {
            //   if (checkError) {
            //     console.log('Sem erro')
            //   }
            }
          }
        }
      }
      const nextState = states.find(state => state._id === botState.children[0])
      if (checkError) {
        nextState.errors = 0
        nextState.descriptionError = undefined
        // console.log('>>> proximo estado: ', nextState)
      }
      return nextState
    } else {
      // It's Menu!
      const userMessages = messages.filter(msg => msg.from === 'USER')
      if (userMessages.length > 0) {
        // console.log('userMessages', userMessages)
        const option = parseInt(userMessages[userMessages.length - 1].message.body)
        // console.log('option', option)
        if (isNaN(option)) {
          return botState
        } else {
          if (option - 1 < 0 || option - 1 >= botState.children.length) {
            return handleError(botState)
          } else {
            return states.find(state => state._id === botState.children[option - 1])
          }
        }
      } else {
        return null
      }
    }
  }
}

const handleError = (botState) => {
  if (botState.errors) {
    botState.errors++
  } else {
    botState.errors = 1
  }
  if (botState.maxTries) {
    if (botState.errors) {
      if (botState.errors >= botState.maxTries) {
        botState.descriptionError = botState.errorMessageFinal
      } else {
        if (botState.errorMessage) {
          botState.descriptionError = botState.errorMessage
        }
      }
    }
  } else {
    if (botState.errorMessage) {
      botState.descriptionError = botState.errorMessage
    }
  }
  console.log('handleError, errors: ', botState.errors, ' maxTries: ', botState.maxTries)
  return botState
}


export default previewBot
