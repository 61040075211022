export const BotsTypes = {
  RETRIEVE_BOT_REQUEST: 'bot/RETRIEVE_BOT_REQUEST',
  RETRIEVE_BOT_SUCCESS: 'bot/RETRIEVE_BOT_SUCCESS',
  LIST_BOTS_REQUEST: 'bot/LIST_BOTS_REQUEST',
  LIST_BOTS_SUCCESS: 'bot/LIST_BOTS_SUCCESS',
  NEW_BOT_REQUEST: 'bot/NEW_BOT_REQUEST',
  NEW_BOT_SUCCESS: 'bot/NEW_BOT_SUCCESS',
  NEW_VOICE_BOT_REQUEST: 'bot/NEW_VOICE_BOT_REQUEST',
  REMOVE_BOT_REQUEST: 'bot/REMOVE_BOT_REQUEST',
  REMOVE_VOICE_BOT_REQUEST: 'bot/REMOVE_VOICE_BOT_REQUEST',
  REMOVE_BOT_SUCCESS: 'bot/REMOVE_BOT_SUCCESS',
  CLONE_VOICE_BOT_REQUEST: 'bot/CLONE_VOICE_BOT_REQUEST',
  CLONE_VOICE_BOT_SUCCESS: 'bot/CLONE_VOICE_BOT_SUCCESS',
  CREATOR_BOT_REQUEST: 'bot/CREATOR_BOT_REQUEST',
  CREATOR_BOT_SUCCESS: 'bot/CREATOR_BOT_SUCCESS',
  EDIT_TITLE_BOT_REQUEST: 'bot/EDIT_TITLE_BOT_REQUEST',
  EDIT_TITLE_BOT_SUCCESS: 'bot/EDIT_TITLE_BOT_SUCCESS',
  CHANNELS_BOT_REQUEST: 'bot/CHANNELS_BOT_REQUEST',
  SAVE_VIEW_POSITION_BOT: 'bot/SAVE_VIEW_POSITION_BOT',
  PUBLISH_WIDGET_HTML: 'bot/PUBLISH_WIDGET_HTML',
  PUBLISH_WIDGET_HTML_SUCCESS: 'bot/PUBLISH_WIDGET_HTML_SUCCESS',
  SAVE_WIDGET_HTML: 'bot/SAVE_WIDGET_HTML',
  SAVE_WIDGET_HTML_SUCCESS: 'bot/SAVE_WIDGET_HTML_SUCCESS',
  SAVE_ROOT_ITEM: 'bot/SAVE_ROOT_ITEM',
  SAVE_ROOT_ITEM_SUCCESS: 'bot/SAVE_ROOT_ITEM_SUCCESS',
  UPDATE_BOT_REQUEST: 'bot/UPDATE_BOT_REQUEST',
  UPDATE_BOT_SUCCESS: 'bot/UPDATE_BOT_SUCCESS',
  UPDATE_VOICE_BOT_REQUEST: 'bot/UPDATE_VOICE_BOT_REQUEST',
  UPDATE_VOICE_BOT_SUCCESS: 'bot/UPDATE_VOICE_BOT_SUCCESS',
  HISTORY_BOT_MESSAGE: 'bot/HISTORY_BOT_MESSAGE',
  HISTORY_BOT_MESSAGE_SUCCESS: 'bot/HISTORY_BOT_MESSAGE_SUCCESS',
  CLEAR_HISTORY_BOT_MESSAGE: 'bot/CLEAR_HISTORY_BOT_MESSAGE',
  CLEAR_HISTORY_BOT_FILTER_SESSIONS: 'bot/CLEAR_HISTORY_BOT_FILTER_SESSIONS',
  HISTORY_BOT_FILTER_SESSIONS: 'bot/HISTORY_BOT_FILTER_SESSIONS',
  HISTORY_BOT_FILTER_SESSIONS_SUCCESS:
    'bot/HISTORY_BOT_FILTER_SESSIONS_SUCCESS',
  HISTORY_BOT_FILTER_DASHBOARD: 'bot/HISTORY_BOT_FILTER_DASHBOARD',
  HISTORY_BOT_FILTER_DASHBOARD_SUCCESS:
    'bot/HISTORY_BOT_FILTER_DASHBOARD_SUCCESS',
  HISTORY_BOT_FILTER_SESSIONS_SEARCH_SUCCESS:
    'bot/HISTORY_BOT_FILTER_SESSIONS_SEARCH_SUCCESS',
  BOT_UPDATE_DATE: 'bot/BOT_UPDATE_DATE',
  UPDATE_BOT_HUMAN_ATTENDANCE_REQUEST:
    'bot/UPDATE_BOT_HUMAN_ATTENDENCE_REQUEST',
  GET_QUEUE_ATTENDANCE: 'bot/GET_QUEUE_ATTENDANCE',
  SET_QUEUE_ATTENDANCE: 'bot/SET_QUEUE_ATTENDANCE',
  UPDATE_BOT_STATUS: 'bot/UPDATE_BOT_STATUS',
  UPDATE_BOT_IA_STATUS: 'bot/UPDATE_BOT_IA_STATUS',
  BOT_COLLECT_DATA_COUNT: 'bot/BOT_COLLECT_DATA_COUNT',
  BOT_COLLECT_DATA_COUNT_SUCCESS: 'bot/BOT_COLLECT_DATA_COUNT_SUCCESS',
  BOT_COLLECT_DATA_CSV: 'bot/BOT_COLLECT_DATA_CSV',
  BOT_COLLECT_DATA_CSV_RESET: 'bot/BOT_COLLECT_DATA_CSV_RESET',
  BOT_COLLECT_DATA_CSV_RESET_SUCCESS: 'bot/BOT_COLLECT_DATA_CSV_RESET_SUCCESS',
  BOT_COLLECT_DATA_COUNT_RESET: 'bot/BOT_COLLECT_DATA_COUNT_RESET',
  BOT_COLLECT_DATA_COUNT_RESET_SUCCESS:
    'bot/BOT_COLLECT_DATA_COUNT_RESET_SUCCESS',
  OPEN_NEW_BOT_PAGE: 'bot/OPEN_NEW_BOT_PAGE',
  LIST_TEMPLATES_BOT: 'bot/LIST_TEMPLATES_BOT',
  LIST_TEMPLATES_BOT_SUCCESS: 'bot/LIST_TEMPLATES_BOT_SUCCESS',
  UPDATE_GENERAL_BOT_INFO: 'bot/UPDATE_GENERAL_BOT_INFO',
  UPDATE_GENERAL_BOT_INFO_SUCCESS: 'bot/UPDATE_GENERAL_BOT_INFO_SUCCESS',
  UPDATE_ZENDESK_TICKET_SETTINGS: 'bot/UPDATE_ZENDESK_TICKET_SETTINGS',
  RETRIEVE_ZENDESK_TICKET_SETTINGS: 'bot/RETRIEVE_ZENDESK_TICKET_SETTINGS',
  UPDATE_JIRA_SETTINGS: 'bot/UPDATE_JIRA_SETTINGS',
  RETRIEVE_JIRA_SETTINGS: 'bot/RETRIEVE_JIRA_SETTINGS',
  UPDATE_GLPI_SETTINGS: 'bot/UPDATE_GLPI_SETTINGS',
  RETRIEVE_GLPI_SETTINGS: 'bot/RETRIEVE_GLPI_SETTINGS',
  UPDATE_ATTENDANCE_HOUR_SETTINGS: 'bot/UPDATE_ATTENDANCE_HOUR_SETTINGS',
  RETRIEVE_ATTENDANCE_HOUR_SETTINGS: 'bot/RETRIEVE_ATTENDANCE_HOUR_SETTINGS',
  PUBLISH_BOT: 'bot/PUBLISH_BOT',
  PUBLISH_BOT_SUCCESS: 'bot/PUBLISH_BOT_SUCCESS',
  PUBLISH_VOICE_BOT: 'bot/PUBLISH_VOICE_BOT',
  CHANGE_STAGING_AREA: 'bot/CHANGE_STAGING_AREA',
  SAVE_QUICK_ACCESS_SETTINGS: 'bot/SAVE_QUICK_ACCESS_SETTINGS',
  SAVE_QUICK_ACCESS_SETTINGS_SUCCESS: 'bot/SAVE_QUICK_ACCESS_SETTINGS_SUCCESS',
  SAVE_PUBLISH_QUICK_ACCESS_SETTINGS: 'bot/SAVE_PUBLISH_QUICK_ACCESS_SETTINGS',
  COUNT_STATUS_CREATOR_NO_SAVE: 'bot/COUNT_STATUS_CREATOR_NO_SAVE',
  COUNT_STATUS_CREATOR_NO_SAVE_SUCCESS:
    'bot/COUNT_STATUS_CREATOR_NO_SAVE_SUCCESS',
  COUNT_STATUS_CREATOR_NO_SAVE_REMOVE:
    'bot/COUNT_STATUS_CREATOR_NO_SAVE_REMOVE',
  COUNT_STATUS_CREATOR_NO_SAVE_REMOVE_SUCCESS:
    'bot/COUNT_STATUS_CREATOR_NO_SAVE_REMOVE_SUCCESS',
};

/* Actions */

export const BotsCreators = {
  editBotTitleRequest: (botid, title) => ({
    type: BotsTypes.EDIT_TITLE_BOT_REQUEST,
    payload: {
      botid,
      title,
      showToast: true,
    },
  }),

  editBotTitleSuccess: (bot) => ({
    type: BotsTypes.EDIT_TITLE_BOT_SUCCESS,
    payload: bot,
  }),

  updateBotRequest: (
    botid,
    data,
    showToast = true,
    showLoading = false,
    updateWhatsappCallbacks = false
  ) => ({
    type: BotsTypes.UPDATE_BOT_REQUEST,
    payload: {
      botid,
      data,
      updateWhatsappCallbacks,
      showToast,
      showLoading,
    },
  }),

  creatorBotRequest: (botid) => ({
    type: BotsTypes.CREATOR_BOT_REQUEST,
    payload: botid,
  }),

  historyMessage: (infoMessages) => ({
    type: BotsTypes.HISTORY_BOT_MESSAGE,
    payload: infoMessages,
  }),

  clearHistoryMessage: () => ({
    type: BotsTypes.CLEAR_HISTORY_BOT_MESSAGE,
  }),

  filterSessions: (infoSession) => ({
    type: BotsTypes.HISTORY_BOT_FILTER_SESSIONS,
    payload: infoSession,
  }),

  searchFilterSessions: (sessions) => ({
    type: BotsTypes.HISTORY_BOT_FILTER_SESSIONS_SEARCH_SUCCESS,
    payload: sessions,
  }),

  clearFilterSessions: () => ({
    type: BotsTypes.CLEAR_HISTORY_BOT_FILTER_SESSIONS,
  }),

  filterDashboard: (infoDashboard) => ({
    type: BotsTypes.HISTORY_BOT_FILTER_DASHBOARD,
    payload: infoDashboard,
  }),

  // historySessions: botid => ({
  //   type: BotsTypes.HISTORY_BOT_SESSIONS,
  //   payload: botid,
  // }),

  creatorBotSuccess: (bot) => ({
    type: BotsTypes.CREATOR_BOT_SUCCESS,
    payload: bot,
  }),

  openNewBotPage: () => ({
    type: BotsTypes.OPEN_NEW_BOT_PAGE,
    payload: {},
  }),

  newBotRequest: (data) => ({
    type: BotsTypes.NEW_BOT_REQUEST,
    payload: { ...data },
  }),

  newBotSuccess: () => ({
    type: BotsTypes.NEW_BOT_SUCCESS,
    payload: null,
  }),

  newVoiceBotRequest: (data) => ({
    type: BotsTypes.NEW_VOICE_BOT_REQUEST,
    payload: { ...data },
  }),

  updateVoiceBotRequest: (data) => ({
    type: BotsTypes.UPDATE_VOICE_BOT_REQUEST,
    payload: { ...data },
  }),

  removeBotRequest: (botid) => ({
    type: BotsTypes.REMOVE_BOT_REQUEST,
    payload: botid,
  }),

  removeVoiceBotRequest: (botid) => ({
    type: BotsTypes.REMOVE_VOICE_BOT_REQUEST,
    payload: botid,
  }),

  cloneVoiceBotRequest: ({ botId, title }, callback) => ({
    type: BotsTypes.CLONE_VOICE_BOT_REQUEST,
    payload: { botId, title, callback },
  }),

  cloneVoiceBotSuccess: (bot) => ({
    type: BotsTypes.CLONE_VOICE_BOT_SUCCESS,
    payload: { bot },
  }),

  removeBotSuccess: (botid) => ({
    type: BotsTypes.REMOVE_BOT_SUCCESS,
    payload: botid,
  }),

  listBotsRequest: () => ({
    type: BotsTypes.LIST_BOTS_REQUEST,
    payload: null,
  }),

  listBotsSuccess: (bots) => ({
    type: BotsTypes.LIST_BOTS_SUCCESS,
    payload: bots,
  }),

  retrieveBotRequest: (botId) => ({
    type: BotsTypes.RETRIEVE_BOT_REQUEST,
    payload: botId,
  }),

  retrieveBotSuccess: (bot) => ({
    type: BotsTypes.RETRIEVE_BOT_SUCCESS,
    payload: bot,
  }),

  channelsBotRequest: (bot) => ({
    type: BotsTypes.CHANNELS_BOT_REQUEST,
    payload: bot,
  }),

  saveBotViewPosition: (botid, viewPosition) => ({
    type: BotsTypes.SAVE_VIEW_POSITION_BOT,
    payload: {
      botid,
      viewPosition,
    },
  }),

  publishBotWidgetHtmlRequest: (data) => ({
    type: BotsTypes.PUBLISH_WIDGET_HTML,
    payload: data,
  }),

  publishBotWidgetHtmlSuccess: (data) => ({
    type: BotsTypes.PUBLISH_WIDGET_HTML_SUCCESS,
    payload: data,
  }),

  saveBotWidgetHtmlRequest: (data) => ({
    type: BotsTypes.SAVE_WIDGET_HTML,
    payload: data,
  }),

  saveBotWidgetHtmlSuccess: (data) => ({
    type: BotsTypes.SAVE_WIDGET_HTML_SUCCESS,
    payload: data,
  }),

  saveBotRootItem: (itemid, botid) => ({
    type: BotsTypes.SAVE_ROOT_ITEM,
    payload: {
      botid,
      itemid,
    },
  }),

  botUpdateDate: (date) => ({
    type: BotsTypes.BOT_UPDATE_DATE,
    payload: date,
  }),

  updateBotHumanAttendanceRequest: (id, data) => ({
    type: BotsTypes.UPDATE_BOT_HUMAN_ATTENDANCE_REQUEST,
    payload: { id, data },
  }),

  getQueueAttendance: (params) => ({
    type: BotsTypes.GET_QUEUE_ATTENDANCE,
    payload: params,
  }),

  updateBotStatus: (botId, description, isActive) => ({
    type: BotsTypes.UPDATE_BOT_STATUS,
    payload: {
      botId,
      data: {
        description,
        isActive,
      },
    },
  }),
  updateBotIaStatus: (botId, iaStatus) => ({
    type: BotsTypes.UPDATE_BOT_IA_STATUS,
    payload: {
      botId,
      iaStatus,
    },
  }),

  getBotCollectDataCount: (botId, token, { startDate, endDate }) => ({
    type: BotsTypes.BOT_COLLECT_DATA_COUNT,
    payload: { botId, token, startDate, endDate },
  }),

  getBotCollectDataCsv: (
    botId,
    token,
    showSensitivityData,
    { startDate, endDate }
  ) => ({
    type: BotsTypes.BOT_COLLECT_DATA_CSV,
    payload: { botId, token, showSensitivityData, startDate, endDate },
  }),

  resetBotCollectDataCount: () => ({
    type: BotsTypes.BOT_COLLECT_DATA_COUNT_RESET,
    payload: {},
  }),

  updateBotGeneralInformation: (payload) => ({
    type: BotsTypes.UPDATE_GENERAL_BOT_INFO,
    payload,
  }),

  updateZendeskTicketSettings: (botId, data) => ({
    type: BotsTypes.UPDATE_ZENDESK_TICKET_SETTINGS,
    botId,
    settings: data,
  }),

  updateGlpiSettings: (botId, data) => ({
    type: BotsTypes.UPDATE_GLPI_SETTINGS,
    botId,
    settings: data,
  }),

  updateBotQuickAccess: (botId, data) => ({
    type: BotsTypes.SAVE_QUICK_ACCESS_SETTINGS,
    botId,
    settings: data,
  }),

  updateBotQuickAccessState: (payload) => ({
    type: BotsTypes.SAVE_QUICK_ACCESS_SETTINGS_SUCCESS,
    payload,
  }),

  updatePublishBotQuickAccess: (botId, data) => ({
    type: BotsTypes.SAVE_PUBLISH_QUICK_ACCESS_SETTINGS,
    botId,
    settings: data,
  }),

  updateJiraSettings: (botId, data) => ({
    type: BotsTypes.UPDATE_JIRA_SETTINGS,
    botId,
    settings: data,
  }),

  updateAttendanceHoursSettings: (botId, data) => ({
    type: BotsTypes.UPDATE_ATTENDANCE_HOUR_SETTINGS,
    botId,
    settings: data,
  }),

  publishBot: (botId, isToActive) => ({
    type: BotsTypes.PUBLISH_BOT,
    botId,
    isToActive,
  }),

  publishVoiceBot: (botId) => ({
    type: BotsTypes.PUBLISH_VOICE_BOT,
    botId,
  }),

  changeStagingArea: (botId, chosenVersion) => ({
    type: BotsTypes.CHANGE_STAGING_AREA,
    botId,
    chosenVersion,
  }),

  countStatusCreatorNoSave: (payload) => ({
    type: BotsTypes.COUNT_STATUS_CREATOR_NO_SAVE,
    payload,
  }),
  countStatusCreatorNoSaveRemove: (payload) => ({
    type: BotsTypes.COUNT_STATUS_CREATOR_NO_SAVE_REMOVE,
    payload,
  }),
};
