import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { connectRouter } from 'connected-react-router';
import bots from './bots';
import items from './items';
import auth from './auth';
import previewBot from './preview-bot';
import common from './common';
import companies from './companies';
import users from './users';
import permissions from './permissions';
import intelligence from './intelligence';
import connectors from './connectors';
import filter from './filter';
import debounce from './debounce';
import reports from './reports';
import facebookMessenger from './facebook-messenger';
import variables from './variables';
import plans from './plans';
import tags from './tags';
import notifications from './notifications';
import templateBot from './template-bot';
import newBot from './new-bot';
import botCreator from './bot-creator';
import triggers from './triggers';
import voiceBot from './voice-bots';
import voiceBotTts from './voice-bots-tts';
import configPage from './page-config';
import iaReports from './iaReports';
import reCaptcha from './reCaptcha';
import registerFrom from './register-from';
import rdStation from './rd-station';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    toastr: toastrReducer,
    bots,
    previewBot,
    items,
    auth,
    intelligence,
    common,
    companies,
    users,
    permissions,
    connectors,
    filter,
    debounce,
    reports,
    variables,
    facebookMessenger,
    plans,
    tags,
    notifications,
    templateBot,
    newBot,
    botCreator,
    triggers,
    voiceBot,
    voiceBotTts,
    configPage,
    iaReports,
    reCaptcha,
    rdStation,
    registerFrom,
  });
