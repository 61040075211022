import { DebounceTypes } from '../actions/debounce';

/* Reducer */
const INITIAL_STATE = {
  list: [],
  time: 3,
};

export default function debounce(state = INITIAL_STATE, action) {
  const { payload, type } = action;

  switch (type) {
    case DebounceTypes.ADD_ITEM_DEBOUNCE:
      if (
        payload.type === 'UPDATE_ITEM' &&
        state.list.find(
          (item) =>
            item.payload._id === payload.payload._id &&
            item.type === payload.type
        )
      ) {
        return {
          ...state,
          list: state.list.map((item) =>
            item.payload._id === payload.payload._id &&
            item.type === payload.type
              ? payload
              : item
          ),
        };
      }
      return { ...state, list: [...state.list, payload] };

    case DebounceTypes.CLEAR_DEBOUNCE:
      return { ...state, list: [] };

    case DebounceTypes.SET_TIME:
      return { ...state, time: payload };

    case DebounceTypes.EXECUTE_DEBOUNCE:
      return state;

    case DebounceTypes.DELETE_ITEM_DEBOUNCE:
      return { ...state, list: state.list.filter((elem) => elem !== payload) };

    default:
      return state;
  }
}
