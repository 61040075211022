export const RdStationTypes = {
  GET_FIELDS_REQUEST: 'rd-station/GET_FIELDS_REQUEST',
  GET_FIELDS_SUCCESS: 'rd-station/GET_FIELDS_SUCCESS',
  RD_STATION_LOGIN: 'rd-station/RD_STATION_LOGIN',
  GENERATE_ACCESS_TOKEN_REQUEST: 'rd-station/GENERATE_ACCESS_TOKEN_REQUEST',
  GENERATE_ACCESS_TOKEN_SUCCESS: 'rd-station/GENERATE_ACCESS_TOKEN_SUCCESS',
  REMOVE_ACCESS_TOKEN_REQUEST: 'rd-station/REMOVE_ACCESS_TOKEN_REQUEST',
  RESET_AUTH_DATA: 'rd-station/RESET_AUTH_DATA',
};

export const rdStationLogin = () => ({
  type: RdStationTypes.RD_STATION_LOGIN,
  payload: null,
});

export const generateAccessToken = (payload) => ({
  type: RdStationTypes.GENERATE_ACCESS_TOKEN_REQUEST,
  payload,
});

export const generateAccessTokenSuccess = (payload) => ({
  type: RdStationTypes.GENERATE_ACCESS_TOKEN_REQUEST,
  payload,
});

export const deleteAccessToken = (payload) => ({
  type: RdStationTypes.REMOVE_ACCESS_TOKEN_REQUEST,
  payload,
});

export const getFields = (params) => ({
  type: RdStationTypes.GET_FIELDS_REQUEST,
  payload: { ...params },
});

export const getFieldsSuccess = (customFields) => ({
  type: RdStationTypes.GET_FIELDS_SUCCESS,
  payload: customFields,
});

export const resetAuthData = () => ({
  type: RdStationTypes.RESET_AUTH_DATA,
  payload: null,
});
