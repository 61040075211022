import { call, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import api from '../../services/api.reports';
import { IaReportAction } from '../actions/iaReports';

const loadError = () => {
  toastr.error('Erro', 'Erro ao carregar os relatorios');
};

const addEndpointParameters = ({
  startDate,
  endDate,
  botId,
  tags,
  channels,
  variables,
  timezone
}) => {
  let endpoint = `${botId}?start_date=${startDate}&end_date=${endDate}`;

  if (tags.length !== 0) {
    endpoint += `&tags=${encodeURIComponent(JSON.stringify(tags))}`;
  }

  if (channels.length !== 0) {
    endpoint += `&channels=${encodeURIComponent(JSON.stringify(channels))}`;
  }

  if (variables.length !== 0) {
    endpoint += `&variables=${encodeURIComponent(JSON.stringify(variables))}`;
  }

  if (timezone.length !== 0) {
    endpoint += `&timezone=${encodeURIComponent(JSON.stringify(timezone))}`;
  }

  return endpoint;
};

export function* getNotUnderstoodIntentions(action) {
  try {
    const { payload } = action;
    const { getNotUnderstoodIntentionsSuccess } = IaReportAction;
    const url = `ia/not-understood/${addEndpointParameters(payload)}`;
    const response = yield call(api.get, url);

    yield put(getNotUnderstoodIntentionsSuccess(response.data));
  } catch (error) {
    loadError();
  }
}

export function* getUseOfIntentions(action) {
  try {
    const { payload } = action;
    const { getUseOfIntentionsSuccess } = IaReportAction;
    const url = `/ia/total-usage/${addEndpointParameters(payload)}`;
    const response = yield call(api.get, url);

    yield put(getUseOfIntentionsSuccess(response.data));
  } catch (error) {
    loadError();
  }
}

export function* getPerformanceOfIntentions(action) {
  try {
    const { payload } = action;
    const { getPerformanceOfIntentionsSuccess } = IaReportAction;
    const url = `/ia/intention-performance/${addEndpointParameters(payload)}`;
    const response = yield call(api.get, url);

    yield put(getPerformanceOfIntentionsSuccess(response.data));
  } catch (error) {
    loadError();
  }
}
