import { VoiceBotsTtsTypes } from '../actions/voice-bots-tts';

const initialState = {
  generatedTts: null,
  loadingGenerateTts: false,
  voiceBotAudios: [],
  audioUrl: null,
};

export default function voiceBotsTtsState(state = initialState, action) {
  switch (action.type) {
    case VoiceBotsTtsTypes.UPLOAD_AUDIOS_SUCCESS:
      return {
        ...state,
        voiceBotAudios: [...state.voiceBotAudios, ...action.payload.audios],
      };

    case VoiceBotsTtsTypes.DELETE_AUDIO_SUCCESS: {
      const upadedAudios = state.voiceBotAudios.filter(
        (audio) => audio._id !== action.payload._id
      );

      return {
        ...state,
        voiceBotAudios: upadedAudios,
      };
    }

    case VoiceBotsTtsTypes.FETCH_AUDIOS_SUCCESS:
      return {
        ...state,
        voiceBotAudios: action.payload.audios,
      };

    case VoiceBotsTtsTypes.GENERATE_TTS_REQUEST:
      return {
        ...state,
        loadingGenerateTts: true,
      };
    case VoiceBotsTtsTypes.GENERATE_TTS_SUCCESS:
      return {
        ...state,
        loadingGenerateTts: false,
        audioUrl: action.payload.audioUrl,
      };
    case VoiceBotsTtsTypes.GENERATE_TTS_FAILED:
      return {
        ...state,
        loadingGenerateTts: false,
      };
    case VoiceBotsTtsTypes.SET_TTS_AUDIO_URL:
      return {
        ...state,
        audioUrl: action.payload.url,
      };

    default:
      return {
        ...state,
      };
  }
}
