export const TriggerTypes = {
  LOAD: '@triggers/LOAD',
  LOAD_SUCCESS: '@triggers/LOAD_SUCCESS',
  CHANGE_ACTIVATOR: '@triggers/CHANGE_ACTIVATOR',
  REMOVE_TRIGGER: '@triggers/REMOVE_TRIGGER',
  CHANGE_ACTION: '@triggers/CHANGE_ACTION',
  ADD_TRIGGER_WORD: '@triggers/ADD_TRIGGER_WORD',
  UPDATE_MESSAGE: '@triggers/UPDATE_MESSAGE',
  REMOVE_WORD: '@triggers/REMOVE_WORD',
  UPDATE_INACTIVITY_TIME: '@triggers/UPDATE_INACTIVITY_TIME',
  UPDATE_TIME_WITHOUT_RESPONSE: '@triggers/UPDATE_TIME_WITHOUT_RESPONSE',
  UPDATE_SQUARE: '@triggers/UPDATE_SQUARE',
  SAVE_BOT_TRIGGERS: '@triggers/SAVE_BOT_TRIGGERS',
  CREATE_EMPTY_TRIGGER: '@triggers/CREATE_EMPTY_TRIGGER',
};

export const TriggerActions = {
  load: (botId) => ({
    type: TriggerTypes.LOAD,
    payload: { botId },
  }),

  loadSucces: (payload) => ({
    type: TriggerTypes.LOAD_SUCCESS,
    payload,
  }),

  changeActivator: (triggerId, activator) => ({
    type: TriggerTypes.CHANGE_ACTIVATOR,
    payload: { triggerId, activator },
  }),

  deleteTrigger: (triggerId) => ({
    type: TriggerTypes.REMOVE_TRIGGER,
    payload: { triggerId },
  }),

  changeAction: (triggerId, action) => ({
    type: TriggerTypes.CHANGE_ACTION,
    payload: { triggerId, action },
  }),

  addTriggerWord: (triggerId, word) => ({
    type: TriggerTypes.ADD_TRIGGER_WORD,
    payload: { triggerId, word },
  }),

  updateMessage: (triggerId, message) => ({
    type: TriggerTypes.UPDATE_MESSAGE,
    payload: { triggerId, message },
  }),

  removeWord: (triggerId, wordArray) => ({
    type: TriggerTypes.REMOVE_WORD,
    payload: { triggerId, wordArray },
  }),

  updateInactivityTime: (triggerId, time) => ({
    type: TriggerTypes.UPDATE_INACTIVITY_TIME,
    payload: { triggerId, time },
  }),

  updateTimeWithoutResponse: (time) => ({
    type: TriggerTypes.UPDATE_TIME_WITHOUT_RESPONSE,
    payload: { time },
  }),

  updateSquare: (triggerId, squareId) => ({
    type: TriggerTypes.UPDATE_SQUARE,
    payload: { triggerId, squareId },
  }),

  saveBotTriggers: (botId, triggers, maxTimeWithoutResponse) => ({
    type: TriggerTypes.SAVE_BOT_TRIGGERS,
    payload: { botId, triggers, maxTimeWithoutResponse },
  }),

  createEmptyTrigger: (objectId) => ({
    type: TriggerTypes.CREATE_EMPTY_TRIGGER,
    payload: {
      _id: objectId,
      receivedValues: [],
      inactivityTime: '',
      activator: '',
      action: 'message',
      message: '',
      square: '',
      version: 0,
    },
  }),
};
