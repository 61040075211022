/* eslint-disable no-param-reassign */
import { TriggerTypes } from '../actions/triggers';

const INITIAL_STATE = {
  triggers: [
    {
      _id: '',
      receivedValues: [],
      activator: '',
      inactivityTime: '',
      action: '',
      message: '',
      square: '',
    },
  ],
  maxTimeWithoutResponse: '',
};

export default function triggers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TriggerTypes.LOAD:
      return { ...state };

    case TriggerTypes.LOAD_SUCCESS:
      return {
        ...state,
        triggers: action.payload.triggers,
        maxTimeWithoutResponse: action.payload.maxTimeWithoutResponse,
      };

    case TriggerTypes.CHANGE_ACTIVATOR:
      const triggerActivatorIndex = state.triggers.findIndex(
        (trigger) => trigger._id === action.payload.triggerId
      );

      state.triggers.splice(triggerActivatorIndex, 1, {
        ...state.triggers[triggerActivatorIndex],
        activator: action.payload.activator,
      });

      return { ...state };

    case TriggerTypes.triggerSquare:
      const findIndex = state.triggers.findIndex(
        (trigger) => trigger._id === action.payload.triggerId
      );

      state.triggers.splice(findIndex, 1);

      return { ...state };

    case TriggerTypes.CHANGE_ACTION:
      const findActionTrigger = state.triggers.findIndex(
        (trigger) => trigger._id === action.payload.triggerId
      );

      state.triggers.splice(findActionTrigger, 1, {
        ...state.triggers[findActionTrigger],
        action: action.payload.action,
      });

      return { ...state };

    case TriggerTypes.REMOVE_TRIGGER:
      const findTriggerToRemove = state.triggers.findIndex(
        (trigger) => trigger._id === action.payload.triggerId
      );

      state.triggers.splice(findTriggerToRemove, 1);

      return { ...state };

    case TriggerTypes.UPDATE_MESSAGE:
      const findMessageTrigger = state.triggers.findIndex(
        (trigger) => trigger._id === action.payload.triggerId
      );

      state.triggers.splice(findMessageTrigger, 1, {
        ...state.triggers[findMessageTrigger],
        message: action.payload.message,
      });

      return { ...state };

    case TriggerTypes.ADD_TRIGGER_WORD:
      const findTriggerWordIndex = state.triggers.findIndex(
        (trigger) => trigger._id === action.payload.triggerId
      );

      state.triggers[findTriggerWordIndex].receivedValues.push(
        action.payload.word
      );

      return { ...state };

    case TriggerTypes.REMOVE_WORD:
      const findRemoveWordIndex = state.triggers.findIndex(
        (trigger) => trigger._id === action.payload.triggerId
      );

      // eslint-disable-next-line no-param-reassign
      state.triggers[findRemoveWordIndex].receivedValues =
        action.payload.wordArray;

      return { ...state };

    case TriggerTypes.UPDATE_INACTIVITY_TIME:
      const findInactivityTimeIndex = state.triggers.findIndex(
        (trigger) => trigger._id === action.payload.triggerId
      );

      state.triggers.splice(findInactivityTimeIndex, 1, {
        ...state.triggers[findInactivityTimeIndex],
        inactivityTime: action.payload.time,
      });

      return { ...state };

    case TriggerTypes.UPDATE_TIME_WITHOUT_RESPONSE:
      state.maxTimeWithoutResponse = action.payload.time;

      return { ...state };

    case TriggerTypes.UPDATE_SQUARE:
      const findIndexToUpdateSquare = state.triggers.findIndex(
        (trigger) => trigger._id === action.payload.triggerId
      );

      state.triggers.splice(findIndexToUpdateSquare, 1, {
        ...state.triggers[findIndexToUpdateSquare],
        square: action.payload.squareId,
      });

      return { ...state };

    case TriggerTypes.CREATE_EMPTY_TRIGGER:
      const baseState = {
        _id: action.payload._id,
        receivedValues: action.payload.receivedValues,
        inactivityTime: action.payload.inactivityTime,
        activator: action.payload.activator,
        action: action.payload.action,
        message: action.payload.message,
        square: action.payload.square,
        version: action.payload.version,
      };
      state.triggers.push(baseState);
      return { ...state };

    default:
      return state;
  }
}
