/* eslint-disable no-param-reassign */
import { IaReportTypes } from '../actions/iaReports';

const INITIAL_STATE = {
  notUnderstoodReports: [],
  useOfIntentions: [],
  performanceOfIntentions: [],
};

export default function iaReports(state = INITIAL_STATE, action) {
  switch (action.type) {
    case IaReportTypes.GET_NOT_UNDERSTOOD_INTENTIONS_SUCCESS:
      return { ...state, notUnderstoodReports: action.payload };

    case IaReportTypes.GET_USE_OF_INTENTIONS_SUCCESS:
      return { ...state, useOfIntentions: action.payload };

    case IaReportTypes.GET_PERFORMANCE_OF_INTENTIONS_SUCCESS:
      return { ...state, performanceOfIntentions: action.payload };

    default:
      return { ...state };
  }
}
