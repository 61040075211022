import { call, put, fork } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { IaTypes } from '../actions/intelligence';
import { BotsTypes } from '../actions/bots';
import { commonActions } from '../actions/common';

import api from '../../services/api';
import apiIa from '../../services/api.ia';

export function* createLuis(action) {
  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    const luis = yield call(api.get, `/v1/bots/${payload.botid}`);

    const findL = luis.data.ia.platforms.find(element => element.platform === payload.platform)

    if (!findL) {
      const { data } = yield call(apiIa.post, `/v1/ia/application/create`, {
        name: payload.botid,
        platform: payload.platform,
        credentials: payload.credentials,
        language: payload.language
      });

      yield put({ type: IaTypes.IA_CREATE_LUIS_SUCCESS, payload: data });
      yield put({ type: IaTypes.IA_PLATFORM_SUCCESS, payload: payload.platform });

      const response = yield call(api.put, `/v1/bots/update/${payload.botid}`, {
        currentTokenIa: data.token,
        ia: {
          platforms: [{
            token: data.token,
            platform: 'luis',
            isActive: true,
          }
          ]
        }
      });
      
      yield put({ type: BotsTypes.UPDATE_BOT_SUCCESS, payload: response.data });
      yield put({ type: IaTypes.IA_PLATFORM_SUCCESS, payload: payload.platform });

      toastr.success('Plataforma', 'Plataforma "Luis" criada com sucesso');
    } else {

      const response = yield call(api.put, `/v1/bots/update/${payload.botid}`, 
        luis.data.currentTokenIa === findL.token ?
        {iaEnabled: false} :
        {currentTokenIa: findL.token}
      );
      yield put({ type: BotsTypes.UPDATE_BOT_SUCCESS, payload: response.data });
      yield put({ type: IaTypes.IA_PLATFORM_SUCCESS, payload: findL.platform });
      
      if (luis.data.currentTokenIa === findL.token) {
        toastr.success('Plataforma', 'Plataforma "Luis" Desativada');
      } else {
        toastr.success('Plataforma', 'Plataforma "Luis" Ativada');
      }
    }

  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Algo deu errado');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}


export function* listLuis(action) {
  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })
    const { data } = yield call(apiIa.post, `/v1/ia/application/get`, { ...payload });
    yield put({ type: IaTypes.IA_LIST_WIT_SUCCESS, payload: data });
  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Algo deu errado');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}

export function* createWit(action) {
  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    const wit = yield call(api.get, `/v1/bots/${payload.botid}`);

    const findL = wit.data.ia.platforms.find(element => element.platform === payload.platform)

    if (!findL) {
      const { data } = yield call(apiIa.post, `/v1/ia/application/create`, {
        name: payload.botid,
        platform: payload.platform,
        credentials: payload.credentials,
        language: payload.language
      });

      yield put({ type: IaTypes.IA_CREATE_WIT_SUCCESS, payload: data });
      yield put({ type: IaTypes.IA_PLATFORM_SUCCESS, payload: payload.platform });

      const response = yield call(api.put, `/v1/bots/update/${payload.botid}`, {
        currentTokenIa: data.token,
        ia: {
          platforms: [{
            token: data.token,
            platform: 'wit',
            isActive: true,
          }
          ]
        }
      });
      yield put({ type: BotsTypes.UPDATE_BOT_SUCCESS, payload: response.data });
      yield put({ type: IaTypes.IA_PLATFORM_SUCCESS, payload: payload.platform });
      toastr.success('Plataforma', 'Plataforma "Wit" criada com sucesso');
    } else {
      const response = yield call(api.put, `/v1/bots/update/${payload.botid}`, 
        wit.data.currentTokenIa === findL.token ? 
        {iaEnabled: false} :
        {currentTokenIa: findL.token}
      );
      yield put({ type: BotsTypes.UPDATE_BOT_SUCCESS, payload: response.data });
      yield put({ type: IaTypes.IA_PLATFORM_SUCCESS, payload: findL.platform });

      if (wit.data.currentTokenIa === findL.token) {
        toastr.success('Plataforma', 'Plataforma "Wit" Desativada');
      } else {
        toastr.success('Plataforma', 'Plataforma "Wit" Ativada');
      }
    }

  } catch (error) {
    console.error(error.response)
    toastr.error('Ops', 'Algo deu errado');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}

export function* listWit(action) {
  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    const { data } = yield call(apiIa.post, `/v1/ia/application/get`, { ...payload });

    yield put({ type: IaTypes.IA_LIST_WIT_SUCCESS, payload: data });

  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Algo deu errado');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}


export function* createBoteria(action) {
  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    const boteria = yield call(api.get, `/v1/bots/${payload.botid}`);

    const findL = boteria.data.ia.platforms.find(element => element.platform === payload.platform)
    if (!findL) {
      const { data } = yield call(apiIa.post, `/v1/ia/application/create`, {
        name: payload.botid,
        platform: 'wit',
        credentials: payload.credentials
      });

      yield put({ type: IaTypes.IA_CREATE_BOTERIA_SUCCESS, payload: data });
      yield put({ type: IaTypes.IA_PLATFORM_SUCCESS, payload: 'boteria' });

      const response = yield call(api.put, `/v1/bots/update/${payload.botid}`, {
        currentTokenIa: data.token,
        ia: {
          platforms: [{
            token: data.token,
            platform: 'boteria',
            isActive: true,
          }
          ]
        }
      });

      yield put({ type: BotsTypes.UPDATE_BOT_SUCCESS, payload: response.data });
      yield put({ type: IaTypes.IA_PLATFORM_SUCCESS, payload: 'boteria' });

      toastr.success('Plataforma', 'Plataforma "Boteria" criada com sucesso');
    } else {
      const response = yield call(api.put, `/v1/bots/update/${payload.botid}`, 
        boteria.data.currentTokenIa === findL.token ? 
        {iaEnabled: false} :
        {currentTokenIa: findL.token}
      );
      yield put({ type: BotsTypes.UPDATE_BOT_SUCCESS, payload: response.data });
      yield put({ type: IaTypes.IA_PLATFORM_SUCCESS, payload: findL.platform });

      if (boteria.data.currentTokenIa === findL.token) {
        toastr.success('Plataforma', 'Plataforma "Boteria" Desativada');
      } else {
        toastr.success('Plataforma', 'Plataforma "Boteria" Ativada');
      }
    }
  } catch (error) {
    console.error(error.response)
    toastr.error('Ops', 'Algo deu errado');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}



export function* createSamples(action) {

  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    console.log(payload)
    yield call(apiIa.post, `/v1/ia/sample/upsert`, { ...payload });

    const { data } = yield call(apiIa.post, `v1/ia/sample/summary`, { token: payload.token, type: 'intent' });
    yield put({ type: IaTypes.IA_LIST_ALL_SAMPLES_SUCCESS, payload: data });
    toastr.success('Frase de exemplo', 'Frases criada com sucesso');

  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Algo deu errado');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}

export function* listAllSamples(action) {
  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })
    const { data } = yield call(apiIa.post, `v1/ia/sample/summary`, { ...payload });
    yield put({ type: IaTypes.IA_LIST_ALL_SAMPLES_SUCCESS, payload: data });

  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Não foi possível listar as intenções.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}

export function* deleteSamples(action) {
  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })
    yield call(apiIa.post, `/v1/ia/sample/delete`, { ...payload });

    const { data } = yield call(apiIa.post, `v1/ia/sample/summary`, { token: payload.token, type: 'intent' });
    yield put({ type: IaTypes.IA_LIST_ALL_SAMPLES_SUCCESS, payload: data });

    toastr.success('Inteções', 'Frase de exemplo excluída com sucesso');

  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Não foi possível deletar as as frases.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}

export function* createIntents(action) {
  const { payload } = action;

  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })
    yield call(apiIa.post, `/v1/ia/intent/insert`, { ...payload });

    if (payload.sentence.length >= 1) {
      for (let i = 0; i < payload.sentence.length; i++) {
        yield call(apiIa.post, `/v1/ia/sample/upsert`, {
          token: payload.token,
          sentence: payload.sentence[i].text,
          intents: [{ name: payload.name }]
        });
      }
    }

    const response = yield call(apiIa.post, `/v1/ia/intent/get`, { token: payload.token });
    yield put({ type: IaTypes.IA_LIST_INTENTS_SUCCESS, payload: response.data });

    const { data } = yield call(apiIa.post, `v1/ia/sample/summary`, { token: payload.token, type: "intent" });
    yield put({ type: IaTypes.IA_LIST_ALL_SAMPLES_SUCCESS, payload: data });

    toastr.success('Intenções', 'Intenção criada com sucesso');

  } catch (error) {
    console.log(error.response)
    toastr.error('Ops', 'Não foi possível criar as intenções.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}

export function* listIAIntents(action) {
  const { payload } = action;
  try {
    // if (!payload.ia) throw new Error("Invalid or missing params");
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    const { data } = yield call(apiIa.post, `v1/ia/intent/get`, { ...payload });
    yield put({ type: IaTypes.IA_LIST_INTENTS_SUCCESS, payload: data });

  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Não foi possível listar as intenções.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}

export function* deleteIntents(action) {
  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    yield call(apiIa.post, `/v1/ia/intent/delete`, { ...payload });


    const { data } = yield call(apiIa.post, `/v1/ia/intent/get`, { token: payload.token });
    yield put({ type: IaTypes.IA_LIST_INTENTS_SUCCESS, payload: data });

    const response = yield call(apiIa.post, `v1/ia/sample/summary`, { token: payload.token, type: 'intent' });
    yield put({ type: IaTypes.IA_LIST_ALL_SAMPLES_SUCCESS, payload: response.data });

    toastr.success('Inteções', 'Intenção excluída com sucesso');

  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Não foi possível deletar as intenções.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}


export function* listIAEntities(action) {

  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    const { data } = yield call(apiIa.post, `/v1/ia/entity/get`, { ...payload });
    yield put({ type: IaTypes.IA_LIST_ENTITIES_SUCCESS, payload: data });
  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Não foi possível listar as Entidades.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}


export function* listEntity(action) {
  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    const { data } = yield call(apiIa.post, `/v1/ia/entity/get`, { ...payload });
    yield put({ type: IaTypes.IA_LIST_ENTITY_SUCCESS, payload: data });
  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Não foi possível listar as Entidades.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}

export function* createEntities(action) {
  
  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    yield call(apiIa.post, `/v1/ia/entity/upsert`, { ...payload });

    const { data } = yield call(apiIa.post, `/v1/ia/entity/get`, { token: payload.token });
    yield put({ type: IaTypes.IA_LIST_ENTITIES_SUCCESS, payload: data });

    const  response = yield call(apiIa.post, `/v1/ia/entity/get`, { token: payload.token, name: payload.name });
    yield put({ type: IaTypes.IA_LIST_ENTITY_SUCCESS, payload: response.data });

    toastr.success('Entidade', 'Entidade criada com sucesso');

  } catch (error) {
    console.log(error.response)
    toastr.error('Ops', 'Não foi possível criar as intenções.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}

export function* deleteEntities(action) {
  
  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    yield call(apiIa.post, `/v1/ia/entity/delete`, { ...payload });

    const { data } = yield call(apiIa.post, `/v1/ia/entity/get`, { token: payload.token });
    yield put({ type: IaTypes.IA_LIST_ENTITIES_SUCCESS, payload: data });

    toastr.success('Entidade', 'Entidade deletada com sucesso');
  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Não foi possível deletar a Entidades.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}
export function* deleteEntitiesObj(action) {
  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    yield call(apiIa.post, `/v1/ia/entity/object/delete`, { ...payload });

    const { data } = yield call(apiIa.post, `/v1/ia/entity/get`, { token: payload.token });
    yield put({ type: IaTypes.IA_LIST_ENTITIES_SUCCESS, payload: data });

    toastr.success('Entidade', 'Objeto deletado com sucesso');
  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Não foi possível deletar a Entidades.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}
export function* deleteEntitiesSyn(action) {
  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    yield call(apiIa.post, `/v1/ia/entity/object/synonym/delete`, { ...payload });

    const { data } = yield call(apiIa.post, `/v1/ia/entity/get`, { token: payload.token, name: payload.name });
    yield put({ type: IaTypes.IA_LIST_ENTITY_SUCCESS, payload: data });

    toastr.success('Entidade', 'Sinonimo deletado com sucesso');
  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Não foi possível deletar a Entidades.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}

export function* listSamplesIntent(action) {

  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    const { data } = yield call(apiIa.post, `/v1/ia/sample/show`, { ...payload });
    yield put({ type: IaTypes.IA_LIST_SAMPLES_INTENT_SUCCESS, payload: data[0] });

  } catch (error) {
    console.error(error)
    toastr.error('Ops', 'Não foi possível listar as intenções.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}

export function* listCurationMessages(action) {

  const { payload } = action;

  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    if (!(payload.bot && payload.count)) throw new Error("Invalid or missing params");

    let url = `/v1/bots/curation/?bot=${payload.bot}&count=${payload.count}`;
    if (payload.query) url += `&query=${payload.query}`;
    if (payload.processed) url += `&processed=true`;
    if (payload.status) url += `&status=${payload.status}`;
    if (payload.start_date) url += `&start_date=${payload.start_date}`;
    if (payload.end_date) url += `&end_date=${payload.end_date}`;

    const { data } = yield call(api.get, url);
    yield put({ type: IaTypes.CURATION_BOT_LIST_MESSAGES_SUCCESS, payload: data.messages });

  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Não foi possível listar as mensagens da curadoria.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}

export function* ignoreCurationMessage(action) {
  const { payload } = action;
  try {

    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    if (!(payload.bot && payload.message)) throw new Error("Invalid or missing params");

    const url = `/v1/bots/curation/ignore/?bot=${payload.bot}&message=${payload.message}`;

    yield call(api.get, url);

    toastr.success('Curadoria', 'Mensagem ignorada com sucesso!');
  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Não foi possível ignorar esta mensagem da curadoria.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })

  }
}

export function* acceptCurationMessage(action) {
  const { payload } = action;
  try {

    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    if (!(payload.bot && payload.message)) throw new Error("Invalid or missing params");

    const url = `/v1/bots/curation/accept/?bot=${payload.bot}&message=${payload.message}`;

    const { data } = yield call(api.get, url);
    yield put({ type: IaTypes.CURATION_BOT_ACCEPT_MESSAGE_SUCCESS, payload: data.result });

    toastr.success('Curadoria', 'Mensagem aceita com sucesso!');
  } catch (error) {
    console.log(error.response)
    toastr.error('Ops', 'Não foi possível salvar esta mensagem.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}


export function* saveIASample(action) {
  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    if (!(payload.ia && payload.sample)) {
      throw new Error("Invalid or missing params");
    }

    const { data } = yield call(apiIa.post, `/v1/bots/ia/sample`, payload);
    yield put({ type: IaTypes.IA_SAVE_SAMPLE_SUCCESS, payload: data.result });

  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Não foi possível inserir o exemplo.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })

  }
}

export function* getConditions(action) {
  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    const { data } = yield call(api.post, '/v1/conditions/fetch?key=kgjdhURyashsJKSkd2kkd98Yf7', { botId: payload });
    yield put({ type: IaTypes.IA_LIST_CONDITIONS_SUCCESS, payload: JSON.parse(data) })
  } catch (error) {
    console.error(error)
    toastr.error('Ops', 'Não foi possível buscar Condições para o bot');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })

  }
}

export function* updateCondition(action) {
  const { payload } = action
  try {

    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    const { data } = yield fork(api.put, `/v1/conditions/${payload._id}?key=kgjdhURyashsJKSkd2kkd98Yf7`, { ...payload })

    toastr.success('Sucesso', 'Condição editada!');

    yield put({ type: IaTypes.IA_UPDATE_CONDITIONS_SUCCESS, payload: { ...payload } })
  } catch (error) {
    console.error(error)
    toastr.error('Ops', 'Não foi possível atualizar a condição do bot');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })

  }
}

export function* createCondition(action) {
  const { payload } = action
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })


    const { data } = yield call(api.post, `/v1/conditions?key=kgjdhURyashsJKSkd2kkd98Yf7`, { ...payload })

    yield put({ type: IaTypes.IA_CREATE_CONDITIONS_SUCCESS, payload: data })

    toastr.success('Sucesso', 'Condição criada');

  } catch (error) {
    console.log(error.response)
    toastr.error('Ops', 'Não foi possível criar uma condição para o bot');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })

  }
}

export function* deleteCondition(action) {
  const { payload } = action
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    const { data } = yield call(api.delete, `/v1/conditions/${payload}?key=kgjdhURyashsJKSkd2kkd98Yf7`)

    yield put({ type: IaTypes.IA_DELETE_CONDITIONS_SUCCESS, payload })
    toastr.success('Sucesso', 'Condição deletada');
  } catch (error) {
    console.log(error.response)
    toastr.error('Ops', 'Não foi possível deletar a condição');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}

export function* saveAprimoramentos(action) {
  const { aprimoramentos, options } = action.payload
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })
    for(let i = 0; i < aprimoramentos.length; ++i) {
      const { payload, type } = aprimoramentos[i]
      switch(type) {
        case 'ADD_SAMPLE_INTENT': 
          yield call(apiIa.post, `/v1/ia/sample/upsert`, {
            token: payload.token,
            sentence: payload.text,
            intents: [{ name: payload.name }]
          });
          break;
  
        case 'ADD_SAMPLE_ENTITY':
          yield call(apiIa.post, `/v1/ia/entity/upsert`, { token: payload.token, name: payload.name, objects: [ { name: payload.text, synonyms: [payload.text] } ] } );
          break;

        case 'ADD_INTENT':
          yield call(apiIa.post, `/v1/ia/intent/insert`, { ...payload });
          yield call(apiIa.post, `/v1/ia/sample/upsert`, {
            token: payload.token,
            sentence: payload.text,
            intents: [{ name: payload.name }]
          });
          break;

        case 'ADD_ENTITY':
          yield call(apiIa.post, `/v1/ia/entity/upsert`, { token: payload.token, name: payload.name, objects: [ { name: payload.text, synonyms: [payload.text] } ] } );
          break;
  
        default:
          break;
      }
    }

    const { data } = yield call(api.get, `/v1/bots/curation/accept/?bot=${options.bot}&message=${options.message}`);
    yield put({ type: IaTypes.CURATION_BOT_ACCEPT_MESSAGE_SUCCESS, payload: data.result });

    toastr.success('Sucesso', 'Aprimoramento Concluído');
  } catch (err) {
    console.error(err.response)
    toastr.error('Ops!', 'Erro ao aprimorar');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
    yield options.callback()
  }

}

export function* getIaInfo(action) {
  const { payload } = action;
  yield put({ type: commonActions.LOADING, payload: { loading: true } })
  try {
    const { data } = yield call(apiIa.post, `/v1/ia/application/info`, { ...payload });
    yield put({ type: IaTypes.IA_PLATFORM_INFO_SUCCESS, payload: data });
  } catch (error) {
    console.log(error)
    yield put({ type: IaTypes.IA_PLATFORM_INFO_ERROR });
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}

export function* trainLuis(action) {
  const { payload } = action;
  let maxTries = 10
  let tries = 0;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } })

    let result = yield call(apiIa.post, '/v1/ia/application/train', { token: payload.token });
    console.log(JSON.stringify(result))

    if (result.status === 200) {
      toastr.success('Plataforma', 'Treinamento efetuado com sucesso');
      let publishSuccess = false;
       
      while (!publishSuccess && tries <= maxTries) {
        console.log('aqui - ', tries)
        try {
          let result = yield call(apiIa.post, '/v1/ia/application/publish', { token: payload.token });
          
          publishSuccess = result.status === 200
          
          if (publishSuccess) {
            toastr.success('Plataforma', 'IA publicada com sucesso');
          }
        } catch(error) {
          
        }

        tries += 1;
      }

      if (!publishSuccess) {
        toastr.warning('Plataforma', 'IA nao pode ser publicada, tente novamente em alguns minutos.');
      }
    }
    const { data } = yield call(apiIa.post, `/v1/ia/application/info`, { ...payload });
    yield put({ type: IaTypes.IA_PLATFORM_INFO_SUCCESS, payload: data });
  } catch (error) {
    toastr.error('Ops', 'Algo deu errado');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } })
  }
}

export function* iaPreview(action) {
  const { payload } = action;
  const { token, message } = payload;

  yield put({ type: commonActions.LOADING, payload: { loading: true } });

  try {
    const { data } = yield call(apiIa.post, '/v1/ia/process/message', {
      token,
      message,
    });
    yield put({ type: IaTypes.IA_PREVIEW_SUCCESS, payload: { data, message } });
  } catch (error) {
    toastr.error('Ops', 'Algo deu errado');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}
