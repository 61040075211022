import { combineReducers } from 'redux';
import { NotificationTypes } from '../actions/notifications';

const initialState = {
  notifications: { data: [], totalCount: null, totalPages: null, page: 0 },
};

export const state = (state = initialState, action) => {
  switch (action.type) {
    case NotificationTypes.LIST_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      };

    case NotificationTypes.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      };

    default:
      return state;
  }
};

export default combineReducers({
  state,
});
