import { VoiceBotsTypes } from '../actions/voice-bots';

const initialState = {
  projects: [],
  grammars: [],
  statusList: [],
  voices: [],
  voicebotInstances: [],
  voicebotVariables: [],
  webApiList: [],
};

export default function voiceBotState(state = initialState, action) {
  switch (action.type) {
    case VoiceBotsTypes.FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload.projects,
      };

    case VoiceBotsTypes.FETCH_GRAMMARS_SUCCESS:
      return {
        ...state,
        grammars: action.payload.grammars,
      };

    case VoiceBotsTypes.FETCH_STATUS_SUCCESS:
      return {
        ...state,
        statusList: action.payload.status,
      };

    case VoiceBotsTypes.FETCH_VOICES_SUCCESS:
      return {
        ...state,
        voices: action.payload.voices,
      };
    case VoiceBotsTypes.FETCH_VOICES_FAILIURE:
      return {
        ...state,
        voices: [],
      };

    case VoiceBotsTypes.FETCH_VOICEBOT_INSTANCES_SUCCESS:
      return {
        ...state,
        voicebotInstances: action.payload.instances,
      };
    case VoiceBotsTypes.FETCH_VOICEBOT_INSTANCES_FAILIURE:
      return {
        ...state,
        voicebotInstances: [],
      };

    case VoiceBotsTypes.CREATE_VOICEBOT_INSTANCE_SUCCESS: {
      const updatedInstanceArray = [
        ...state.voicebotInstances,
        action.payload.instance,
      ];

      return {
        ...state,
        voicebotInstances: updatedInstanceArray,
      };
    }
    case VoiceBotsTypes.UPDATE_VOICEBOT_INSTANCE_SUCCESS: {
      const updatedInstance = action.payload.instance;

      const updatedInstances = state.voicebotInstances.map((instance) =>
        instance._id === updatedInstance._id ? updatedInstance : instance
      );

      return {
        ...state,
        voicebotInstances: updatedInstances,
      };
    }

    case VoiceBotsTypes.FETCH_VARIABLES_SUCCESS:
      return {
        ...state,
        voicebotVariables: action.payload.variables,
      };

    case VoiceBotsTypes.FETCH_WEB_API_SUCCESS:
      return {
        ...state,
        webApiList: action.payload.webApiList,
      };

    default:
      return {
        ...state,
      };
  }
}
