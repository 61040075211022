import { FilterTypes } from '../../store/actions/filter';

/* Reducer */

const INITIAL_STATE = {
  startDate: new Date(
    new Date().setDate(new Date().getDate() - 1)
  ),
  endDate: new Date(),
  vlSelect: '',
  dateFilter: new Date(),
  tags: [],
  nameTags: [],
  channels: [],
  variables: [],
};

export default function filter(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FilterTypes.LIST_FILTER_INFO:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        vlSelect: action.payload.vlSelect,
        tags: action.payload.tags,
        nameTags: action.payload.nameTags,
        channels: action.payload.channels,
        variables: action.payload.variables,
      };
    case FilterTypes.FILTER_RELATORIO_INFO:
      return {
        ...state,
        dateFilter: action.payload.dateFilter,
      };
    default:
      return state;
  }
}
