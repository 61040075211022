import React, { useLayoutEffect, lazy, Suspense } from 'react';
import './config/reactotron';

import './assets/css/styles.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';

import store from './store';
import Footer from './components/Footer';

const Routes = lazy(() => import('./routes'));

const App = () => {
  useLayoutEffect(() => {
    if (window.location.pathname?.includes('webchat')) return null;

    if (process.env.REACT_APP_ENVIRONMENT === 'PROD') {
      return window.location.replace('https://new.boteria.com.br/');
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'TEST') {
      return window.location.replace('https://new.hmlbots.digitalcontact.cloud/');
    }
    return null;
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={null}>
          {' '}
          <Routes />
        </Suspense>

        <ReduxToastr
          timeOut={8000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <Footer />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
