import { call, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import api from '../../services/api';
import { TemplateBotTypes } from '../actions/template-bot';

/**
 * Create new template bot linked with the current bot viewed
 *
 * @param {*} action
 */
export function* createTemplateBot(action) {
  const { payload } = action;

  try {
    const url = `/v1/template-bot`;
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    const { data } = yield call(api.post, url, formData);

    yield put({
      type: TemplateBotTypes.CREATE_TEMPLATE_BOT_SUCCESS,
      payload: { ...data },
    });
    toastr.success('Sucesso', 'Template do bot criado!');
  } catch (error) {
    toastr.error('Ops', 'Falha ao criar um template apartir do bot!');
  }
}

/**
 * Update the information for the template bot from current bot viewed
 *
 * @param {*} action
 */
export function* updateTemplateBotDetails(action) {
  const { payload } = action;

  try {
    const url = `/v1/template-bot/${payload._id}`;
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    const { data } = yield call(api.put, url, formData);

    yield put({
      type: TemplateBotTypes.UPDATE_TEMPLATE_BOT_DETAILS_SUCCESS,
      payload: { ...data },
    });
    toastr.success('Sucesso', 'Template do bot atualizado!');
  } catch (error) {
    toastr.error('Ops', 'Falha ao atualizar um template apartir do bot!');
  }
}

/**
 * Update the template bot flow with the current bot viewed
 *
 * @param {*} action
 */
export function* updateTemplateBot(action) {
  const { payload } = action;

  try {
    const url = `/v1/template-bot`;
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    const { data } = yield call(api.put, url, formData);

    yield put({
      type: TemplateBotTypes.UPDATE_TEMPLATE_BOT_SUCCESS,
      payload: { ...data },
    });
    toastr.success('Sucesso', 'Template do bot atualizado!');
  } catch (error) {
    toastr.error('Ops', 'Falha ao atualizar um template apartir do bot!');
  }
}

/**
 * Empty template bot data
 *
 * @param {*} action
 */
export function* emptyTemplateBot(action) {
  yield put({
    type: TemplateBotTypes.EMPTY_TEMPLATE_BOT_SUCCESS,
    payload: {},
  });
}

/**
 * set template bot data
 *
 * @param {*} action
 */
export function* setTemplateBotData(action) {
  yield put({
    type: TemplateBotTypes.SET_TEMPLATE_BOT_DATA_SUCCESS,
    payload: action.payload,
  });
}

/**
 * Get template bot from current bot viewed
 *
 * @param {*} action
 */
export function* getTemplateBot(action) {
  const { payload } = action;

  try {
    const url = `/v1/template-bot/${payload.botId}`;
    const { data } = yield call(api.get, url);

    yield put({
      type: TemplateBotTypes.GET_TEMPLATE_BOT_SUCCESS,
      payload: { ...data },
    });
  } catch (error) {
    toastr.error('Ops', 'Falha ao buscar um template apartir do bot!');
  }
}

/**
 * Get all templates bot available and published for use
 *
 */
export function* getAllTemplatesBot() {
  try {
    const url = `/v1/template-bot/list/all`;
    const { data } = yield call(api.get, url);

    yield put({
      type: TemplateBotTypes.GET_ALL_TEMPLATE_BOTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar os templates de bot!');
  }
}

/**
 * Get template bot with the id passed
 *
 * @param {*} action
 */
export function* getTemplateBotById(action) {
  const { payload } = action;

  try {
    const url = `/v1/template-bot/template/${payload.id}`;
    const { data } = yield call(api.get, url);

    yield put({
      type: TemplateBotTypes.GET_TEMPLATE_BOT_BY_ID_SUCCESS,
      payload: { ...data },
    });
  } catch (error) {
    toastr.error('Ops', 'Falha ao buscar um template pelo id!');
  }
}

/**
 * Delete template bot with the id passed
 *
 * @param {*} action
 */
export function* deleteTemplateBot(action) {
  const { payload } = action;
  const { deletedTemplatesIds } = payload;
  const url = `/v1/template-bot/`;
  const body = { deletedTemplatesIds };
  try {
    if (deletedTemplatesIds.length > 0) {
      const { data } = yield call(api.delete, url, { data: { ...body } });

      yield put({
        type: TemplateBotTypes.DELETE_TEMPLATE_BOT_SUCCESS,
        payload: { ...data },
      });
      toastr.success('Sucesso', 'Templates deletados!');
    }
  } catch (error) {
    toastr.error('Ops', 'Falha ao deletar o template!');
  }
}
