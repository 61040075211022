import { put } from 'redux-saga/effects';

import { facebookMessengerTypes } from '../actions/facebook-messenger';

/**
 * Set the new facebook auth state
 * @param {Object} action
 */
export function* setFacebookAuthState(action) {
  yield put({
    type: facebookMessengerTypes.SET_FB_AUTH_STATE_SUCCESS,
    payload: { ...action.payload },
  });
}

/**
 * Set the new facebook selected page state
 * @param {Object} action
 */
export function* setFacebookSelectedPage(action) {
  yield put({
    type: facebookMessengerTypes.SET_FB_SELECTED_PAGE_SUCCESS,
    payload: { ...action.payload },
  });
}
