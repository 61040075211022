import { BotCreatorTypes } from '../actions/bot-creator';

const INITIAL_STATE = {
  connectorsOnError: [],
  itemsOnError: [],
  resetFlowPath: false,
};

export default function botCreator(state = INITIAL_STATE, action) {
  switch (action.type) {
    case BotCreatorTypes.CHANGE_CONNECTORS_ON_ERROR: {
      return { ...state, connectorsOnError: action.payload };
    }
    case BotCreatorTypes.SET_ITEMS_ON_ERROR: {
      return { ...state, itemsOnError: action.payload };
    }
    case BotCreatorTypes.RESET_FLOW_PATH: {
      return { ...state, resetFlowPath: action.payload };
    }

    case BotCreatorTypes.REMOVE_ITEMS_ON_ERROR: {
      const { id } = action.payload;

      const updateItemsOnError = state.itemsOnError.filter(
        (item) => item._id !== id
      );

      return { ...state, itemsOnError: updateItemsOnError };
    }

    case BotCreatorTypes.RESET_BOT_CREATOR: {
      return { ...state, itemsOnError: [], connectorsOnError: [] };
    }

    default: {
      return state;
    }
  }
}
