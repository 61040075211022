import { combineReducers } from 'redux';
import actions from '../actions';

const initialState = {
  loading: false,
};

export const commonStatus = (state = initialState, action) => {
  switch (action.type) {
    case actions.commonActions.LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case actions.commonActions.LOADING_START:
      return { ...state, loading: true };
    case actions.commonActions.LOADING_FINISH:
      return { ...state, loading: false };
    // case actions.ItemsTypes.LIST_ITEMS_REQUEST:
    case actions.CompanyTypes.GET_COMPANIES_REQUEST:
    case actions.CompanyTypes.CREATE_COMPANY_REQUEST:
    case actions.CompanyTypes.UPDATE_COMPANY_REQUEST:
    case actions.CompanyTypes.UPDATE_SELECTED_COMPANY_REQUEST:
    case actions.CompanyTypes.GET_ORGANIZATIONS:
    case actions.BotsTypes.LIST_BOTS_REQUEST:
    case actions.UserTypes.CREATE_USER:
    case actions.UserTypes.UPDATE_USER:
    case actions.UserTypes.SELECT_ORGANIZATION:
      return { ...state, loading: true };
    case actions.UserTypes.SELECT_COMPANY_ORGANIZATION:
      return { ...state, loading: true };
    case actions.BotsTypes.UPDATE_BOT_REQUEST:
      return { ...state, loading: false };
    case actions.CompanyTypes.GET_COMPANIES_REQUEST_SUCCESS:
    case actions.CompanyTypes.CREATE_COMPANY_REQUEST_SUCCESS:
    case actions.CompanyTypes.UPDATE_COMPANY_REQUEST_SUCCESS:
    case actions.CompanyTypes.UPDATE_SELECTED_COMPANY_SUCCESS:
    case actions.CompanyTypes.GET_ORGANIZATIONS_SUCCESS:
    case actions.BotsTypes.RETRIEVE_BOT_SUCCESS:
    case actions.BotsTypes.LIST_BOTS_SUCCESS:
    case actions.ItemsTypes.LIST_ITEMS_SUCCESS:
    case actions.BotsTypes.UPDATE_BOT_SUCCESS:
    case actions.UserTypes.CREATE_USER_SUCCESS:
    case actions.UserTypes.UPDATE_USER_SUCCESS:
    case actions.UserTypes.SELECT_ORGANIZATION_SUCCESS:
      return { ...state, loading: false };
    case actions.UserTypes.SELECT_COMPANY_ORGANIZATION_SUCCESS:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default combineReducers({
  commonStatus,
});
