import { call, put, select } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { TagTypes } from '../actions/tags';
import api from '../../services/api';

export function* list({ payload }) {
  try {
    const { botId } = payload;

    const response = yield call(api.get, `/v1/bots/tag/${botId}`);

    yield put({
      type: TagTypes.LIST_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    toastr.error('Erro', 'Ocorreu um erro ao listar as tags.');
  }
}

export function* create({ payload }) {
  try {
    const { setModalOpened, values } = payload;

    const response = yield call(api.post, '/v1/bots/tag', values);

    const { tags } = yield select((state) => state.tags.tagsState);

    const newTags = {
      bot: [...tags.bot, ...response.data],
      global: tags.global,
    };

    yield put({
      type: TagTypes.CREATE_SUCCESS,
      payload: newTags,
    });

    if (setModalOpened) {
      setModalOpened('');
    }
  } catch (err) {
    toastr.error(
      'Erro',
      (err.response && err.response.data.message) ||
        'Ocorreu um erro ao criar a tag.'
    );
  }
}

export function* edit({ payload }) {
  try {
    const { setModalOpened, id, ...data } = payload;

    const response = yield call(api.put, `/v1/bots/tag/${id}`, data);

    const { tags } = yield select((state) => state.tags.tagsState);

    const findTagIndex = tags.bot.findIndex((tag) => tag._id === id);

    const updatedBotTags = tags.bot;
    updatedBotTags[findTagIndex] = response.data;

    const updatedTags = {
      bot: updatedBotTags,
      global: tags.global,
    };

    yield put({
      type: TagTypes.EDIT_SUCCESS,
      payload: updatedTags,
    });

    setModalOpened('');
  } catch (err) {
    toastr.error(
      'Erro',
      (err.response && err.response.data.message) ||
        'Ocorreu um erro ao atualizar a tag.'
    );
  }
}

export function* remove({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `/v1/bots/tag/${id}`);

    const { tags } = yield select((state) => state.tags.tagsState);

    const botTags = tags.bot.filter((tag) => tag._id !== id);

    const updatedTags = {
      bot: botTags,
      global: tags.global,
    };

    yield put({
      type: TagTypes.REMOVE_SUCCESS,
      payload: updatedTags,
    });
  } catch (err) {
    toastr.error('Erro', 'Ocorreu um erro ao deletar a tag.');
  }
}
