export const TagTypes = {
  LIST: 'tags/LIST',
  LIST_SUCCESS: 'tags/LIST_SUCCESS',
  CREATE: 'tags/CREATE',
  CREATE_SUCCESS: 'tags/CREATE_SUCCESS',
  EDIT: 'tags/EDIT',
  EDIT_SUCCESS: 'tags/EDIT_SUCCESS',
  REMOVE: 'tags/REMOVE',
  REMOVE_SUCCESS: 'tags/REMOVE_SUCCESS',
};

export const TagActions = {
  list: ({ botId }) => {
    return {
      type: TagTypes.LIST,
      payload: { botId },
    };
  },

  create: (data) => {
    return {
      type: TagTypes.CREATE,
      payload: data,
    };
  },

  edit: (data) => {
    return {
      type: TagTypes.EDIT,
      payload: data,
    };
  },

  remove: ({ id }) => {
    return {
      type: TagTypes.REMOVE,
      payload: { id },
    };
  },
};
