import moment from 'moment';
import queryString from 'query-string';
import { call, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { commonActions } from '../actions/common';
import apiReports from '../../services/api.reports';
import api from '../../services/api';
import { ReportsTypes } from '../actions/reports';

/**
 * Display the error received from the catch on request.
 * @param {*} error
 */
const onRequestError = (error) => {
  toastr.error('Erro', 'Ocorreu um erro inesperado');
};

const onPeriodInvalid = (startDate, endDate) => {
  const isInvalid = moment(startDate).isAfter(endDate);
  if (isInvalid) {
    toastr.error(
      'Error',
      'A data inicial deve ser anterior a data final do período.'
    );
  }
  return isInvalid;
};

const addEndpointParameters = (
  startDate,
  endDate,
  botId,
  token,
  tags = [],
  channels = [],
  variables = [],
  timezone
) => {
  let endpoint = `?bot=${botId}&token=${token}`;
  endpoint += `&start_date=${startDate}`;
  endpoint += `&end_date=${endDate}`;
  endpoint += `&timezone=${timezone}`;
  if (tags.length !== 0) {
    endpoint += `&tags=${encodeURIComponent(JSON.stringify(tags))}`;
  }

  if (channels.length !== 0) {
    endpoint += `&channels=${encodeURIComponent(JSON.stringify(channels))}`;
  }

  if (variables.length !== 0) {
    endpoint += `&variables=${encodeURIComponent(JSON.stringify(variables))}`;
  }

  return endpoint;
};

/**
 * Get bot total of users used and the average
 * @param {*} action
 */
export function* getClientsReports(action) {
  const { botId, token, filters } = action.payload;
  const { startDate, endDate, tags, channels, variables, timezone } = filters;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const clientsTotalEndpoint = `/bots/clients/count${addEndpointParameters(
      startDate,
      endDate,
      botId,
      token,
      tags,
      channels,
      variables,
      timezone
    )}`;

    const [clientsTotal, clientsAverage] = yield [
      yield call(apiReports.get, clientsTotalEndpoint),
      // yield call(apiReports.get, clientsAverageEndpoint),
    ];
    yield put({
      type: ReportsTypes.GET_CLIENTS_REPORTS_SUCCESS,
      payload: { total: clientsTotal.data, average: [] },
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get bot total of users filter by period for the graphic
 * @param {*} action
 */
export function* getClientsGraphicReports(action) {
  const { botId, token, filters } = action.payload;
  const { startDate, endDate, tags, channels, variables, timezone } = filters;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const clientsGraphicEndpoint = `/bots/clients${addEndpointParameters(
      startDate,
      endDate,
      botId,
      token,
      tags,
      channels,
      variables,
      timezone
    )}`;

    const { data } = yield call(apiReports.get, clientsGraphicEndpoint);
    yield put({
      type: ReportsTypes.GET_CLIENTS_REPORTS_GRAPHIC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get bot total of sessions used and the average
 * @param {*} action
 */
export function* getBotSessions(action) {
  const { botId, token, filters } = action.payload;
  const { startDate, endDate, tags, channels, variables, timezone } = filters;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const sessionsTotalEndpoint = `/bots/sessions/count${addEndpointParameters(
      startDate,
      endDate,
      botId,
      token,
      tags,
      channels,
      variables,
      timezone
    )}`;

    const [sessionsTotal] = yield [
      yield call(apiReports.get, sessionsTotalEndpoint),
    ];
    yield put({
      type: ReportsTypes.GET_SESSIONS_REPORTS_SUCCESS,
      payload: { total: sessionsTotal.data, average: [] },
    });
  } catch (error) {
    onRequestError(error);
  }
}

export function* getSessionsGraphicReports(action) {
  const { botId, token, filters } = action.payload;
  const { startDate, endDate, tags, channels, variables, timezone } = filters;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const sessionsGraphicEndpoint = `/bots/sessions${addEndpointParameters(
      startDate,
      endDate,
      botId,
      token,
      tags,
      channels,
      variables,
      timezone
    )}`;

    const { data } = yield call(apiReports.get, sessionsGraphicEndpoint);
    yield put({
      type: ReportsTypes.GET_SESSIONS_REPORTS_GRAPHIC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get bot attendance time and waiting time
 * @param {*} action
 */
export function* getAttendanceTime(action) {
  const { botId, token, filters } = action.payload;
  const { startDate, endDate, tags, channels, variables, timezone } = filters;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const attendanceTimeAverageEndpoint = `/bots/sessions/duration/average${addEndpointParameters(
      startDate,
      endDate,
      botId,
      token,
      tags,
      channels,
      variables,
      timezone
    )}`;

    const { data } = yield call(apiReports.get, attendanceTimeAverageEndpoint);

    yield put({
      type: ReportsTypes.GET_ATTENDANCE_TIME_REPORTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
    toastr.error('Ops!', 'Erro ao aprimorar');
  }
}

export function* getSessionAttendanceTime(action) {
  const { botId, token, filters } = action.payload;
  const { startDate, endDate, tags, channels, variables, timezone } = filters;

  try {
    const sessionsGraphicEndpoint = `bots/sessions/stats${addEndpointParameters(
      startDate,
      endDate,
      botId,
      token,
      tags,
      channels,
      variables,
      timezone
    )}`;

    const { data } = yield call(apiReports.get, sessionsGraphicEndpoint);
    yield put({
      type: ReportsTypes.GET_SESSION_ATTENDANCE_TIME_REPORTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get bot attendance time data for graphic
 * @param {*} action
 */
export function* getBotAttendanceTimeGraphic(action) {
  const { botId, token, filters } = action.payload;
  const { startDate, endDate, tags, channels, variables, timezone } = filters;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const attendanceTimeGraphicTotalEndpoint = `/bots/sessions/duration${addEndpointParameters(
      startDate,
      endDate,
      botId,
      token,
      tags,
      channels,
      variables,
      timezone
    )}`;

    const { data } = yield call(
      apiReports.get,
      attendanceTimeGraphicTotalEndpoint
    );

    yield put({
      type: ReportsTypes.GET_ATTENDANCE_TIME_REPORTS_GRAPHIC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get bot (and user) quantity of messages in a period
 * @param {*} action
 */
export function* getBotQuantityMessagesData(action) {
  const { botId, token, filters } = action.payload;
  const { startDate, endDate, tags, channels, variables, timezone } = filters;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const parameters = addEndpointParameters(
      startDate,
      endDate,
      botId,
      token,
      tags,
      channels,
      variables,
      timezone
    );
    const botMessageEndpoint = `/bots/messages${parameters}`;
    const userMessageEndpoint = `/bots/messages/user${parameters}`;

    const [bot, user] = yield [
      yield call(apiReports.get, botMessageEndpoint),
      yield call(apiReports.get, userMessageEndpoint),
    ];

    yield put({
      type: ReportsTypes.GET_BOT_MESSAGES_SUCCESS,
      payload: { bot: bot.data, user: user.data },
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get bot (and user) quantity of messages in a period
 * @param {*} action
 */
export function* getBotQuantityAndAverageMessageData(action) {
  const { botId, token, filters } = action.payload;
  const { startDate, endDate, tags, channels, variables, timezone } = filters;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const parameters = addEndpointParameters(
      startDate,
      endDate,
      botId,
      token,
      tags,
      channels,
      variables,
      timezone
    );
    const botMessageEndpoint = `/bots/messages${parameters}`;
    const totalMessageEndpoint = `/bots/messages/count${parameters}`;
    const userMessageEndpoint = `/bots/messages/user${parameters}`;
    const chatAverageMessageEndpoint = `/bots/messages/average-by-session${parameters}`;

    const [bot, total, user, average] = yield [
      yield call(apiReports.get, botMessageEndpoint),
      yield call(apiReports.get, totalMessageEndpoint),
      yield call(apiReports.get, userMessageEndpoint),
      yield call(apiReports.get, chatAverageMessageEndpoint),
    ];

    yield put({
      type: ReportsTypes.GET_BOT_MESSAGES_AVG_SUCCESS,
      payload: {
        bot: bot.data,
        total: total.data,
        user: user.data,
        average: average.data,
      },
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get bot total messages on each channel used (graphic)
 * @param {*} action
 */
export function* getBotMessagesByChannel(action) {
  const { botId, token, filters } = action.payload;
  const { startDate, endDate, tags, channels, variables, timezone } = filters;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const quantityMessageByChannelEndpoint = `/bots/messages/by-channel${addEndpointParameters(
      startDate,
      endDate,
      botId,
      token,
      tags,
      channels,
      variables,
      timezone
    )}`;
    const { data } = yield call(
      apiReports.get,
      quantityMessageByChannelEndpoint
    );
    yield put({
      type: ReportsTypes.GET_AMOUNT_MESSAGE_CHANNEL_REPORTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get bot total messages on each channel used (absolute)
 * @param {*} action
 */
export function* getBotTotalMessagesByChannel(action) {
  const { botId, token, filters } = action.payload;
  const { startDate, endDate, tags, channels, variables, timezone } = filters;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const botTotalMessageByChannelEndpoint = `/bots/messages/total-by-channel${addEndpointParameters(
      startDate,
      endDate,
      botId,
      token,
      tags,
      channels,
      variables,
      timezone
    )}`;
    const { data } = yield call(
      apiReports.get,
      botTotalMessageByChannelEndpoint
    );
    yield put({
      type: ReportsTypes.GET_TOTAL_MESSAGE_CHANNEL_REPORTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get total of bots in this company (org)
 * @param {*} action
 */
export function* getCompanyBotsCount(action) {
  const { orgId, token } = action.payload;

  try {
    const orgBotsCountEndpoint = `/company/bots/count?company=${orgId}&token=${token}`;
    const { data } = yield call(apiReports.get, orgBotsCountEndpoint);
    yield put({
      type: ReportsTypes.GET_ORG_BOTS_COUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get bots in this company (org) for the period
 * @param {*} action
 */
export function* getCompanyBotsGraphic(action) {
  const { orgId, token, date, timezone } = action.payload;
  const { startDate, endDate } = date;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const orgBotsGraphicEndpoint = `/company/bots?company=${orgId}&token=${token}&start_date=${startDate}&end_date=${endDate}&timezone=${timezone}`;
    const { data } = yield call(apiReports.get, orgBotsGraphicEndpoint);
    yield put({
      type: ReportsTypes.GET_ORG_BOTS_GRAPHIC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get total of messages in this company (org)
 * @param {*} action
 */
export function* getCompanyTotalMessagesCount(action) {
  const { orgId, token, date, timezone } = action.payload;
  const { startDate, endDate } = date;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const orgTotalMessagesCountEndpoint = `/company/messages/count?company=${orgId}&token=${token}&start_date=${startDate}&end_date=${endDate}&timezone=${timezone}`;
    const { data } = yield call(apiReports.get, orgTotalMessagesCountEndpoint);
    yield put({
      type: ReportsTypes.GET_ORG_TOTAL_MESSAGES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get total messages in this company (org) for the period
 * @param {*} action
 */
export function* getCompanyTotalMessagesGraphic(action) {
  const { orgId, token, date, timezone } = action.payload;
  const { startDate, endDate } = date;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const orgTotalMessagesGraphicEndpoint = `/company/messages?company=${orgId}&token=${token}&start_date=${startDate}&end_date=${endDate}&timezone=${timezone}`;
    const orgTotalMessagesUserGraphicEndpoint = `/company/messages/user?company=${orgId}&token=${token}&start_date=${startDate}&end_date=${endDate}&timezone=${timezone}`;

    const [total, user] = yield [
      yield call(apiReports.get, orgTotalMessagesGraphicEndpoint),
      yield call(apiReports.get, orgTotalMessagesUserGraphicEndpoint),
    ];
    yield put({
      type: ReportsTypes.GET_ORG_TOTAL_MESSAGES_GRAPHIC_SUCCESS,
      payload: { total: total.data, user: user.data },
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get total of sessions in this company (org)
 * @param {*} action
 */
export function* getCompanyTotalSessionsCount(action) {
  const { orgId, token, date, timezone } = action.payload;
  const { startDate, endDate } = date;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const orgTotalSessionsCountEndpoint = `/company/sessions/count?company=${orgId}&token=${token}&start_date=${startDate}&end_date=${endDate}&timezone=${timezone}`;
    const { data } = yield call(apiReports.get, orgTotalSessionsCountEndpoint);
    yield put({
      type: ReportsTypes.GET_ORG_TOTAL_SESSIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get total sessions in this company (org) for the period
 * @param {*} action
 */
export function* getCompanyTotalSessionsGraphic(action) {
  const { orgId, token, date, timezone } = action.payload;
  const { startDate, endDate } = date;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const orgTotalSessionsGraphicEndpoint = `/company/sessions?company=${orgId}&token=${token}&start_date=${startDate}&end_date=${endDate}&timezone=${timezone}`;

    const { data } = yield call(
      apiReports.get,
      orgTotalSessionsGraphicEndpoint
    );

    yield put({
      type: ReportsTypes.GET_ORG_TOTAL_SESSIONS_GRAPHIC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get total of users in this company (org)
 * @param {*} action
 */
export function* getCompanyTotalUsersCount(action) {
  const { orgId, token } = action.payload;

  try {
    const orgTotalUsersCountEndpoint = `/company/users/count?company=${orgId}&token=${token}`;
    const { data } = yield call(apiReports.get, orgTotalUsersCountEndpoint);
    yield put({
      type: ReportsTypes.GET_ORG_TOTAL_USERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get total users in this company (org) for the period
 * @param {*} action
 */
export function* getCompanyTotalUsersGraphic(action) {
  const { orgId, token, date, timezone } = action.payload;
  const { startDate, endDate } = date;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const orgTotalUsersGraphicEndpoint = `/company/users?company=${orgId}&token=${token}&start_date=${startDate}&end_date=${endDate}&timezone=${timezone}`;

    const [total] = yield [
      yield call(apiReports.get, orgTotalUsersGraphicEndpoint),
    ];
    yield put({
      type: ReportsTypes.GET_ORG_TOTAL_USERS_GRAPHIC_SUCCESS,
      payload: total.data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get list of users in this company (org)
 * @param {*} action
 */
export function* getCompanyListUsers(action) {
  const { orgId, token } = action.payload;

  try {
    const orgListUsersCountEndpoint = `/company/users/detailed?company=${orgId}&token=${token}`;
    const { data } = yield call(apiReports.get, orgListUsersCountEndpoint);
    yield put({
      type: ReportsTypes.GET_ORG_LIST_USERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get total messages by channel per bot in this company (org)
 * @param {*} action
 */
export function* getCompanyMessagesByChannel(action) {
  const { orgId, token, date, timezone } = action.payload;
  const { startDate, endDate } = date;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const orgTotalMessagesByChannelEndpoint = `/company/messages/by-channel/count?company=${orgId}&token=${token}&start_date=${startDate}&end_date=${endDate}&timezone=${timezone}`;
    const { data } = yield call(
      apiReports.get,
      orgTotalMessagesByChannelEndpoint
    );
    yield put({
      type: ReportsTypes.GET_ORG_MESSAGES_BY_CHANNEL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get total messages by channel per bot in this company (org) for the graphic
 * @param {*} action
 */
export function* getCompanyMessagesByChannelGraphic(action) {
  const { orgId, token, date, timezone } = action.payload;
  const { startDate, endDate } = date;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) {
    return;
  }

  try {
    const orgTotalMessagesByChannelEndpoint = `/company/messages/by-channel?company=${orgId}&token=${token}&start_date=${startDate}&end_date=${endDate}&timezone=${timezone}`;
    const { data } = yield call(
      apiReports.get,
      orgTotalMessagesByChannelEndpoint
    );
    yield put({
      type: ReportsTypes.GET_ORG_MESSAGES_BY_CHANNEL_GRAPHIC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get users bots average in this company (org)
 * @param {*} action
 */
export function* getCompanyUsersBotsAverage(action) {
  const { orgId, token } = action.payload;

  try {
    const orgUsersBotsAverageEndpoint = `/company/users/bots/avg?company=${orgId}&token=${token}`;
    const { data } = yield call(apiReports.get, orgUsersBotsAverageEndpoint);
    yield put({
      type: ReportsTypes.GET_ORG_USERS_BOTS_AVERAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get users bots total in this company (org)
 * @param {*} action
 */
export function* getCompanyUsersBots(action) {
  const { orgId, token } = action.payload;

  try {
    const orgUsersBotsTotalEndpoint = `/company/users/bots?company=${orgId}&token=${token}`;
    const { data } = yield call(apiReports.get, orgUsersBotsTotalEndpoint);
    yield put({
      type: ReportsTypes.GET_ORG_USERS_BOTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Create dashboard report for the current user and report type
 * @param {*} action
 */
export function* createDashboardReport(action) {
  const { dashboardToken } = action.payload;

  try {
    const createDashboardReportEndpoint = `?token=${dashboardToken}`;
    const { data } = yield call(
      apiReports.post,
      createDashboardReportEndpoint,
      { ...action.payload }
    );

    toastr.success('Sucesso', 'Dashboard salva com sucesso.');
    yield put({
      type: ReportsTypes.CREATE_DASHBOARD_REPORT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Update dashboard report for the current user and report type
 * @param {*} action
 */
export function* updateDashboardReport(action) {
  const { dashboardToken, _id } = action.payload;

  try {
    const updateDashboardReportEndpoint = `/${_id}?token=${dashboardToken}`;
    const { data } = yield call(apiReports.put, updateDashboardReportEndpoint, {
      ...action.payload,
    });

    toastr.success('Sucesso', 'Dashboard salva com sucesso.');
    yield put({
      type: ReportsTypes.UPDATE_DASHBOARD_REPORT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get dashboard report for the current user and report type
 * @param {*} action
 */
export function* getDashboardReport(action) {
  const { userId, type, token } = action.payload;

  try {
    const getDashboardReportEndpoint = `?user_id=${userId}&type=${type}&token=${token}`;
    const { data } = yield call(apiReports.get, getDashboardReportEndpoint);
    yield put({
      type: ReportsTypes.GET_DASHBOARD_REPORT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

export function* getCompanyMessagesPerBot(action) {
  const { company, token, start_date, end_date } = action.payload;

  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const companyMessagePerBotParams = `?token=${token}&company=${company}&start_date=${start_date}&end_date=${end_date}`;

    const { data } = yield call(
      apiReports.get,
      `/company/messages/bots/count${companyMessagePerBotParams}`
    );
    yield put({
      type: ReportsTypes.GET_COMPANY_MESSAGES_PER_BOT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

/**
 * Get bot sessions with tags on the period asked
 *
 * @param {object} action object with the data for this action
 */
export function* getBotSessionTags(action) {
  const {
    botId,
    token,
    startDate,
    endDate,
    nameTags,
    channels,
    variables,
    timezone,
  } = action.payload;

  try {
    const url = `/tags${addEndpointParameters(
      startDate,
      endDate,
      botId,
      token,
      nameTags,
      channels,
      variables,
      timezone
    )}`;

    const { data } = yield call(apiReports.get, url);

    yield put({
      type: ReportsTypes.GET_BOT_SESSION_TAGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

/**
 * Get the total of bot reports created and their respective status
 * @param {*} action
 */
export function* getBotCustomReports(action) {
  const { botId, token } = action.payload;

  try {
    const botCustomReportEndpoint = `/v1/report/check?bot=${botId}&token=${token}`;

    const { data } = yield call(api.get, botCustomReportEndpoint);

    yield put({
      type: ReportsTypes.GET_BOT_CUSTOM_REPORTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    onRequestError(error);
  }
}

export function* createBotCustomReport(action) {
  const { token, type, ...sentData } = action.payload;

  try {
    const url = `/v1/report/${type}/generate?token=${token}`;

    const { data } = yield call(api.post, url, sentData);

    if (data?.message === 'Report intent created successfully') {
      toastr.success('Sucesso', 'Relatório customizado criado');
      yield put({
        type: ReportsTypes.GET_BOT_CUSTOM_REPORTS,
        payload: { botId: sentData.bot, token },
      });

      getBotCustomReports({ botId: sentData.bot, token });
    }
  } catch (error) {
    onRequestError(error);
  }
}

export function* getErrorLogs(action) {
  try {
    const params = queryString.stringify(action.payload, {
      skipNull: true,
      arrayFormat: 'comma',
    });

    const { data } = yield call(api.get, `/v1/bots/item-log?${params}`);

    yield put({
      type: ReportsTypes.GET_ERROR_LOGS_SUCCESS,
      payload: data,
    });
    toastr.success('Sucesso', 'Operação realizada com sucesso');
  } catch (error) {
    onRequestError(error);
  }
}
