import { newBotTypes } from '../actions/new-bot';

const INITIAL_STATE = {
  currentTemplateBotTesting: {
    title: '',
    botId: '',
    isOpen: false,
  },
  currentTemplateBotSelected: {},
};

export default function newBot(state = INITIAL_STATE, action) {
  switch (action.type) {
    case newBotTypes.SET_NEW_BOT_CURRENT_TEMPLATE_BOT_TESTING_SUCCESS:
      return { ...state, currentTemplateBotTesting: action.payload };
    case newBotTypes.SET_NEW_BOT_TEMPLATE_BOT_SELECTED_SUCCESS:
      return { ...state, currentTemplateBotSelected: action.payload };
    case newBotTypes.PUSH_TO_CREATE_BOT:
      return { ...state };
    default:
      return state;
  }
}
