import { registerFromTypes } from '../actions/register-from';

const initialState = {
  registers: [],
};

export default function registerFromState(state = initialState, action) {
  switch (action.type) {
    case registerFromTypes.GET_REGISTERS_SUCCESS:
      return { ...state, registers: action.payload };
    default:
      return {
        ...state,
      };
  }
}
