export const newBotTypes = {
  SET_NEW_BOT_CURRENT_TEMPLATE_BOT_TESTING:
    'bot/SET_NEW_BOT_CURRENT_TEMPLATE_BOT_TESTING',
  SET_NEW_BOT_CURRENT_TEMPLATE_BOT_TESTING_SUCCESS:
    'bot/SET_NEW_BOT_CURRENT_TEMPLATE_BOT_TESTING_SUCCESS',
  SET_NEW_BOT_TEMPLATE_BOT_SELECTED: 'bot/SET_NEW_BOT_TEMPLATE_BOT_SELECTED',
  SET_NEW_BOT_TEMPLATE_BOT_SELECTED_SUCCESS:
    'bot/SET_NEW_BOT_TEMPLATE_BOT_SELECTED_SUCCESS',
  PUSH_TO_CREATE_BOT: 'bot/PUSH_TO_CREATE_BOT',
};

export const setCurrentTemplateBotTesting = (newCurrentTemplateBot) => ({
  type: newBotTypes.SET_NEW_BOT_CURRENT_TEMPLATE_BOT_TESTING,
  payload: { ...newCurrentTemplateBot },
});

export const setCurrentTemplateBotSelected = (newTemplateBot) => ({
  type: newBotTypes.SET_NEW_BOT_TEMPLATE_BOT_SELECTED,
  payload: { ...newTemplateBot },
});

export const pushToCreateBot = () => ({
  type: newBotTypes.PUSH_TO_CREATE_BOT,
  payload: {},
});
