import { IaTypes } from '../actions/intelligence';

/* Reducer */

const INITIAL_STATE = {
  ia: {},
  intents: [],
  entity: [],
  entities: [],
  samples: [],
  samplesAll: [],
  curation: {},
  conditions: [],
  editCondition: {},
  aprimoramentos: [],
  platform: '',
  iaInfo: {},
  filterCuration: {},
};

export default function intelligence(state = INITIAL_STATE, action) {
  switch (action.type) {
    case IaTypes.IA_PLATFORM_SUCCESS:
      return {
        ...state,
        platform: action.payload,
      };
    case IaTypes.IA_CREATE_WIT_SUCCESS:
      return {
        ...state,
        ia: { ...state.ia, wit: action.payload },
        plataform: 'wit',
      };
    case IaTypes.IA_CREATE_LUIS_SUCCESS:
      return {
        ...state,
        ia: { ...state.ia, luis: action.payload },
        plataform: 'luis',
      };
    case IaTypes.IA_CREATE_BOTERIA_SUCCESS:
      return {
        ...state,
        ia: { ...state.ia, boteria: action.payload },
        plataform: 'boteria',
      };
    case IaTypes.CURATION_BOT_LIST_MESSAGES_SUCCESS:
      return {
        ...state,
        curation: { ...state.curation, messages: action.payload },
      };
    case IaTypes.CURATION_BOT_IGNORE_MESSAGE_SUCCESS:
      return {
        ...state,
        curation: {
          ...state.curation,
          messages: state.curation.messages.filter(
            (message) => message._id !== action.payload._id
          ),
        },
      };
    case IaTypes.CURATION_BOT_ACCEPT_MESSAGE_SUCCESS:
      return {
        ...state,
        curation: {
          ...state.curation,
          messages: state.curation.messages.filter(
            (message) => message._id !== action.payload._id
          ),
        },
      };
    case IaTypes.IA_LIST_ENTITIES_SUCCESS:
      return {
        ...state,
        entities: action.payload,
      };
    case IaTypes.IA_LIST_ENTITY_SUCCESS:
      return {
        ...state,
        entity: action.payload,
      };

    case IaTypes.IA_LIST_ALL_SAMPLES_SUCCESS:
      return {
        ...state,
        samples: action.payload,
      };

    case IaTypes.IA_LIST_SAMPLES_INTENT_SUCCESS:
      return {
        ...state,
        samplesAll: action.payload.intent,
      };

    case IaTypes.IA_LIST_INTENTS_SUCCESS:
      return {
        ...state,
        intents: action.payload,
      };
    case IaTypes.IA_CREATE_INTENTS_SUCCESS:
      return {
        ...state,
        intents: [...state.intents, action.payload],
      };
    case IaTypes.IA_DELETE_INTENTS_SUCCESS:
      return {
        ...state,
        intents: [...state.intents, action.payload],
      };
    case IaTypes.IA_SAVE_SAMPLE_SUCCESS:
      return {
        ...state,
        ia: { ...state.ia, sample_result: action.payload },
      };
    case IaTypes.IA_CREATE_CONDITIONS_SUCCESS:
      return {
        ...state,
        conditions: [...state.conditions, action.payload],
      };
    case IaTypes.IA_DELETE_CONDITIONS_SUCCESS:
      return {
        ...state,
        conditions: state.conditions.filter(
          (condition) => condition._id !== action.payload
        ),
      };
    case IaTypes.IA_UPDATE_CONDITIONS_SUCCESS:
      return {
        ...state,
        conditions: state.conditions.map((condition) =>
          condition._id === action.payload._id
            ? { ...condition, ...action.payload }
            : condition
        ),
      };
    case IaTypes.IA_LIST_CONDITIONS_SUCCESS:
      return {
        ...state,
        conditions: action.payload,
      };
    case IaTypes.IA_SET_EDIT_CONDITION:
      return {
        ...state,
        editCondition: action.payload,
      };
    case IaTypes.CURATION_BOT_SET_APRIMORAMENTOS:
      return {
        ...state,
        aprimoramentos: [...state.aprimoramentos, action.payload],
      };
    case IaTypes.CURATION_BOT_APRIMORAMENTOS_DELETE:
      return {
        ...state,
        aprimoramentos: state.aprimoramentos.filter(
          (aprimoramento) => aprimoramento !== action.payload
        ),
      };
    case IaTypes.CURATION_BOT_APRIMORAMENTOS_CLEAR:
      return {
        ...state,
        aprimoramentos: [],
      };
    case IaTypes.IA_PLATFORM_INFO_SUCCESS:
      return {
        ...state,
        iaInfo: action.payload,
      };
    case IaTypes.IA_PLATFORM_INFO_ERROR:
      return {
        ...state,
        iaInfo: {},
      };
    case IaTypes.IA_UPDATE_FILTER_CURATION:
      return {
        ...state,
        filterCuration: action.payload,
      };
    case IaTypes.IA_PREVIEW_SUCCESS:
      return {
        ...state,
        iaPreviewResult: action.payload,
      };
    default:
      return state;
  }
}
