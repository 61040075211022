export const VoiceBotsTypes = {
  FETCH_PROJECTS: 'voice-bots/FETCH_PROJECTS',
  FETCH_PROJECTS_SUCCESS: 'voice-bots/FETCH_PROJECTS_SUCCESS',
  FETCH_GRAMMARS: 'voice-bots/FETCH_GRAMMARS',
  FETCH_GRAMMARS_SUCCESS: 'voice-bots/FETCH_GRAMMARS_SUCCESS',
  FETCH_STATUS: 'voice-bots/FETCH_STATUS',
  FETCH_STATUS_SUCCESS: 'voice-bots/FETCH_STATUS_SUCCESS',
  FETCH_VOICES: 'voice-bots/FETCH_VOICES',
  FETCH_VOICES_SUCCESS: 'voice-bots/FETCH_VOICES_SUCCESS',
  FETCH_VOICES_FAILIURE: 'voice-bots/FETCH_VOICES_FAILIURE',
  FETCH_VOICEBOT_INSTANCES: 'voice-bots/FETCH_VOICEBOT_INSTANCES',
  FETCH_VOICEBOT_INSTANCES_SUCCESS:
    'voice-bots/FETCH_VOICEBOT_INSTANCES_SUCCESS',
  FETCH_VOICEBOT_INSTANCES_FAILIURE:
    'voice-bots/FETCH_VOICEBOT_INSTANCES_FAILIURE',
  CREATE_VOICEBOT_INSTANCE: 'voice-bots/CREATE_VOICEBOT_INSTANCE',
  CREATE_VOICEBOT_INSTANCE_SUCCESS:
    'voice-bots/CREATE_VOICEBOT_INSTANCE_SUCCESS',
  UPDATE_VOICEBOT_INSTANCE: 'voice-bots/UPDATE_VOICEBOT_INSTANCE',
  UPDATE_VOICEBOT_INSTANCE_SUCCESS:
    'voice-bots/UPDATE_VOICEBOT_INSTANCE_SUCCESS',
  FETCH_VARIABLES_REQUEST: 'voice-bots/FETCH_VARIABLES_REQUEST',
  FETCH_VARIABLES_SUCCESS: 'voice-bots/FETCH_VARIABLES_SUCCESS',
  FETCH_WEB_API_REQUEST: 'voice-bots/FETCH_WEB_API_REQUEST',
  FETCH_WEB_API_SUCCESS: 'voice-bots/FETCH_WEB_API_SUCCESS',
};

export const fetchProjects = ({ params }) => ({
  type: VoiceBotsTypes.FETCH_PROJECTS,
  payload: { params },
});
export const fetchProjectsSuccess = ({ projects }) => ({
  type: VoiceBotsTypes.FETCH_PROJECTS_SUCCESS,
  payload: { projects },
});

export const fetchGrammars = ({ params }) => ({
  type: VoiceBotsTypes.FETCH_GRAMMARS,
  payload: { params },
});
export const fetchGrammarsSuccess = ({ grammars }) => ({
  type: VoiceBotsTypes.FETCH_GRAMMARS_SUCCESS,
  payload: { grammars },
});

export const fetchStatus = ({ params }) => ({
  type: VoiceBotsTypes.FETCH_STATUS,
  payload: { params },
});
export const fetchStatusSuccess = ({ status }) => ({
  type: VoiceBotsTypes.FETCH_STATUS_SUCCESS,
  payload: { status },
});

export const fetchVoices = ({ params }) => ({
  type: VoiceBotsTypes.FETCH_VOICES,
  payload: { params },
});
export const fetchVoicesSuccess = ({ voices }) => ({
  type: VoiceBotsTypes.FETCH_VOICES_SUCCESS,
  payload: { voices },
});
export const fetchVoicesFailiure = () => ({
  type: VoiceBotsTypes.FETCH_VOICES_FAILIURE,
});

export const fetchVoicebotInstances = ({ organizationId }) => ({
  type: VoiceBotsTypes.FETCH_VOICEBOT_INSTANCES,
  payload: { organizationId },
});
export const fetchVoicebotInstancesSuccess = ({ instances }) => ({
  type: VoiceBotsTypes.FETCH_VOICEBOT_INSTANCES_SUCCESS,
  payload: { instances },
});
export const fetchVoicebotInstancesFailiure = () => ({
  type: VoiceBotsTypes.FETCH_VOICEBOT_INSTANCES_FAILIURE,
});

export const createVoicebotInstance = (instance, callback) => ({
  type: VoiceBotsTypes.CREATE_VOICEBOT_INSTANCE,
  payload: { instance, callback },
});
export const createVoicebotInstanceSuccess = (instance) => ({
  type: VoiceBotsTypes.CREATE_VOICEBOT_INSTANCE_SUCCESS,
  payload: { instance },
});

export const updateVoicebotInstance = (instance, callback) => ({
  type: VoiceBotsTypes.UPDATE_VOICEBOT_INSTANCE,
  payload: { instance, callback },
});
export const updateVoicebotInstanceSuccess = (instance) => ({
  type: VoiceBotsTypes.UPDATE_VOICEBOT_INSTANCE_SUCCESS,
  payload: { instance },
});

export const fetchVoicebotVariables = ({ params }) => ({
  type: VoiceBotsTypes.FETCH_VARIABLES_REQUEST,
  payload: { params },
});
export const fetchVoicebotVariablesSuccess = ({ variables }) => ({
  type: VoiceBotsTypes.FETCH_VARIABLES_SUCCESS,
  payload: { variables },
});

export const fetchVoicebotWebApi = ({ params }) => ({
  type: VoiceBotsTypes.FETCH_WEB_API_REQUEST,
  payload: { params },
});
export const fetchVoicebotWebApiSuccess = ({ webApiList }) => ({
  type: VoiceBotsTypes.FETCH_WEB_API_SUCCESS,
  payload: { webApiList },
});
