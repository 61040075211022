import { combineReducers } from 'redux';
import { TagTypes } from '../actions/tags';

const initialState = {
  tags: { bot: [], global: [] },
};

export const tagsState = (state = initialState, action) => {
  switch (action.type) {
    case TagTypes.CREATE_SUCCESS:
      return {
        ...state,
        tags: action.payload,
      };

    case TagTypes.LIST_SUCCESS:
      return {
        ...state,
        tags: action.payload,
      };

    case TagTypes.EDIT_SUCCESS:
      return {
        ...state,
        tags: action.payload,
      };

    case TagTypes.REMOVE_SUCCESS:
      return {
        ...state,
        tags: action.payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  tagsState,
});
