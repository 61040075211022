export const UserTypes = {
  GET_USERS: 'user/GET_USERS',
  GET_USERS_SUCCESS: 'user/GET_USERS_SUCCESS',
  CREATE_USER: 'user/CREATE_USER',
  CREATE_USER_SUCCESS: 'user/CREATE_USER_SUCCESS',
  UPDATE_SIGNED_USER_STATE: 'user/UPDATE_SIGNED_USER_STATE',
  UPDATE_USER: 'user/UPDATE_USER',
  UPDATE_USER_SUCCESS: 'user/UPDATE_USER_SUCCESS',
  GET_ME: 'user/GET_ME',
  GET_ME_SUCCESS: 'user/GET_ME_SUCCESS',
  SELECT_ORGANIZATION: 'user/SELECT_ORGANIZATION',
  SELECT_ORGANIZATION_SUCCESS: 'user/SELECT_ORGANIZATION_SUCCESS',
  SELECT_COMPANY_ORGANIZATION: 'user/SELECT_COMPANY_ORGANIZATION',
  SELECT_COMPANY_ORGANIZATION_SUCCESS:
    'user/SELECT_COMPANY_ORGANIZATION_SUCCESS',
};

export const UserActions = {
  getUsers: (searchTerm = undefined) => ({
    type: UserTypes.GET_USERS,
    payload: {
      searchTerm,
    },
  }),

  createUser: (data) => ({
    type: UserTypes.CREATE_USER,
    payload: data,
  }),

  updateUser: (data) => ({
    type: UserTypes.UPDATE_USER,
    payload: data,
  }),

  updateSignedUserState: (user) => ({
    type: UserTypes.UPDATE_SIGNED_USER_STATE,
    payload: { user },
  }),

  me: () => ({
    type: UserTypes.GET_ME,
  }),

  selectOrganization: (organizationId) => ({
    type: UserTypes.SELECT_ORGANIZATION,
    payload: {
      selectedOrganization: organizationId,
    },
  }),

  selectCompanyOrganization: (companyId, organizationId, userIsRoot) => ({
    type: UserTypes.SELECT_COMPANY_ORGANIZATION,
    payload: {
      selectedCompany: companyId,
      selectedOrganization: organizationId,
      userIsRoot,
    },
  }),
};
