export const PreviewBotTypes = Object.freeze({
  previewSendUserMessage: 'bot/PREVIEW_SEND_NEW_MESSAGE',
  previewSendUserMessageFile: 'bot/PREVIEW_SEND_NEW_MESSAGE_FILE',
  previewSendUserMessageFileProgress: 'bot/PREVIEW_SEND_NEW_MESSAGE_FILE_PROGRESS',
  previewSendBotMessage: 'bot/PREVIEW_SEND_BOT_MESSAGE',
  previewSendBotTypping: 'bot/PREVIEW_SEND_BOT_TYPPING',
  previewSendBotPrepareNextMessage: 'bot/previewSendBotPrepareNextMessage',
  previewSendBotTyppingStop: 'bot/PREVIEW_SEND_BOT_TYPPING_STOP',
  previewResetMessages: 'bot/PREVIEW_RESET_MESSAGES',
  previewSetStateMachine: 'bot/PREVIEW_SET_STATE_MACHINE',
  previewOpen: 'bot/PREVIEW_OPEN',
  previewDispose: 'bot/PREVIEW_DISPOSE',
  previewStart: 'bot/PREVIEW_START',
  previewNextStatePreview: 'bot/PREVIEW_NEXT_STATE',
});

export const previewBot = {
  sendUserMessage: (from, message) => ({
    type: '',
    // type: PreviewBotTypes.previewSendUserMessage,
    payload: { from, message }
  }),

  sendUserFileMessage: (from, file, message) => ({
    type: '',
    // type: PreviewBotTypes.previewSendUserMessageFile,
    payload: { from, file, message }
  }),

  sendBotMessage: () => ({
    type: '',
    // type: PreviewBotTypes.previewSendBotMessage,
    payload: null
  }),
  openPreviewBot: () => ({
    // type: '',
    type: PreviewBotTypes.previewOpen,
    payload: null,
  }),
  closeBot: () => ({
    // type: '',
    type: PreviewBotTypes.previewDispose,
    payload: null,
  }),
  startTest: (items) => ({
    // type: '',
    type: PreviewBotTypes.previewStart,
    payload: items,
  }),
  setStateMachine: (items) => ({
    // type: '',
    type: PreviewBotTypes.previewStart,
    payload: items,
  }),
  resetMessages: () => ({
    type: '',
    // type: PreviewBotTypes.previewResetMessages,
    payload: null
  }),
  nextStatePreview: () => ({
    type: '',
    // type: PreviewBotTypes.previewNextStatePreview,
    payload: null,
  }),
};
