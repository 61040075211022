import { combineReducers } from 'redux';
import actions from '../actions';

const initialState = {
  reCaptchaValue: '',
  resetRef: false,
  disabled: true,
};

export const reCaptcha = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_RECAPTCHA:
      return {
        ...state,
        reCaptchaValue: action.payload.reCaptchaValue,
      };
    case actions.DISABLE_RECAPTCHA:
      return {
        ...state,
        disabled: action.payload.disabled,
      };
    case actions.RESET_RECAPTCHA:
      return {
        ...state,
        resetRef: action.payload.resetRef,
      };
    default:
      return state;
  }
};

export default combineReducers({
  reCaptcha,
});
