import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

const resourcePt = require('./i18n/pt/resource.json')
const resourceEn = require('./i18n/en/resource.json')

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  pt: {
    translation: resourcePt
  },
  en: {
    translation: resourceEn
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'pt',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
