export const VoiceBotsTtsTypes = {
  GENERATE_TTS_REQUEST: 'voice-bots-tts/GENERATE_TTS_REQUEST',
  GENERATE_TTS_SUCCESS: 'voice-bots-tts/GENERATE_TTS_SUCCESS',
  GENERATE_TTS_FAILED: 'voice-bots-tts/GENERATE_TTS_FAILED',
  SET_TTS_AUDIO_URL: 'voice-bots-tts/SET_TTS_AUDIO_URL',

  FETCH_AUDIOS_REQUEST: 'voice-bots-tts/FETCH_AUDIOS_REQUEST',
  FETCH_AUDIOS_SUCCESS: 'voice-bots-tts/FETCH_AUDIOS_SUCCESS',

  UPLOAD_AUDIOS_REQUEST: 'voice-bots-tts/UPLOAD_AUDIOS_REQUEST',
  UPLOAD_AUDIOS_SUCCESS: 'voice-bots-tts/UPLOAD_AUDIOS_SUCCESS',

  DELETE_AUDIO_REQUEST: 'voice-bots-tts/DELETE_AUDIO_REQUEST',
  DELETE_AUDIO_SUCCESS: 'voice-bots-tts/DELETE_AUDIO_SUCCESS',
};

export const generateTtsRequest = ({ text, idProject, idIvrVoice }) => ({
  type: VoiceBotsTtsTypes.GENERATE_TTS_REQUEST,
  payload: { text, idProject, idIvrVoice },
});
export const generateTtsSuccess = ({ audioUrl }) => ({
  type: VoiceBotsTtsTypes.GENERATE_TTS_SUCCESS,
  payload: { audioUrl },
});
export const generateTtsFailed = () => ({
  type: VoiceBotsTtsTypes.GENERATE_TTS_FAILED,
});

export const setTtsAudioUrl = (url) => ({
  type: VoiceBotsTtsTypes.SET_TTS_AUDIO_URL,
  payload: { url },
});

export const fetchAudiosRequest = ({ botId }) => ({
  type: VoiceBotsTtsTypes.FETCH_AUDIOS_REQUEST,
  payload: { botId },
});
export const fetchAudiosSuccess = ({ audios }) => ({
  type: VoiceBotsTtsTypes.FETCH_AUDIOS_SUCCESS,
  payload: { audios },
});

export const uploadAudiosRequest = (payload) => ({
  type: VoiceBotsTtsTypes.UPLOAD_AUDIOS_REQUEST,
  payload,
});
export const uploadAudiosSuccess = ({ audios }) => ({
  type: VoiceBotsTtsTypes.UPLOAD_AUDIOS_SUCCESS,
  payload: { audios },
});

export const deleteAudioRequest = ({ _id }) => ({
  type: VoiceBotsTtsTypes.DELETE_AUDIO_REQUEST,
  payload: { _id },
});
export const deleteAudioSuccess = ({ _id }) => ({
  type: VoiceBotsTtsTypes.DELETE_AUDIO_SUCCESS,
  payload: { _id },
});
