import axios from 'axios';

export const API_TOKEN = 'API_TOKEN';

const url = process.env.REACT_APP_API_URL || 'http://localhost:3333';

const headers = {
  common: { authorization: `Bearer ${localStorage.getItem(API_TOKEN)}` },
};

axios.defaults.baseURL = `${url}/api/v1/dashboard/`;

export const AxiosWithInterceptors = axios.create({ headers });

export const AxiosFile = axios.create({ headers, responseType: 'arraybuffer' });

const addAxiosInterceptor = (api) => {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log('error.response.status: ', error.response.status);
      // if (error.response.status === 401) {
      //   localStorage.removeItem(API_TOKEN);
      //   window.location.reload();
      // }
      return Promise.reject(error);
    }
  );
};

addAxiosInterceptor(AxiosWithInterceptors);
addAxiosInterceptor(AxiosFile);

export default AxiosWithInterceptors;
