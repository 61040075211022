export const CompanyTypes = {
  GET_COMPANY_REQUEST: 'company/GET_COMPANY_REQUEST',
  GET_COMPANY_REQUEST_SUCCESS: 'company/GET_COMPANY_REQUEST_SUCCESS',
  GET_COMPANIES_REQUEST: 'company/GET_COMPANIES_REQUEST',
  GET_COMPANIES_REQUEST_SUCCESS: 'company/GET_COMPANIES_REQUEST_SUCCESS',
  CREATE_COMPANY_REQUEST: 'company/CREATE_COMPANY_REQUEST',
  CREATE_COMPANY_REQUEST_SUCCESS: 'company/CREATE_COMPANY_REQUEST_SUCCESS',
  UPDATE_SELECTED_COMPANY_REQUEST: 'company/UPDATE_SELECTED_COMPANY_REQUEST',
  UPDATE_SELECTED_COMPANY_SUCCESS: 'company/UPDATE_SELECTED_COMPANY_SUCCESS',
  UPDATE_COMPANY_REQUEST: 'company/UPDATE_COMPANY_REQUEST',
  UPDATE_COMPANY_REQUEST_SUCCESS: 'company/UPDATE_COMPANY_REQUEST_SUCCESS',
  CANCEL_COMPANY_EDITING: 'company/CANCEL_COMPANY_EDITING',
  START_COMPANY_EDITING: 'company/START_COMPANY_EDITING',
  CREATE_ORGANIZATION: 'company/CREATE_ORGANIZATION',
  CREATE_ORGANIZATION_SUCCESS: 'company/CREATE_ORGANIZATION_SUCCESS',
  REMOVE_OGANIZATION: 'company/REMOVE_OGANIZATION',
  REMOVE_OGANIZATION_SUCCESS: 'company/REMOVE_OGANIZATION_SUCCESS',
  GET_ORGANIZATIONS: 'company/GET_ORGANIZATIONS',
  GET_ORGANIZATIONS_SUCCESS: 'company/GET_ORGANIZATIONS_SUCCESS',
  SET_PLAN_COMPANY: 'company/SET_PLAN_COMPANY',
  SET_PLAN_COMPANY_SUCCESS: 'company/SET_PLAN_COMPANY_SUCCESS',
  UPDATE_PLAN_COMPANY: 'company/UPDATE_PLAN_COMPANY',
  POST_NEW_PLAN: 'plan/POST_NEW_PLAN',
  POST_NEW_PLAN_SUCCESS: 'plan/POST_NEW_PLAN_SUCCESS',
  POST_ADDITIONAL_MESSAGE: 'company/POST_ADDITIONAL_MESSAGE',
  POST_ADDITIONAL_MESSAGE_SUCCESS: 'company/POST_ADDITIONAL_MESSAGE_SUCCESS',
  GET_HISTORY_ACTIONS_REQUEST: 'company/GET_HISTORY_ACTIONS_REQUEST',
  GET_HISTORY_ACTIONS_SUCCESS: 'company/GET_HISTORY_ACTIONS_SUCCESS',
  RESET_REDUX_COMPANIES: 'company/RESET_REDUX_COMPANIES',
  UPDATE_ORGANIZATION_REQUEST: 'company/UPDATE_ORGANIZATION_REQUEST',
};

export const CompanyActions = {
  resetCompanies: () => ({
    type: CompanyTypes.RESET_REDUX_COMPANIES,
  }),

  setCompany: (payload) => ({
    type: CompanyTypes.GET_COMPANY_REQUEST_SUCCESS,
    payload,
  }),

  createNewPlan: (payload) => ({
    type: CompanyTypes.POST_NEW_PLAN,
    payload,
  }),

  getCompany: (payload) => ({
    type: CompanyTypes.GET_COMPANY_REQUEST,
    payload,
  }),

  getCompanies: (searchTerm = undefined) => ({
    type: CompanyTypes.GET_COMPANIES_REQUEST,
    payload: {
      searchTerm,
    },
  }),

  createAdditionalMessage: (payload) => ({
    type: CompanyTypes.POST_ADDITIONAL_MESSAGE,
    payload,
  }),

  createCompany: (data) => ({
    type: CompanyTypes.CREATE_COMPANY_REQUEST,
    payload: data,
  }),

  updateSelectedCompany: (data) => ({
    type: CompanyTypes.UPDATE_SELECTED_COMPANY_REQUEST,
    payload: data,
  }),

  updateCompany: (data) => ({
    type: CompanyTypes.UPDATE_COMPANY_REQUEST,
    payload: data,
  }),

  cancelCompanyEditing: () => ({
    type: CompanyTypes.CANCEL_COMPANY_EDITING,
  }),

  startCompanyEditing: (company) => ({
    type: CompanyTypes.START_COMPANY_EDITING,
    payload: company,
  }),

  createOrganization: (data) => ({
    type: CompanyTypes.CREATE_ORGANIZATION,
    payload: data,
  }),

  updateOrganization: (payload) => ({
    type: CompanyTypes.UPDATE_ORGANIZATION_REQUEST,
    payload,
  }),

  removeOrganization: (organization) => ({
    type: CompanyTypes.REMOVE_OGANIZATION,
    payload: organization,
  }),

  getOrganizations: (data) => ({
    type: CompanyTypes.GET_ORGANIZATIONS,
    payload: data,
  }),

  changePlan: (payload) => ({
    type: CompanyTypes.SET_PLAN_COMPANY,
    payload,
  }),

  updatePlan: (payload) => ({
    type: CompanyTypes.UPDATE_PLAN_COMPANY,
    payload,
  }),

  getHistoryActions: (payload) => ({
    type: CompanyTypes.GET_HISTORY_ACTIONS_REQUEST,
    payload,
  }),
};
