import { put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { FilterTypes } from '../actions/filter';

export function* infoStateFilter(action) {
  const { payload } = action;
  try {
    yield put({ type: FilterTypes.LIST_FILTER_INFO_SUCCESS, payload });
  } catch (error) {
    console.log(error);
    toastr.error('Ops', 'Algo deu errado');
  }
}

export function* relatorioFilterInfo(action) {
  const { payload } = action;
  try {
    yield put({
      type: FilterTypes.FILTER_RELATORIO_INFO_SUCCESS,
      payload,
    });
  } catch (error) {
    console.log(error);
    toastr.error('Ops', 'Algo deu errado');
  }
}
