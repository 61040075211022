export const IaTypes = {
  IA_CREATE_WIT: 'intel/IA_CREATE_WIT',
  IA_CREATE_WIT_SUCCESS: 'intel/IA_CREATE_WIT_SUCCESS',
  IA_CREATE_LUIS: 'intel/IA_CREATE_LUIS',
  IA_CREATE_LUIS_SUCCESS: 'intel/IA_CREATE_LUIS_SUCCESS',
  IA_CREATE_ENTITIES: 'intel/IA_CREATE_ENTITIES',
  IA_CREATE_ENTITIES_SUCCESS: 'intel/IA_CREATE_ENTITIES_SUCCESS',
  IA_CREATE_INTENTS: 'intel/IA_CREATE_INTENTS',
  IA_CREATE_INTENTS_SUCCESS: 'intel/IA_CREATE_INTENTS_SUCCESS',
  IA_CREATE_SAMPLES: 'intel/IA_CREATE_SAMPLES',
  IA_CREATE_SAMPLES_SUCCESS: 'intel/IA_CREATE_SAMPLES_SUCCESS',
  IA_CREATE_BOTERIA: 'intel/IA_CREATE_BOTERIA',
  IA_CREATE_BOTERIA_SUCCESS: 'intel/IA_CREATE_BOTERIA_SUCCESS',
  IA_CREATE_CONDITIONS: 'intel/IA_CREATE_CONDITIONS',
  IA_CREATE_CONDITIONS_SUCCESS: 'intel/IA_CREATE_CONDITIONS_SUCCESS',
  IA_LIST_LUIS: 'intel/IA_LIST_LUIS',
  IA_LIST_LUIS_SUCCESS: 'intel/IA_LIST_LUIS_SUCCESS',
  IA_LIST_WIT: 'intel/IA_LIST_WIT',
  IA_LIST_WIT_SUCCESS: 'intel/IA_LIST_WIT_SUCCESS',
  IA_LIST_ENTITIES: 'intel/IA_LIST_ENTITIES',
  IA_LIST_ENTITIES_SUCCESS: 'intel/IA_LIST_ENTITIES_SUCCESS',
  IA_LIST_ENTITY: 'intel/IA_LIST_ENTITY',
  IA_LIST_ENTITY_SUCCESS: 'intel/IA_LIST_ENTITY_SUCCESS',
  IA_LIST_INTENTS: 'intel/IA_LIST_INTENTS',
  IA_LIST_INTENTS_SUCCESS: 'intel/IA_LIST_INTENTS_SUCCESS',
  IA_LIST_ALL_SAMPLES: 'intel/IA_LIST_ALL_SAMPLES',
  IA_LIST_ALL_SAMPLES_SUCCESS: 'intel/IA_LIST_ALL_SAMPLES_SUCCESS',
  IA_LIST_SAMPLES_INTENT: 'intel/IA_LIST_SAMPLES_INTENT',
  IA_LIST_SAMPLES_INTENT_SUCCESS: 'intel/IA_LIST_SAMPLES_INTENT_SUCCESS',
  IA_LIST_CONDITIONS: 'intel/IA_LIST_CONDITIONS',
  IA_LIST_CONDITIONS_SUCCESS: 'intel/IA_LIST_CONDITIONS_SUCCESS',
  IA_DELETE_ENTITIES: 'intel/IA_DELETE_ENTITIES',
  IA_DELETE_ENTITIES_SUCCESS: 'intel/IA_DELETE_ENTITIES_SUCCESS',
  IA_DELETE_ENTITIES_OBJ: 'intel/IA_DELETE_ENTITIES_OBJ',
  IA_DELETE_ENTITIES_OBJ_SUCCESS: 'intel/IA_DELETE_ENTITIES_OBJ_SUCCESS',
  IA_DELETE_ENTITIES_SYN: 'intel/IA_DELETE_ENTITIES_SYN',
  IA_DELETE_ENTITIES_SYN_SUCCESS: 'intel/IA_DELETE_ENTITIES_SYN_SUCCESS',
  IA_DELETE_INTENTS: 'intel/IA_DELETE_INTENTS',
  IA_DELETE_INTENTS_SUCCESS: 'intel/IA_DELETE_INTENTS_SUCCESS',
  IA_DELETE_SAMPLES: 'intel/IA_DELETE_SAMPLES',
  IA_DELETE_SAMPLES_SUCCESS: 'intel/IA_DELETE_SAMPLES_SUCCESS',
  IA_DELETE_CONDITIONS: 'intel/IA_DELETE_CONDITIONS',
  IA_DELETE_CONDITIONS_SUCCESS: 'intel/IA_DELETE_CONDITIONS_SUCCESS',
  IA_SET_EDIT_CONDITION: 'intel/IA_SET_EDIT_CONDITION',
  IA_UPDATE_CONDITIONS: 'intel/IA_UPDATE_CONDITIONS',
  IA_UPDATE_CONDITIONS_SUCCESS: 'intel/IA_UPDATE_CONDITIONS_SUCCESS',
  IA_SAVE_SAMPLE: 'intel/IA_SAVE_SAMPLE',
  IA_SAVE_SAMPLE_SUCCESS: 'intel/IA_SAVE_SAMPLE_SUCCESS',
  CURATION_BOT_LIST_MESSAGES: 'intel/CURATION_BOT_LIST_MESSAGES',
  CURATION_BOT_LIST_MESSAGES_SUCCESS:
    'intel/CURATION_BOT_LIST_MESSAGES_SUCCESS',
  CURATION_BOT_ACCEPT_MESSAGE: 'intel/CURATION_BOT_ACCEPT_MESSAGE',
  CURATION_BOT_ACCEPT_MESSAGE_SUCCESS:
    'intel/CURATION_BOT_ACCEPT_MESSAGE_SUCCESS',
  CURATION_BOT_IGNORE_MESSAGE: 'intel/CURATION_BOT_IGNORE_MESSAGE',
  CURATION_BOT_IGNORE_MESSAGE_SUCCESS:
    'intel/CURATION_BOT_IGNORE_MESSAGE_SUCCESS',
  CURATION_BOT_SAVE_APRIMORAMENTOS: 'intel/CURATION_BOT_SAVE_APRIMORAMENTOS',
  CURATION_BOT_SET_APRIMORAMENTOS: 'intel/CURATION_BOT_SET_APRIMORAMENTOS',
  CURATION_BOT_APRIMORAMENTOS_DELETE:
    'intel/CURATION_BOT_APRIMORAMENTOS_DELETE',
  CURATION_BOT_APRIMORAMENTOS_CLEAR: 'intel/CURATION_BOT_APRIMORAMENTOS_CLEAR',
  IA_PLATFORM: 'intel/IA_PLATFORM',
  IA_PLATFORM_SUCCESS: 'intel/IA_PLATFORM_SUCCESS',
  IA_PLATFORM_INFO: 'intel/IA_PLATFORM_INFO',
  IA_PLATFORM_INFO_SUCCESS: 'intel/IA_PLATFORM_INFO_SUCCESS',
  IA_PLATFORM_INFO_ERROR: 'intel/IA_PLATFORM_INFO_ERROR',
  IA_UPDATE_FILTER_CURATION: 'intel/IA_UPDATE_FILTER_CURATION',
  IA_TRAIN_LUIS: 'intel/IA_TRAIN_LUIS',
  IA_PREVIEW: 'intel/IA_PREVIEW',
  IA_PREVIEW_SUCCESS: 'intel/IA_PREVIEW_SUCCESS',
};

export const IaCreators = {
  createBoteria: (platform, credentials, botid) => ({
    type: IaTypes.IA_CREATE_BOTERIA,
    payload: { platform, credentials, botid },
  }),

  createWit: (platform, credentials, botid, language) => ({
    type: IaTypes.IA_CREATE_WIT,
    payload: { platform, credentials, botid, language },
  }),

  listWit: (token) => ({
    type: IaTypes.IA_LIST_WIT,
    payload: { token },
  }),

  createLuis: (platform, credentials, botid, language) => ({
    type: IaTypes.IA_CREATE_LUIS,
    payload: { platform, credentials, botid, language },
  }),

  listLuis: (token) => ({
    type: IaTypes.IA_LIST_LUIS,
    payload: { token },
  }),

  listCurationMessages: (
    bot,
    count,
    query,
    processed,
    status = 'none',
    start_date = '',
    end_date = ''
  ) => ({
    type: IaTypes.CURATION_BOT_LIST_MESSAGES,
    payload: { bot, count, query, processed, status, start_date, end_date },
  }),

  ignoreCurationMessage: (bot, message) => ({
    type: IaTypes.CURATION_BOT_IGNORE_MESSAGE,
    payload: { bot, message },
  }),

  acceptCurationMessage: (bot, message) => ({
    type: IaTypes.CURATION_BOT_ACCEPT_MESSAGE,
    payload: { bot, message },
  }),

  listIAEntities: (token) => ({
    type: IaTypes.IA_LIST_ENTITIES,
    payload: { token },
  }),

  listEntity: (token, name) => ({
    type: IaTypes.IA_LIST_ENTITY,
    payload: { token, name },
  }),

  createEntities: (token, name, objects, reference) => ({
    type: IaTypes.IA_CREATE_ENTITIES,
    payload: { token, name, objects, reference },
  }),

  deleteEntities: (token, name, reference) => ({
    type: IaTypes.IA_DELETE_ENTITIES,
    payload: { token, name, reference },
  }),

  deleteEntitiesObj: (token, name, object, reference, index) => ({
    type: IaTypes.IA_DELETE_ENTITIES_OBJ,
    payload: { token, name, object, reference, index },
  }),

  deleteEntitiesSyn: (token, name, object, synonym, reference) => ({
    type: IaTypes.IA_DELETE_ENTITIES_SYN,
    payload: { token, name, object, synonym, reference },
  }),

  createSamples: (token, sentence, intents) => ({
    type: IaTypes.IA_CREATE_SAMPLES,
    payload: { token, sentence, intents },
  }),

  createIntents: (token, name, sentence) => ({
    type: IaTypes.IA_CREATE_INTENTS,
    payload: { token, name, sentence },
  }),

  listIAIntents: (token) => ({
    type: IaTypes.IA_LIST_INTENTS,
    payload: { token },
  }),

  deleteIntents: (token, name) => ({
    type: IaTypes.IA_DELETE_INTENTS,
    payload: { token, name },
  }),

  listSamplesIntent: (token, type, value) => ({
    type: IaTypes.IA_LIST_SAMPLES_INTENT,
    payload: { token, type, value },
  }),

  listAllSamples: (token, type) => ({
    type: IaTypes.IA_LIST_ALL_SAMPLES,
    payload: { token, type },
  }),

  saveIASample: (ia, sample) => ({
    type: IaTypes.IA_SAVE_SAMPLE,
    payload: { ia, sample },
  }),

  deleteSamples: (name, token, type, sentence) => ({
    type: IaTypes.IA_DELETE_SAMPLES,
    payload: { name, token, type, sentence },
  }),

  listConditions: (botId) => ({
    type: IaTypes.IA_LIST_CONDITIONS,
    payload: botId,
  }),

  createCondition: (payload) => ({
    type: IaTypes.IA_CREATE_CONDITIONS,
    payload,
  }),

  updateCondition: (payload) => ({
    type: IaTypes.IA_UPDATE_CONDITIONS,
    payload,
  }),

  deleteCondition: (_id) => ({
    type: IaTypes.IA_DELETE_CONDITIONS,
    payload: _id,
  }),

  setEditCondition: (condition) => ({
    type: IaTypes.IA_SET_EDIT_CONDITION,
    payload: condition,
  }),

  setAprimoramentos: (aprimoramento) => ({
    type: IaTypes.CURATION_BOT_SET_APRIMORAMENTOS,
    payload: aprimoramento,
  }),

  deleteAprimoramento: (aprimoramento) => ({
    type: IaTypes.CURATION_BOT_APRIMORAMENTOS_DELETE,
    payload: aprimoramento,
  }),

  clearAprimoramentos: () => ({
    type: IaTypes.CURATION_BOT_APRIMORAMENTOS_CLEAR,
  }),

  saveAprimoramentos: (aprimoramentos, options) => ({
    type: IaTypes.CURATION_BOT_SAVE_APRIMORAMENTOS,
    payload: { aprimoramentos, options },
  }),

  getIaInfo: (token) => ({
    type: IaTypes.IA_PLATFORM_INFO,
    payload: { token },
  }),

  updateFilterCuration: (newFilter) => ({
    type: IaTypes.IA_UPDATE_FILTER_CURATION,
    payload: newFilter,
  }),

  trainLuis: (token) => ({
    type: IaTypes.IA_TRAIN_LUIS,
    payload: { token },
  }),

  iaPreview: ({ token, message }) => ({
    type: IaTypes.IA_PREVIEW,
    payload: { token, message },
  }),
};
