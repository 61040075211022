import actions from '../actions';

const initialState = {
  company: {},
  companies: [],
  getCompaniesCompleted: false,
  companyEditing: undefined,
  organizations: [],
  getOrganizationsCompleted: false,
  historyActions: {
    docs: [],
    page: 1,
    totalPages: 0,
  },
};

export const companiesState = (state = initialState, action) => {
  switch (action.type) {
    case actions.CompanyTypes.RESET_REDUX_COMPANIES: {
      state = initialState;
      return state;
    }
    case actions.CompanyTypes.GET_COMPANY_REQUEST_SUCCESS:
      return {
        ...state,
        company: action.payload,
        companies: state.companies.map((company) =>
          company._id !== action.payload._id ? company : action.payload
        ),
      };
    case actions.CompanyTypes.SET_PLAN_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload.company,
      };
    case actions.CompanyTypes.GET_COMPANIES_REQUEST_SUCCESS:
      return {
        ...state,
        companies: [...action.payload],
        getCompaniesCompleted: true,
      };
    case actions.CompanyTypes.CREATE_COMPANY_REQUEST_SUCCESS:
      return {
        ...state,
        companyEditing: { ...action.payload },
      };
    case actions.CompanyTypes.UPDATE_SELECTED_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload,
      };
    case actions.CompanyTypes.UPDATE_COMPANY_REQUEST_SUCCESS:
    case actions.CompanyTypes.CANCEL_COMPANY_EDITING:
      return {
        ...state,
        companyEditing: undefined,
      };
    case actions.CompanyTypes.START_COMPANY_EDITING:
      return {
        ...state,
        companyEditing: { ...action.payload },
      };
    case actions.CompanyTypes.GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: [...action.payload],
        getOrganizationsCompleted: true,
      };
    case actions.CompanyTypes.GET_HISTORY_ACTIONS_SUCCESS:
      return {
        ...state,
        historyActions: { ...action.payload },
      };
    case actions.CompanyTypes.GET_ERROR_LOGS_SUCCESS:
      return {
        ...state,
        errorLogs: action.payload,
      };
    default:
      return state;
  }
};

export default companiesState;
