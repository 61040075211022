import { call, put, fork } from 'redux-saga/effects';
import { Types } from 'mongoose';
import { toastr } from 'react-redux-toastr';
import { commonActions } from '../actions/common';

import api from '../../services/api';

import { ItemsTypes, ItemsCreators } from '../actions/items';
import { ConnectorsCreators } from '../actions/connectors';
import { DebounceCreators } from '../actions/debounce';

export function* create(action) {
  try {
    const { botid, rootId } = action.payload;
    const item = {
      _id: Types.ObjectId().toString(),
      botId: botid,
      ...action.payload.item,
    };
    const { option } = item;
    delete item.option;

    if (rootId && rootId !== botid) {
      item.subflowFor = rootId;
    }

    const itemTypesNoDebounce = [
      'Collect',
      'Human',
      'Image',
      'Document',
      'Video',
      'Audio',
      'ZendeskCreateRequest',
      'ZendeskListRequests',
      'ZendeskShowRequest',
      'ZendeskListComments',
      'ZendeskInsertComment',
      'ZendeskAttachFile',
      'ZendeskUpdateStatusRequest',
      'JiraCreateIssue',
      'JiraListIssues',
      'JiraShowIssue',
      'JiraListProjects',
      'JiraListStatus',
      'JiraListRequestypes',
      'JiraListPriorities',
      'JiraListComments',
      'JiraInsertComment',
      'JiraAttachFile',
      'JiraUpdateStatusIssue',
      'RdStationInput',
      'RdStationSubmitLead',
      'GlpiCreateRequest',
      'GlpiListRequests',
      'GlpiShowRequest',
      'GlpiListComments',
      'GlpiInsertComment',
      'GlpiAttachFile',
      'GlpiUpdateStatusRequest',
      'GlpiListGroups',
    ];
    const itemTypesMedia = ['Image', 'Document', 'Video', 'Audio'];
    if (!itemTypesNoDebounce.includes(item.type)) {
      yield put(
        DebounceCreators.addItemDebounce({ type: 'ADD_ITEM', payload: item })
      );
    } else if (itemTypesMedia.includes(item.type)) {
      yield fork(api.post, `/v1/bots/${botid}/items`, item);
    } else {
      yield call(api.post, `/v1/bots/${botid}/items`, item);
    }
    yield put(ItemsCreators.createItemSuccess(item));

    // Verifica se o item adicionado possuí alguma ligação caso contrario ele n cria um conector para ele
    if (!option.orphan) {
      if (option.fromRoot) {
        yield put(
          ConnectorsCreators.createConnectorRequest({
            from: rootId !== botid ? rootId : botid,
            to: item._id,
            botId: botid,
            subflowFor: rootId !== botid ? rootId : null,
          })
        );
      } else {
        yield put(
          ConnectorsCreators.createConnectorRequest({
            from: option.from._id,
            to: item._id,
            botId: botid,
            subflowFor: rootId !== botid ? rootId : null,
          })
        );
      }
    }

    yield put(ItemsCreators.updateAllItems({ item, isRemove: false }));
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.customMsg
    ) {
      toastr.error('Ops', error.response.data.message);
    } else {
      toastr.error(
        'Ops',
        'Algo de errado aconteceu ao criar um novo item para o bot.'
      );
    }
  }
}

export function* createVoiceItem(action) {
  const { botid } = action.payload;

  const item = {
    _id: Types.ObjectId().toString(),
    botId: botid,
    ...action.payload.item,
  };
  try {
    const { option } = item;
    delete item.option;

    yield put(ItemsCreators.createItemSuccess(item));

    const response = yield call(
      api.post,
      `/v1/voice-bots/${botid}/items`,
      item
    );

    const newItem = response.data;

    yield put(ItemsCreators.updateItemInListState({ item: newItem }));

    // Verifica se o item adicionado possuí alguma ligação caso contrario ele n cria um conector para ele
    if (!option.orphan) {
      if (option.fromRoot) {
        yield put(
          ConnectorsCreators.createConnectorRequest({
            from: botid,
            to: item._id,
            botId: botid,
          })
        );
      } else {
        const menuOptionDescription = option?.from?.description;
        const fromMenuOption =
          typeof menuOptionDescription === 'string'
            ? menuOptionDescription
            : '';
        yield put(
          ConnectorsCreators.createVoiceConnectorRequest({
            from: option.from._id,
            to: item._id,
            botId: botid,
            voiceSettings: {
              idIvrStepFlow: -1,
              nmIvrResult: fromMenuOption,
            },
          })
        );
      }
    }

    yield put(ItemsCreators.updateAllItems({ item: newItem, isRemove: false }));
  } catch (error) {
    yield put(ItemsCreators.removeItemInListState({ item }));
    const errorMessage =
      error?.response?.data?.message ||
      'Algo de errado aconteceu ao criar um novo item para o bot.';

    toastr.error('Ops', errorMessage);
  }
}

export function* removeVoiceItem(action) {
  try {
    const { _id } = action.payload.item;

    yield put(ItemsCreators.removeItemSuccess(_id));
    yield call(api.delete, `/v1/voice-bots/items/${_id}`);
  } catch (error) {
    toastr.error('Ops', 'Algo de errado aconteceu ao remover o Item.');
  }
}

export function* cloneVoiceItem(action) {
  const { item, targetWidth } = action.payload;
  try {
    const newItem = {
      ...item,
      _id: Types.ObjectId().toString(),
      positionOnScreen: [
        item.positionOnScreen[0] + targetWidth,
        item.positionOnScreen[1],
      ],
      voiceSettings: { ...item.voiceSettings, idIvrStep: -1 },
    };

    yield put(ItemsCreators.createItemSuccess(newItem));
    const responseNewItem = yield call(
      api.post,
      `/v1/voice-bots/${item?.botId}/items`,
      newItem
    );

    yield put(
      ItemsCreators.updateItemInListState({ item: responseNewItem.data })
    );
  } catch (error) {
    toastr.error('Ops', 'Algo de errado aconteceu ao duplicar o Item.');
  }
}

export function* editItem(action) {
  try {
    yield put(
      DebounceCreators.addItemDebounce({
        type: 'UPDATE_ITEM',
        payload: action.payload,
      })
    );
    yield put(
      ItemsCreators.updateAllItems({ item: action.payload, isRemove: false })
    );
  } catch (error) {
    toastr.error('Ops', 'Algo de errado aconteceu ao editar o item.');
  }
}

export function* removeItem(action) {
  try {
    yield put(
      DebounceCreators.addItemDebounce({
        type: 'REMOVE_ITEM',
        payload: action.payload,
      })
    );
    yield put(ItemsCreators.removeItemSuccess(action.payload._id));
    yield put(
      ItemsCreators.updateAllItems({ item: action.payload, isRemove: true })
    );
  } catch (error) {
    toastr.error('Ops', 'Algo de errado aconteceu ao remover o Item.');
  }
}

export function* cloneItem(action) {
  const { item, targetWidth } = action.payload;
  try {
    const newItem = JSON.parse(JSON.stringify(item));
    if (newItem.type === 'Menu' || newItem.type === 'MenuAI') {
      newItem.menuItems = newItem.menuItems.map((menuItem) => {
        return { ...menuItem, _id: Types.ObjectId().toString() };
      });
    }

    const isVoiceItem = newItem?.type?.startsWith('Voice');
    if (isVoiceItem) {
      newItem.voiceSettings = { ...newItem.voiceSettings, idIvrStep: -1 };
    }

    newItem._id = Types.ObjectId().toString();
    newItem.positionOnScreen = [
      item.positionOnScreen[0] + targetWidth,
      item.positionOnScreen[1],
    ];

    yield put(
      DebounceCreators.addItemDebounce({ type: 'CLONE_ITEM', payload: newItem })
    );
    yield put(ItemsCreators.createItemSuccess(newItem));
  } catch (error) {
    toastr.error('Ops', 'Algo de errado aconteceu ao duplicar o Item.');
  }
}

export function* list(action) {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });
    const { data } = yield call(api.get, `/v1/bots/${action.payload}/items`);
    yield put(ItemsCreators.listItemsSuccess(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar os items desse Bot.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* listSubflow(action) {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });
    const { botId, itemId } = action.payload;
    const url = `/v1/bots/${botId}/items-subflow/${itemId}`;
    const { data } = yield call(api.get, url);
    yield put(ItemsCreators.listItemsSuccess(data));
  } catch (error) {
    console.log(error);
    toastr.error('Ops', 'Não foi possível listar os items desse grupo.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* getAllItems(action) {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });
    const { botId } = action.payload;
    const url = `/v1/bots/${botId}/all-items`;
    const { data } = yield call(api.get, url);
    yield put({ type: ItemsTypes.LIST_ALL_ITEMS_SUCCESS, payload: data });
  } catch (error) {
    console.log(error);
    toastr.error('Ops', 'Não foi possível listar os items desse grupo.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* editCardImage(action) {
  try {
    const { file, item, cardId } = action.payload;
    const { botId, _id: itemId } = item;

    const formData = new FormData();
    formData.append('file', file);

    const response = yield call(
      api.put,
      `/v1/bots/${botId}/items/${itemId}/cards/${cardId}/upload-image`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    const updatedItem = response.data;

    yield put({ type: ItemsTypes.EDIT_ITEM, payload: updatedItem });
  } catch (err) {
    toastr.error(
      'Ops',
      'Algo de errado aconteceu ao realizar o upload para o servidor.'
    );
    console.log(`sagas.items.editCardImage: ${err}`);
  }
}

export function* editItemFile(action) {
  action.payload.item.uploading = true;
  action.payload.item.upload_progress = 0;

  try {
    const formData = new FormData();
    formData.append('file', action.payload.file);

    const { data } = yield call(
      api.put,
      `/v1/bots/${action.payload.botId}/items/${action.payload.itemid}/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: yield (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          action.payload.item.upload_progress = percentage;

          put({
            type: ItemsTypes.EDIT_ITEM_UPLOAD_PROGRESS,
            payload: { item: action.payload.item, progress: percentage },
          });
        },
      }
    );
    let parsedData = null;
    if (typeof data === 'string') {
      parsedData = JSON.parse(data.split('|')[0]);
    } else {
      parsedData = data;
    }
    action.payload.item.thumbnailUrl = parsedData.thumbnailUrl;
    action.payload.item.fileUrl = parsedData.fileUrl;
    if (data.error) {
      if (data.message) {
        toastr.error('Ops', `${data.message}`);
      } else {
        toastr.error(
          'Ops',
          'Algo de errado aconteceu ao realizar o upload para o servidor.'
        );
      }
    } else {
      yield put({ type: ItemsTypes.EDIT_ITEM, payload: action.payload.item });
    }
  } catch (error) {
    toastr.error(
      'Ops',
      'Algo de errado aconteceu ao editar o arquivo do item.'
    );
  } finally {
    action.payload.item.uploading = false;
  }
}

export function* removeItemFile(action) {
  action.payload.item.uploading = true;
  action.payload.item.upload_progress = 0;

  try {
    const { data } = yield call(
      api.put,
      `/v1/bots/${action.payload.botId}/items/${action.payload.itemid}/remove`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
        onUploadProgress: yield (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          action.payload.item.upload_progress = percentage;

          put({
            type: ItemsTypes.EDIT_ITEM_UPLOAD_PROGRESS,
            payload: { item: action.payload.item, progress: percentage },
          });
        },
      }
    );
    let parsedData = null;
    if (typeof data === 'string') {
      parsedData = JSON.parse(data.split('|')[0]);
    } else {
      parsedData = data;
    }
    action.payload.item.thumbnailUrl = parsedData.thumbnailUrl;
    action.payload.item.fileUrl = parsedData.fileUrl;
    if (data.error) {
      toastr.error(
        'Ops',
        'Algo de errado aconteceu ao tentar remover arquivo.'
      );
    } else {
      yield put({ type: ItemsTypes.EDIT_ITEM, payload: action.payload.item });
    }
  } catch (error) {
    toastr.error(
      'Ops',
      'Algo de errado aconteceu ao editar o arquivo do item.'
    );
  } finally {
    action.payload.item.uploading = false;
  }
}

export function* editItemUrl(action) {
  action.payload.item.uploading = true;
  action.payload.item.upload_progress = 0;

  try {
    yield call(
      api.put,
      `/v1/bots/${action.payload.botId}/items/${action.payload.itemid}/download`,
      { url: action.payload.url },
      {
        responseType: 'stream',
        onDownloadProgress: yield (progressEvent) => {
          const progress = progressEvent.currentTarget.response.split('|');
          if (progress) {
            const progressData = progress[progress.length - 2];

            if (!progressData) {
              return;
            }

            if (!isNaN(progressData)) {
              action.payload.item.upload_progress = progressData;
            } else if (JSON.parse(progressData)) {
              const parsed = JSON.parse(progressData);

              action.payload.item.uploading = false;
              action.payload.item.upload_progress = 100;
              action.payload.item.fileUrl = parsed.fileUrl;
              action.payload.item.thumbnailUrl = parsed.thumbnailUrl;
              action.payload.item = parsed;

              put({ type: ItemsTypes.EDIT_ITEM, payload: action.payload.item });
            }
            put({
              type: ItemsTypes.EDIT_ITEM_UPLOAD_PROGRESS,
              payload: {
                item: action.payload.item,
                progress: action.payload.item.upload_progress,
              },
            });
          }
        },
      }
    );
    yield put({ type: ItemsTypes.EDIT_ITEM, payload: action.payload.item });
  } catch (error) {
    toastr.error(
      'Ops',
      'Algo de errado aconteceu ao editar a URL da imagem do item.'
    );
  } finally {
    action.payload.item.uploading = false;
  }
}
