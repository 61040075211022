export const registerFromTypes = {
  GET_REGISTERS_REQUEST: 'registerFrom/GET_REGISTERS_REQUEST',
  GET_REGISTERS_SUCCESS: 'registerFrom/GET_REGISTERS_SUCCESS',
};

export const getRegisters = () => ({
  type: registerFromTypes.GET_REGISTERS_REQUEST,
  payload: null,
});

export const getRegistersSuccess = (registers) => ({
  type: registerFromTypes.GET_REGISTERS_SUCCESS,
  payload: registers,
});
