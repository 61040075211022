import { ConfigPageTypes } from '../actions/page-config';

const INITIAL_STATE = {
  page: 'bot',
  subPage: 'openingHours',
};

export default function configPage(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ConfigPageTypes.SET_MENU_PAGE_SUCCESS: {
      return { ...state, page: action.payload };
    }
    case ConfigPageTypes.SET_SUB_MENU_PAGE_SUCCESS: {
      return { ...state, subPage: action.payload };
    }

    default: {
      return state;
    }
  }
}
