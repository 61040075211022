export const TemplateBotTypes = {
  CREATE_TEMPLATE_BOT: 'template-bot/CREATE_TEMPLATE_BOT',
  CREATE_TEMPLATE_BOT_SUCCESS: 'template-bot/CREATE_TEMPLATE_BOT_SUCCESS',
  UPDATE_TEMPLATE_BOT: 'template-bot/UPDATE_TEMPLATE_BOT',
  UPDATE_TEMPLATE_BOT_SUCCESS: 'template-bot/UPDATE_TEMPLATE_BOT_SUCCESS',
  UPDATE_TEMPLATE_BOT_DETAILS: 'template-bot/UPDATE_TEMPLATE_BOT_DETAILS',
  UPDATE_TEMPLATE_BOT_DETAILS_SUCCESS:
    'template-bot/UPDATE_TEMPLATE_BOT_DETAILS_SUCCESS',
  GET_TEMPLATE_BOT: 'template-bot/GET_TEMPLATE_BOT',
  GET_TEMPLATE_BOT_SUCCESS: 'template-bot/GET_TEMPLATE_BOT_SUCCESS',
  GET_ALL_TEMPLATE_BOTS: 'template-bot/GET_ALL_TEMPLATE_BOTS',
  GET_ALL_TEMPLATE_BOTS_SUCCESS: 'template-bot/GET_ALL_TEMPLATE_BOTS_SUCCESS',
  GET_TEMPLATE_BOT_BY_ID: 'template-bot/GET_TEMPLATE_BOT_BY_ID',
  GET_TEMPLATE_BOT_BY_ID_SUCCESS: 'template-bot/GET_TEMPLATE_BOT_BY_ID_SUCCESS',
  EMPTY_TEMPLATE_BOT: 'template-bot/EMPTY_TEMPLATE_BOT',
  EMPTY_TEMPLATE_BOT_SUCCESS: 'template-bot/EMPTY_TEMPLATE_BOT_SUCCESS',
  SET_TEMPLATE_BOT_DATA: 'template-bot/SET_TEMPLATE_BOT_DATA',
  SET_TEMPLATE_BOT_DATA_SUCCESS: 'template-bot/SET_TEMPLATE_BOT_DATA_SUCCESS',
  DELETE_TEMPLATE_BOT: 'template-bot/DELETE_TEMPLATE_BOT',
  DELETE_TEMPLATE_BOT_SUCCESS: 'template-bot/DELETE_TEMPLATE_BOT_SUCCESS',
};

export const createTemplateBot = (payload) => ({
  type: TemplateBotTypes.CREATE_TEMPLATE_BOT,
  payload,
});

export const updateTemplateBot = (payload) => ({
  type: TemplateBotTypes.UPDATE_TEMPLATE_BOT,
  payload,
});

export const updateTemplateBotDetails = (payload) => ({
  type: TemplateBotTypes.UPDATE_TEMPLATE_BOT_DETAILS,
  payload,
});

export const emptyTemplateBot = (payload) => ({
  type: TemplateBotTypes.EMPTY_TEMPLATE_BOT,
  payload,
});

export const getTemplateBot = (botId) => ({
  type: TemplateBotTypes.GET_TEMPLATE_BOT,
  payload: { botId },
});

export const getTemplateBotById = (id) => ({
  type: TemplateBotTypes.GET_TEMPLATE_BOT_BY_ID,
  payload: { id },
});

export const setTemplateBotData = (template) => ({
  type: TemplateBotTypes.SET_TEMPLATE_BOT_DATA,
  payload: template,
});

export const getAllTemplatesBot = () => ({
  type: TemplateBotTypes.GET_ALL_TEMPLATE_BOTS,
  payload: {},
});

export const deleteTemplateBot = (deletedTemplatesIds) => ({
  type: TemplateBotTypes.DELETE_TEMPLATE_BOT,
  payload: { deletedTemplatesIds },
});
