import { call, put, select } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { parseISO, formatDistance } from 'date-fns';
import pt from 'date-fns/locale/pt';

import { NotificationTypes } from '../actions/notifications';
import api from '../../services/api';

export function* list({ payload }) {
  try {
    const { companyId, page } = payload;

    const { notifications: oldNotifications } = yield select(
      (state) => state.notifications.state
    );

    if (page <= oldNotifications.page) return;

    const response = yield call(
      api.get,
      `/v1/notifications/list/${companyId}?page=${page}`
    );

    const { notifications, totalCount, totalPages } = response.data;

    const formattedNotifications = notifications.map((notification) => {
      return {
        ...notification,
        timeDistance: formatDistance(
          parseISO(notification.dateCreation),
          new Date(),
          {
            addSuffix: true,
            locale: pt,
          }
        ),
      };
    });

    const allNotifications = {
      data: [...oldNotifications.data, ...formattedNotifications],
      page,
      totalCount,
      totalPages,
    };

    yield put({
      type: NotificationTypes.LIST_SUCCESS,
      payload: allNotifications,
    });
  } catch (err) {
    toastr.error('Erro', 'Ocorreu um erro ao listar as notificações.');
  }
}

export function* updateStatus({ payload }) {
  try {
    const { id } = payload;

    yield call(api.put, `/v1/notifications/update/${id}`);

    const { notifications } = yield select(
      (state) => state.notifications.state
    );

    const updatedNotifications = notifications.data.map((notification) =>
      notification._id === id
        ? {
            ...notification,
            read: true,
          }
        : notification
    );

    yield put({
      type: NotificationTypes.UPDATE_STATUS_SUCCESS,
      payload: { ...notifications, data: updatedNotifications },
    });
  } catch (err) {
    toastr.error(
      'Erro',
      (err.response && err.response.data.message) ||
        'Ocorreu um erro ao atualizar a notificação.'
    );
  }
}
