import { combineReducers } from 'redux';
import { VariableTypes } from '../actions/variables';

const initialState = {
  variables: { bot: [], global: [] },
};

export const variablesState = (state = initialState, action) => {
  switch (action.type) {
    case VariableTypes.CREATE_SUCCESS:
      return {
        ...state,
        variables: action.payload,
      };

    case VariableTypes.LIST_SUCCESS:
      return {
        ...state,
        variables: action.payload,
      };

    case VariableTypes.EDIT_SUCCESS:
      return {
        ...state,
        variables: action.payload,
      };

    case VariableTypes.REMOVE_SUCCESS:
      return {
        ...state,
        variables: action.payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  variablesState,
});
