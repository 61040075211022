import { call, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import api from '../../services/api';
import * as actions from '../actions/voice-bots-tts';

import { commonLoadingStart, commonLoadingFinish } from '../actions/common';

export function* uploadAudios({ payload }) {
  const { files, botId } = payload;

  try {
    yield put(commonLoadingStart());

    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(index, file);
    });

    const response = yield call(
      api.post,
      `/v1/voice-bots/tts/file/${botId}`,
      formData,
      { timeout: 60000 }
    );

    const { success, error } = response.data;

    if (error?.length > 0) {
      const message = error.length > 1 ? 'arquivos' : 'arquivo';
      toastr.warning(
        'Aviso',
        `Tivemos um problema no upload de ${error.length} ${message}`
      );
    }

    yield put(actions.uploadAudiosSuccess({ audios: success }));
  } catch (e) {
    const errorMessage =
      e.response.data?.message || 'Ocorreu um erro ao fazer o upload';
    toastr.error('Erro', errorMessage);
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* deleteAudio({ payload }) {
  try {
    const { _id } = payload;

    yield call(api.delete, `/v1/voice-bots/tts/file/${_id}`);

    yield put(actions.deleteAudioSuccess({ _id }));
  } catch (e) {
    toastr.error('Erro', 'Ocorreu um erro ao excluir o arquivo');
  }
}

export function* fetchAudios({ payload }) {
  try {
    const { botId } = payload;

    const { data } = yield call(api.get, `/v1/voice-bots/tts/${botId}`, {
      timeout: 30000,
    });

    yield put(actions.fetchAudiosSuccess({ audios: data }));
  } catch (e) {
    toastr.error('Erro', 'Ocorreu um erro ao listar os audios');
  }
}

export function* generateTts({ payload }) {
  try {
    const { text, idProject, idIvrVoice } = payload;

    const { data } = yield call(
      api.post,
      `/v1/voice-bots/tts/generate`,
      {
        text,
        idProject,
        idIvrVoice,
      },
      { responseType: 'arraybuffer', timeout: 50000 }
    );

    const blob = new Blob([data], { type: 'audio/wav' });
    const blobUrl = URL.createObjectURL(blob);

    yield put(actions.generateTtsSuccess({ audioUrl: blobUrl }));
  } catch (e) {
    toastr.error('Erro', 'Ocorreu um erro ao gerar o TTS');
    yield put(actions.generateTtsFailed());
  }
}
