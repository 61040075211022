export const PermissionTypes = {
  GET_PERMISSIONS: 'permission/GET_PERMISSIONS',
  GET_PERMISSIONS_SUCCESS: 'permission/GET_PERMISSIONS_SUCCESS',
  UPDATE_PERMISSION: 'permission/UPDATE_PERMISSION',
  UPDATE_PERMISSION_SUCCESS: 'permission/UPDATE_PERMISSION_SUCCESS'
}

export const PermissionActions = {

  getPermissions: () => ({
    type: PermissionTypes.GET_PERMISSIONS
  }),

  updatePermission: (data) => ({
    type: PermissionTypes.UPDATE_PERMISSION,
    payload: data,
  }),

}
