import { call, put, select } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { VariableTypes } from '../actions/variables';
import api from '../../services/api';

export function* list({ payload }) {
  try {
    const { botId } = payload;

    const response = yield call(api.get, `/v1/bots/variable/${botId}`);

    yield put({
      type: VariableTypes.LIST_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    toastr.error('Erro', 'Ocorreu um erro ao listar as variáveis.');
  }
}

export function* create({ payload }) {
  try {
    const { setModalOpened, ...data } = payload;

    const response = yield call(api.post, '/v1/bots/variable', data);

    const { variables } = yield select(
      (state) => state.variables.variablesState
    );

    const newVariables = {
      bot: [...variables.bot, response.data],
      global: variables.global,
    };

    yield put({
      type: VariableTypes.CREATE_SUCCESS,
      payload: newVariables,
    });

    if (setModalOpened) {
      setModalOpened('');
    }
    toastr.success('Sucesso', 'A variável foi armazenada');
  } catch (err) {
    toastr.error(
      'Erro',
      (err.response && err.response.data.message) ||
        'Ocorreu um erro ao criar a variável.'
    );
  }
}

export function* edit({ payload }) {
  try {
    const { setModalOpened, _id, ...data } = payload;

    const response = yield call(api.put, `/v1/bots/variable/${_id}`, data);

    const { variables } = yield select(
      (state) => state.variables.variablesState
    );

    const findVariableIndex = variables.bot.findIndex(
      (variable) => variable._id === _id
    );

    const updatedBotVariables = variables.bot;
    updatedBotVariables[findVariableIndex] = response.data;

    const updatedVariables = {
      bot: updatedBotVariables,
      global: variables.global,
    };

    yield put({
      type: VariableTypes.EDIT_SUCCESS,
      payload: updatedVariables,
    });

    setModalOpened('');
  } catch (err) {
    toastr.error(
      'Erro',
      (err.response && err.response.data.message) ||
        'Ocorreu um erro ao atualizar a variável.'
    );
  }
}

export function* remove({ payload }) {
  try {
    const { id } = payload;

    yield call(api.delete, `/v1/bots/variable/${id}`);

    const { variables } = yield select(
      (state) => state.variables.variablesState
    );

    const botVariables = variables.bot.filter(
      (variable) => variable._id !== id
    );

    const updatedVariables = {
      bot: botVariables,
      global: variables.global,
    };

    yield put({
      type: VariableTypes.REMOVE_SUCCESS,
      payload: updatedVariables,
    });
  } catch (err) {
    toastr.error('Erro', 'Ocorreu um erro ao deletar a variável.');
  }
}
