import actions from '../actions';

const initialState = {
  permissions: [],
  getPermissionsCompleted: false,
};

export default function permissionsState(state = initialState, action) {
  switch (action.type) {
    case actions.PermissionTypes.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: [...action.payload],
        getPermissionsCompleted: true,
      };
    default:
      return {
        ...state,
      };
  }
}
