import moment from 'moment';
import { call, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';

import store from '..';
import { BotsTypes, BotsCreators } from '../actions/bots';
import { IaTypes } from '../actions/intelligence';

import { commonActions } from '../actions/common';
import api from '../../services/api';
import apiReports, { AxiosFile } from '../../services/api.reports';

import { ItemsCreators } from '../actions/items';
import { ConnectorsCreators } from '../actions/connectors';

export function* getBot(action) {
  const { payload } = action;

  try {
    const { data } = yield call(api.get, `/v1/bots/${payload}`);

    yield put({ type: BotsTypes.RETRIEVE_BOT_SUCCESS, payload: data });

    if (data.ia && data.ia.platforms && data.ia.platforms.length > 0) {
      const findL = data.ia.platforms.find(
        (element) => element.token === data.currentTokenIa
      );
      if (findL) {
        yield put({
          type: IaTypes.IA_PLATFORM_SUCCESS,
          payload: findL.platform,
        });
      }
    }
  } catch (error) {
    toastr.error('Ops', 'Falha na requisição de informações do bot');
  }
}

export function* historyMessage(action) {
  const { payload } = action;
  const { botid, sessionId, count, page, pages } = payload;

  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    if (page === pages) {
      return;
    }

    if (page === 0) {
      yield put({
        type: BotsTypes.CLEAR_HISTORY_BOT_MESSAGE,
      });
    }

    const { data } = yield call(
      api.get,
      `/v1/bots/messages/?bot=${botid}&session=${sessionId}&index=${
        page + 1
      }&count=${count}`
    );

    yield put({
      type: BotsTypes.HISTORY_BOT_MESSAGE_SUCCESS,
      payload: data.messages,
    });
  } catch (error) {
    toastr.error('Ops', 'Não foi possível acessar o historico de mensagem.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* filterSessions(action) {
  const { payload } = action;
  const {
    botid,
    start,
    end,
    count,
    busc,
    page,
    pages,
    tags,
    channels,
    variables,
    timezone,
  } = payload;

  try {
    if (page === pages) {
      return;
    }

    if (page === 0) {
      yield put({
        type: BotsTypes.CLEAR_HISTORY_BOT_FILTER_SESSIONS,
      });
    }

    let endpoint = `/v1/bots/sessions?bot=${botid}`;
    endpoint += `&start=${start}`;
    endpoint += `&end=${end}`;
    endpoint += `&index=${page + 1}`;
    endpoint += `&count=${count}`;
    endpoint += `&timezone=${timezone}`;

    if (tags.length !== 0) {
      endpoint += `&tags=${encodeURIComponent(JSON.stringify(tags))}`;
    }

    if (channels.length !== 0) {
      endpoint += `&channels=${encodeURIComponent(JSON.stringify(channels))}`;
    }

    if (variables.length !== 0) {
      endpoint += `&variables=${encodeURIComponent(JSON.stringify(variables))}`;
    }

    const { data } = yield call(api.get, endpoint);

    if (busc === true && data.sessions.docs.length > 0) {
      toastr.success('Filtro', 'Busca feita com sucesso!');
      yield put({
        type: BotsTypes.HISTORY_BOT_FILTER_SESSIONS_SEARCH_SUCCESS,
        payload: data.sessions,
      });
    } else if (data.sessions.docs.length > 0) {
      yield put({
        type: BotsTypes.HISTORY_BOT_FILTER_SESSIONS_SUCCESS,
        payload: data.sessions,
      });
    } else if (data.sessions.docs.length <= 0) {
      toastr.success('Filtro', 'Não há mais sessões para esta data');
    }
  } catch (error) {
    console.log('Saga Error:', error);
    toastr.error('Ops', 'Não foi possível acessar o historico de mensagem.');
  }
}

export function* filterDashboard(action) {
  const { payload } = action;
  const { botid, start, end, busc, timezone } = payload;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const { data } = yield call(
      api.get,
      `/v1/bots/dashboard?bot=${botid}&start=${start}&end=${end}&timezone=${timezone}`
    );

    yield put({
      type: BotsTypes.HISTORY_BOT_FILTER_DASHBOARD_SUCCESS,
      payload: data,
    });

    if (busc === true) {
      toastr.success('Filtro', 'Busca feita com sucesso!');
    }
  } catch (error) {
    toastr.error('Ops', 'Não foi possível acessar o historico de mensagem.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* editBotTitle(action) {
  try {
    const { data } = yield call(api.put, `/v1/bots/${action.payload.botid}`, {
      title: action.payload.title,
    });

    yield put({ type: BotsTypes.EDIT_TITLE_BOT_SUCCESS, payload: data });

    if (action.payload.showToast) {
      toastr.success('Atualização', 'Atualização do título efetuada');
    }
  } catch (error) {
    console.log(error, 'EROORR');
    toastr.error('Ops', 'Algo de errado aconteceu ao editar o titulo do Bot.');
  }
}

export function* updateBot(action) {
  try {
    if (action.payload.showLoading) {
      yield put({ type: commonActions.LOADING, payload: { loading: true } });
    }

    const apiUrl = `/v1/bots/update/${action.payload.botid}`;

    const { data } = yield call(api.put, apiUrl, action.payload.data);

    yield put({ type: BotsTypes.UPDATE_BOT_SUCCESS, payload: data });

    if (action.payload.showToast) {
      toastr.success(
        'Atualização',
        'Atualizações feitas no bot foram salvas com sucesso!'
      );
    }
  } catch (error) {
    toastr.error('Ops', 'Algo de errado aconteceu atualizando o Bot.');
  } finally {
    if (action.payload.showLoading) {
      yield put({ type: commonActions.LOADING, payload: { loading: false } });
    }
  }
}

export function* updateBotStatus(action) {
  try {
    yield call(
      api.put,
      `/v1/bots/update/${action.payload.botId}`,
      action.payload.data
    );
  } catch (err) {
    toastr.error('Ops', 'Algo de errado aconteceu atualizando o Bot.');
  }
}

export function* updateBotGeneralInformation(action) {
  try {
    const { botId, ...newPayload } = action.payload;
    const formData = new FormData();
    Object.keys(newPayload).forEach((key) => {
      formData.append(key, newPayload[key]);
    });
    const url = `/v1/bots/update-general/${botId}`;
    const { data } = yield call(api.put, url, formData);

    yield put({
      type: BotsTypes.UPDATE_GENERAL_BOT_INFO_SUCCESS,
      payload: { ...data },
    });

    toastr.success('Sucesso', 'Informações do bot atualizadas!');
  } catch (error) {
    toastr.error('Ops', 'Algo de errado ao atualizar o Bot.');
  }
}

export function* openNewBotPage() {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });
    yield put(push(`/novo-bot`));
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* newBot(action) {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });
    const newBotData = {
      ...action.payload,
      description: 'Construção',
      deleted: false,
      isTemplate: false,
    };
    const formData = new FormData();
    Object.keys(newBotData).forEach((key) => {
      formData.append(key, newBotData[key]);
    });
    const { data } = yield call(api.post, '/v1/bots', formData);

    yield put({ type: BotsTypes.RETRIEVE_BOT_SUCCESS, payload: data });
    yield put(BotsCreators.newBotSuccess());
    yield put(push(`/bots/${data._id}/overview`));

    console.log('', 'Bot criado com sucesso!');
  } catch (error) {
    toastr.error('Ops', 'Algo de errado ao criar o Bot.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* newVoiceBot(action) {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const newBotData = { ...action.payload };

    const formData = new FormData();
    Object.keys(newBotData).forEach((key) => {
      formData.append(key, newBotData[key]);
    });

    const { data } = yield call(api.post, '/v1/voice-bots', formData);

    if (data?._id) {
      yield put(push(`/bots/${data._id}/criador`));
    }

    console.log('Voicebot criado com sucesso!');
  } catch (error) {
    toastr.error('Ops', 'Algo de errado ao criar o Voicebot.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* updateVoiceBot(action) {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const { _id, avatarFile, ...restData } = action.payload;

    const jsonData = JSON.stringify(restData);
    const formData = new FormData();
    formData.append('jsonData', jsonData);

    if (avatarFile) {
      formData.append('avatarFile', avatarFile);
    }

    const { data } = yield call(api.put, `/v1/voice-bots/${_id}`, formData);

    yield put({
      type: BotsTypes.UPDATE_VOICE_BOT_SUCCESS,
      payload: { ...data },
    });

    console.log('Voicebot atualizado com sucesso!');
  } catch (error) {
    toastr.error('Ops', 'Algo de errado ao criar o Voicebot.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* removeBot(action) {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    yield call(api.delete, `/v1/bots/${action.payload}`);

    yield put(BotsCreators.removeBotSuccess(action.payload));

    toastr.success('', 'Bot removido com sucesso!');
  } catch (error) {
    toastr.error('Ops', 'Algo de errado aconteceu ao remover o Bot.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* removeVoiceBot(action) {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const botId = action.payload;

    yield call(api.delete, `/v1/voice-bots/${botId}`);

    yield put(BotsCreators.removeBotSuccess(botId));

    toastr.success('', 'Voicebot removido com sucesso!');
  } catch (error) {
    toastr.error('Ops', 'Algo de errado aconteceu ao remover o Voicebot.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* cloneVoiceBot({ payload }) {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const { botId, title, callback } = payload;

    const response = yield call(api.post, `/v1/voice-bots/${botId}/clone`, {
      title,
    });

    yield put(BotsCreators.cloneVoiceBotSuccess(response.data));
    toastr.success('', 'Voicebot clonado com sucesso!');

    if (callback) {
      callback(true);
    }
  } catch (error) {
    toastr.error('Ops', 'Algo de errado aconteceu ao clonar o Voicebot.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* creatorBot(action) {
  try {
    const { data } = yield call(api.get, `/v1/bots/${action.payload}`);

    if (data.ia && data.ia.platforms && data.ia.platforms.length > 0) {
      const findL = data.ia.platforms.find(
        (element) => element.token === data.currentTokenIa
      );
      if (findL) {
        yield put({
          type: IaTypes.IA_PLATFORM_SUCCESS,
          payload: findL.platform,
        });
      }
    }

    yield put({ type: BotsTypes.RETRIEVE_BOT_SUCCESS, payload: data });
    yield put(BotsCreators.creatorBotSuccess(data));

    yield put(push(`/bots/${action.payload}/overview`));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível acessar o Bot.');
  }
}

export function* list() {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const { data } = yield call(api.get, '/v1/bots');
    yield put(BotsCreators.listBotsSuccess(data));
  } catch (error) {
    console.log(error);
    toastr.error('Ops', 'A sessão expirou.');
    yield put(push('/auth'));
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* saveBotViewPosition(action) {
  try {
    yield call(api.put, `/v1/bots/${action.payload.botid}`, {
      viewPosition: action.payload.viewPosition,
    });
    // console.log('', 'Posição de zoom e pan gravado com sucesso!');
  } catch (error) {
    toastr.error(
      'Ops',
      'Algo de errado aconteceu ao salvar a posição da tela / estado do zoom.'
    );
  }
}

export function* publishHtmlWidget(action) {
  const { payload } = action;
  try {
    const { data } = yield call(
      api.post,
      `/v1/bots/${payload.botid}/widget-html`,
      payload
    );
    yield put({
      type: BotsTypes.PUBLISH_WIDGET_HTML_SUCCESS,
      payload: { botId: payload.botid, data: data.data },
    });
  } catch (error) {
    toastr.error('Ops', 'Falha na publicação do widget');
  }
}

export function* saveWidgetHtml(action) {
  const { payload } = action;
  try {
    const { data } = yield call(
      api.put,
      `/v1/bots/${payload.botid}/widget-html-save`,
      { widgetHtml: payload.widgetHtml }
    );
    // console.log('data', data)
    yield put({
      type: BotsTypes.SAVE_WIDGET_HTML_SUCCESS,
      payload: { botId: payload.botid, data, callback: payload.callback },
    });
  } catch (error) {
    toastr.error('Ops', 'Falha na publicação do widget');
  }
}

export function* saveRootItem(action) {
  const { payload } = action;
  try {
    const { data } = yield call(
      api.put,
      `/v1/bots/${payload.botid}/root-item-save`,
      { rootItem: payload.itemid }
    );
    yield put({ type: BotsTypes.SAVE_ROOT_ITEM_SUCCESS, payload: data.bot });

    if (data.item) {
      yield put(
        ItemsCreators.editItemRequest(data.bot._id, data.item._id, {
          ...data.item,
          ancestorItem: data.bot._id,
        })
      );
    }

    yield put(ItemsCreators.listItemsRequest(action.payload.botid));
  } catch (error) {
    console.log(error);
    toastr.error('Ops', 'Falha na atualização do item raiz');
  }
}

export function* updateBotHumanAttendance(action) {
  const { payload } = action;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const { data } = yield call(
      api.post,
      `/v1/bots/${payload.id}/humanAttendence`,
      { ...payload }
    );

    yield put({ type: BotsTypes.RETRIEVE_BOT_SUCCESS, payload: data });

    toastr.success('Atendimento Humano', 'Endpoints salvos com sucesso');
  } catch (error) {
    if (error.response.status === 404) toastr.error('Ops', error.response.data);
    else toastr.error('Ops', 'Falha ao salvar key do atendimento');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* updateZendeskTicketSettings(action) {
  const { botId, settings } = action;

  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const { data } = yield call(
      api.post,
      `/v1/bots/zendes-ticket-settings/${botId}`,
      { settings }
    );

    toastr.success('zendesk tickets', 'Configurações salvas com sucesso');

    yield put({
      type: BotsTypes.RETRIEVE_ZENDESK_TICKET_SETTINGS,
      payload: data,
    });
  } catch (error) {
    if (error.response.status === 404) toastr.error('Ops', error.response.data);
    else toastr.error('Ops', 'Falha ao salvar configurações');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* updateGlpiSettings(action) {
  const { botId, settings } = action;

  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const { data } = yield call(
      api.post,
      `/v1/bots/glpi-settings/${botId}`,
      settings
    );

    toastr.success('GLPi Settings', 'Configurações salvas com sucesso');

    yield put({
      type: BotsTypes.RETRIEVE_GLPI_SETTINGS,
      payload: data,
    });
  } catch (error) {
    if (error.response.status === 404) toastr.error('Ops', error.response.data);
    else toastr.error('Ops', 'Falha ao salvar configurações');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* updateBotQuickAccess(action) {
  const { botId, settings } = action;

  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const { data } = yield call(
      api.post,
      `/v1/bots/quick-access-settings/${botId}`,
      { settings }
    );

    toastr.success('quick access', 'Configurações salvas com sucesso');

    yield put({
      type: BotsTypes.SAVE_QUICK_ACCESS_SETTINGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toastr.error('Ops', 'Falha ao salvar configurações');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* updatePublishBotQuickAccess(action) {
  const { botId, settings } = action;

  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const { data } = yield call(
      api.post,
      `/v1/bots/quick-access-settings/${botId}`,
      { settings }
    );

    yield call(api.post, `/v1/bots/${botId}/publish`);

    yield put({
      type: BotsTypes.SAVE_QUICK_ACCESS_SETTINGS_SUCCESS,
      payload: data,
    });

    toastr.success(
      'quick access',
      'Configurações salvas e publicadas com sucesso'
    );
  } catch (error) {
    toastr.error('Ops', 'Falha ao salvar e publicar configurações');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* updateJiraSettings(action) {
  const { botId, settings } = action;

  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const { data } = yield call(api.post, `/v1/bots/jira-settings/${botId}`, {
      settings,
    });

    toastr.success('Jira tickets', 'Configurações salvas com sucesso');

    yield put({
      type: BotsTypes.RETRIEVE_JIRA_SETTINGS,
      payload: data,
    });
  } catch (error) {
    if (error.response.status === 404) toastr.error('Ops', error.response.data);
    else toastr.error('Ops', 'Falha ao salvar configurações');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* updateAttendanceHoursSettings(action) {
  const { botId, settings } = action;

  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const { data } = yield call(
      api.post,
      `/v1/bots/timezone-settings/${botId}`,
      {
        settings,
      }
    );

    yield put({
      type: BotsTypes.RETRIEVE_ATTENDANCE_HOUR_SETTINGS,
      payload: data,
    });
    toastr.success('Sucesso', 'Horário de atendimento salvo');
  } catch (error) {
    if (error.response.status === 404) toastr.error('Ops', error.response.data);
    else toastr.error('Ops', 'Falha ao salvar configurações');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* getBotQueueHumanAttendance(action) {
  const { payload } = action;
  const { botId, loading } = payload;
  try {
    yield put({ type: commonActions.LOADING, payload: { loading } });
    const { data } = yield call(api.get, `/v1/bots/${botId}/humanAttendence`);

    yield put({
      type: BotsTypes.SET_QUEUE_ATTENDANCE,
      payload: data.map((elem) => {
        return {
          _id: elem._id,
          name: elem.client_name,
          service: elem.service_name,
          apiKey: elem.api_key,
          serviceKey: elem.service_ObjectId,
        };
      }),
    });
  } catch (error) {
    yield put({ type: BotsTypes.SET_QUEUE_ATTENDANCE, payload: [] });
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* updateBotIaStatus(action) {
  const { payload } = action;

  try {
    yield call(api.put, `/v1/bots/${payload.botId}/ia/update`, {
      iaStatus: payload.iaStatus,
    });
    toastr.success('Bot atualizado com sucesso');
  } catch (error) {
    console.log(error);
  }
}

/**
 * Get bot collect data count value
 * @param {Object} action action from redux
 */
export function* getBotCollectDataCount({ payload }) {
  try {
    const { botId, token, startDate, endDate } = payload;
    const url = `/collect-data-count?bot_id=${botId}&token=${token}&start_date=${startDate}&end_date=${endDate}`;

    const { data } = yield call(apiReports.get, url);

    yield put({
      type: BotsTypes.BOT_COLLECT_DATA_COUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toastr.error('Erro', 'Ocorreu um erro inesperado');
    console.log(error);
  }
}

/**
 * Get bot collect data value in a .csv file and save the last api cal status
 * @param {Object} action action from redux
 */
export function* getBotCollectDataCsv({ payload }) {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });
    const { botId, token, showSensitivityData, startDate, endDate } = payload;
    const url = `/v1/report/variable/generate?bot=${botId}&token=${token}`;
    const now = new Date();
    const date = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`;
    const hour = `${now.getHours()}_${now.getMinutes()}`;
    const name = `Dados_coletados_${date}_${hour}`;

    yield call(api.post, url, {
      name,
      bot: botId,
      showSensitivityData,
      start_date: startDate,
      end_date: endDate,
    });

    toastr.success(
      'Sucesso',
      `Relatório criado com sucesso. Download em "Análise → Relatório Customizado"`
    );
  } catch (error) {
    toastr.error('Erro', 'Ocorreu um erro inesperado');
    console.log(error);
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* resetBotCollectCount(action) {
  yield put({
    type: BotsTypes.BOT_COLLECT_DATA_COUNT_RESET_SUCCESS,
    payload: [],
  });
}

export function* publishBot(action) {
  const { botId, isToActive } = action;

  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const { data } = yield call(api.post, `/v1/bots/${botId}/publish`, {
      isActive: isToActive,
    });

    store.dispatch(BotsCreators.countStatusCreatorNoSaveRemove(botId));

    toastr.success('Sucesso', 'A publicação foi realizada com sucesso!!');

    yield put({ type: BotsTypes.PUBLISH_BOT_SUCCESS, payload: data });
  } catch (error) {
    toastr.error('Ops', 'Não foi possível publicar essa versão do seu Bot');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* publishVoiceBot(action) {
  const { botId } = action;

  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const { data } = yield call(
      api.post,
      `/v1/voice-bots/${botId}/publish`,
      {},
      { timeout: 50000 }
    );

    store.dispatch(BotsCreators.countStatusCreatorNoSaveRemove());

    toastr.success('Sucesso', 'A publicação foi realizada com sucesso!!');

    yield put({ type: BotsTypes.PUBLISH_BOT_SUCCESS, payload: data });
  } catch (error) {
    toastr.error('Ops', 'Não foi possível publicar essa versão do seu Bot');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* changeStagingArea(action) {
  const { botId, chosenVersion } = action;

  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const { data } = yield call(api.put, `/v1/bots/${botId}/stagingArea`, {
      version: chosenVersion.id,
    });

    yield put(ConnectorsCreators.getConnectorsSuccess(data.connectors));
    yield put(ItemsCreators.listItemsSuccess(data));
    store.dispatch(BotsCreators.countStatusCreatorNoSaveRemove());

    toastr.success(
      'Sucesso',
      `Você está acessando a versão publicada em ${moment(
        chosenVersion.createDate
      ).format('DD/MM/YYYY - HH:mm')}`
    );
  } catch (error) {
    toastr.error('Ops', 'Não foi possível abrir a versão escolhida');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* countStatusCreatorNoSave() {
  try {
    yield put({
      type: BotsTypes.COUNT_STATUS_CREATOR_NO_SAVE_SUCCESS,
      payload: store.getState().bots.bot?.countUpdatesNoPublish + 1,
    });
  } catch (error) {
    toastr.error('Ops', error.message);
  }
}

export function* countStatusCreatorNoSaveRemove() {
  try {
    // const { data } = yield call(api.get, `/v1/bots/${botId}`);
    yield put({
      type: BotsTypes.COUNT_STATUS_CREATOR_NO_SAVE_REMOVE_SUCCESS,
      payload: 0,
    });
  } catch (error) {
    toastr.error('Ops', error.message);
  }
}
