import { call, put } from 'redux-saga/effects';
import store from '..';

import { BotsTypes, BotsCreators } from '../actions/bots';
import { DebounceTypes } from '../actions/debounce';

import api from '../../services/api';

const executeFunction = {
  ADD_ITEM: addItem,
  UPDATE_ITEM: updateItem,
  REMOVE_ITEM: removeItem,
  CLONE_ITEM: cloneItem,
  ADD_CONNECTOR: addConnector,
  REMOVE_CONNECTOR: removeConnector,
};

export function* executeDebounce(action) {
  const { payload } = action;

  for (let i = 0; i < payload.length; ++i) {
    const command = payload[i];
    yield call(executeFunction[command.type], command);
    yield put({
      type: DebounceTypes.DELETE_ITEM_DEBOUNCE,
      payload: payload[i],
    });
    // Atualiza a data em que o bot foi atualizado em seu criador
    if (i === payload.length - 1) {
      store.dispatch(BotsCreators.countStatusCreatorNoSave());

      yield updateBotDate(new Date(), command.payload.botId);
      yield put({ type: BotsTypes.BOT_UPDATE_DATE, payload: new Date() });
    }
  }
}

async function updateBotDate(date, botId) {
  await api.put(`/v1/bots/update/${botId}`, { lastUpdate: date });
}

async function addItem(args) {
  const { payload } = args;
  await api.post(`/v1/bots/${payload.botId}/items`, payload);
}

async function updateItem(args) {
  const { payload } = args;
  await api.put(`/v1/bots/${payload.botId}/items/${payload._id}`, payload);
}

async function removeItem(args) {
  const { payload } = args;
  await api.delete(`/v1/bots/${payload.botId}/items/${payload._id}`);
}

async function cloneItem(args) {
  const { payload } = args;
  await api.post(`/v1/bots/${payload.botId}/items`, payload);
}

async function addConnector(args) {
  const { payload } = args;
  await api.post(`/v1/bots/${payload.botId}/connectors`, payload);
}

async function removeConnector(args) {
  const { payload } = args;
  await api.delete(`/v1/bots/${payload.botId}/connectors/${payload.id}`);
}
