import { call, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { AxiosWithoutInterceptors as api } from '../../services/api';
import { getRegistersSuccess } from '../actions/register-from';
import { commonLoadingStart, commonLoadingFinish } from '../actions/common';

export function* getRegisters(action) {
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(api.get, '/v1/register-from');

    yield put(getRegistersSuccess(data));
  } catch (error) {
    console.error(error);
    toastr.error('Erro', 'Ocorreu um erro ao buscar os projetos');
  } finally {
    yield put(commonLoadingFinish());
  }
}
