import { combineReducers } from 'redux';
import actions from '../actions';
import { API_TOKEN } from '../../services/api';

const initialState = {
  isLoggedIn: localStorage.getItem(API_TOKEN) !== null,
  lastNotice: '',
  resendConfirmationEmail: null,
};

export const authStatus = (state = initialState, action) => {
  switch (action.type) {
    case actions.AUTH_LOGIN:
      return {
        ...state,
        isLoggedIn: true,
      };
    case actions.AUTH_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      };
    case actions.AUTH_NOTICE:
      return {
        ...state,
        isLoggedIn: true,
        lastNotice: action.payload.notice,
      };
    case actions.AUTH_RESEND_EMAIL_CONFIRMATION_SUCCESS:
      console.log(action.payload);
      return { ...state, resendConfirmationEmail: action.payload };
    default:
      return state;
  }
};
const inicialStateTfa = {
  active: false,
  dataURL: '',
  message: '',
  error: '',
};

export const tfaStatus = (state = inicialStateTfa, action) => {
  switch (action.type) {
    case actions.TFA_SETUP_SUCCESS:
      return {
        ...state,
        dataURL: action.payload.dataURL,
      };
    case actions.TFA_ACTIVE_SUCCESS:
      return {
        ...state,
        message: action.payload?.message || '',
        error: action.payload?.error || '',
      };
    case actions.TFA_DISABLE_SUCCESS:
      return {
        ...state,
        message: action.payload?.message,
      };
    case actions.TFA_VERIFY_SUCCESS:
      return {
        ...state,
        token: action.payload?.token,
      };
    default:
      return state;
  }
};

export default combineReducers({
  authStatus,
  tfaStatus,
});
