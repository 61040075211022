import { call, put, fork } from 'redux-saga/effects';
import { Types } from 'mongoose';
import { commonActions } from '../../store/actions/common';

import { toastr } from 'react-redux-toastr';

import api from '../../services/api';

import { ConnectorsCreators } from '../actions/connectors';
import { DebounceCreators } from '../actions/debounce';

export function* createConnector(action) {
  try {
    const { from, to, botId, subflowFor } = action.payload;
    const connector = {
      _id: Types.ObjectId().toString(),
      from,
      to,
      botId,
      ...(subflowFor && { subflowFor }),
    };
    yield put(
      DebounceCreators.addItemDebounce({
        type: 'ADD_CONNECTOR',
        payload: connector,
      })
    );
    yield put(ConnectorsCreators.createConnectorsSuccess(connector));
  } catch (err) {
    toastr.error('Ops', 'Algo de errado aconteceu ao criar um novo conector.');
  }
}

export function* createVoiceConnector(action) {
  try {
    const { from, to, botId, voiceSettings } = action.payload;
    const connector = {
      _id: Types.ObjectId().toString(),
      from,
      to,
      botId,
      ...(voiceSettings && { voiceSettings }),
    };

    yield put(ConnectorsCreators.createConnectorsSuccess(connector));

    yield call(api.post, `/v1/voice-bots/${botId}/connectors`, connector);
  } catch (err) {
    toastr.error('Ops', 'Algo de errado aconteceu ao criar um novo conector.');
  }
}

export function* deleteConnector(action) {
  try {
    const { id, botId } = action.payload;
    yield put(
      DebounceCreators.addItemDebounce({
        type: 'REMOVE_CONNECTOR',
        payload: { id, botId },
      })
    );
    yield put(ConnectorsCreators.deleteConnectorSuccess(id, botId));
  } catch (err) {
    toastr.error('Ops', 'Algo de errado aconteceu ao criar um novo conector.');
  }
}

export function* getConnectors(action) {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const response = yield call(
      api.get,
      `/v1/bots/${action.payload.botId}/connectors`
    );
    const { connectors } = response.data;
    yield put(ConnectorsCreators.getConnectorsSuccess(connectors));
  } catch (err) {
    toastr.error('Ops', 'Algo de errado aconteceu ao buscar os conectores.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* getConnectorsSubflow(action) {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });
    const { botId, itemId } = action.payload;
    const url = `/v1/bots/${botId}/connectors-subflow/${itemId}`;

    const response = yield call(api.get, url);
    const { connectors } = response.data;
    yield put(ConnectorsCreators.getConnectorsSuccess(connectors));
  } catch (err) {
    toastr.error('Ops', 'Algo de errado aconteceu ao buscar os conectores.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* getAllConnectors(action) {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });
    const { botId } = action.payload;
    const url = `/v1/bots/${botId}/all-connectors`;

    const { data } = yield call(api.get, url);
    yield put(ConnectorsCreators.getAllConnectorsSuccess(data));
  } catch (err) {
    toastr.error('Ops', 'Algo de errado aconteceu ao buscar os conectores.');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}
