import { ItemsTypes } from '../actions/items';

/* Reducer */

const INITIAL_STATE = {
  loading: false,
  list: [],
  currentItem: null,
  currentPath: null,
  contextMenuOption: null,
  showRightMenu: false,
  currentSelectedItem: null,
  currentClickItem: null,
  httpHeaders: [],
  variables: [],
  itemsToRemove: [],
  connectorsToRemove: [],
  activeIaOnItems: true,
  currentFlow: { title: '', id: '' },
  allItems: [],
  selectedMenuOption: null,
};

export function getListItems(state) {
  return state.items.list;
}

export function getVariables(state) {
  return state.items.variables;
}

export default function items(state = INITIAL_STATE, action) {
  let itemIndex = -1;
  let newList = [];

  switch (action.type) {
    case ItemsTypes.CREATE_ITEM_REQUEST:
      return { ...state, loading: true };
    case ItemsTypes.CREATE_ITEM_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.payload],
        showRightMenu: true,
        currentItem: action.payload,
        currentSelectedItem: action.payload,
        currentClickItem: action.payload._id,
        loading: false,
      };
    case ItemsTypes.UPDATE_SELECTED_ITEM:
      return {
        ...state,
        showRightMenu: action.payload !== undefined && action.payload !== null,
        currentSelectedItem: action.payload,
        currentClickItem: action.payload ? action.payload._id : '',
      };
    case ItemsTypes.UPDATE_ITEM_IN_LIST_STATE: {
      const updatedItem = action.payload.item;
      const newItemList = state.list.map((_item) =>
        _item._id === updatedItem._id ? updatedItem : _item
      );

      return {
        ...state,
        list: newItemList,
      };
    }
    case ItemsTypes.REMOVE_ITEM_IN_LIST_STATE: {
      const removeItem = action.payload.item;
      const newItemList = state.list.filter(
        (_item) => _item._id !== removeItem._id
      );

      return {
        ...state,
        list: newItemList,
      };
    }
    case ItemsTypes.REMOVE_ITEM_REQUEST:
    case ItemsTypes.REMOVE_VOICE_ITEM_REQUEST:
      return { ...state, loading: true, showRightMenu: false };
    case ItemsTypes.CLONE_ITEM_REQUEST:
      return { ...state, loading: true };
    case ItemsTypes.REMOVE_ITEM_SUCCESS:
      return {
        ...state,
        list: state.list.filter((item) => item._id !== action.payload),
        loading: false,
      };
    case ItemsTypes.LIST_ITEMS_REQUEST:
      return { ...state, loading: true };
    case ItemsTypes.LIST_ITEMS_SUCCESS: {
      const variables = [];
      for (const item of action.payload.items) {
        if (item.variable) {
          variables.push({ id: item._id, variable: item.variable });
        }
      }

      if (action.payload.bot && action.payload.bot.rootItem) {
        action.payload.items.push({
          positionOnScreen: [500, 50],
          root: true,
          _id: 'builder__root',
          title: 'Ponto inicial',
        });
      }

      const itemsCopy = [...action.payload.items].filter(
        (i) => state.itemsToRemove.find((ii) => ii._id === i._id) === undefined
      );

      return {
        ...state,
        list: itemsCopy,
        loading: false,
        showRightMenu: false,
        variables,
      };
    }
    case ItemsTypes.SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: { ...action.payload },
        currentPath: null,
      };
    case ItemsTypes.SET_CURRENT_PATH:
      return {
        ...state,
        currentItem: null,
        currentPath: action.payload,
        showRightMenu: action.payload !== undefined && action.payload !== null,
      };
    case ItemsTypes.SET_SELECTED_MENU_OPTION:
      return {
        ...state,
        selectedMenuOption: action.payload,
      };
    case ItemsTypes.EDIT_ITEM: {
      let newVariables = [...state.variables];
      if (action.payload.variable) {
        newVariables = newVariables.filter((i) => i.id !== action.payload._id);
        newVariables.push({
          id: action.payload._id,
          variable: action.payload._id,
        });
      }
      itemIndex = state.list.findIndex((l) => l._id === action.payload._id);

      const currentList = state.list;

      if (itemIndex >= 0) {
        if (currentList[itemIndex]) {
          currentList[itemIndex].uploading = false;
        }
        currentList[itemIndex] = action.payload;
      }

      return {
        ...state,
        loading: true,
        list: currentList,
        variables: newVariables,
        currentItem: action.payload,
      };
    }
    case ItemsTypes.EDIT_URL_ITEM_REQUEST:
      itemIndex = state.list.findIndex((l) => l._id === action.payload.itemid);
      newList = [...state.list];

      if (itemIndex >= 0) {
        newList[itemIndex].uploading = true;
        newList[itemIndex].upload_progress = 0;
      }

      return {
        ...state,
        loading: true,
        list: newList,
      };
    case ItemsTypes.EDIT_ITEM_UPLOAD_PROGRESS:
      itemIndex = state.list.findIndex(
        (l) => l._id === action.payload.item._id
      );
      newList = [...state.list];
      console.log(itemIndex, action.progress);
      if (itemIndex >= 0) {
        newList[itemIndex].upload_progress = action.progress;
      }

      return {
        ...state,
        loading: true,
        list: newList,
      };
    case ItemsTypes.EDIT_FILE_ITEM_REQUEST:
      itemIndex = state.list.findIndex((l) => l._id === action.payload._id);
      newList = [...state.list];

      if (itemIndex >= 0) {
        newList[itemIndex].uploading = true;
        newList[itemIndex].upload_progress = 0;
      }

      return {
        ...state,
        loading: true,
        list: newList,
      };

    case ItemsTypes.REMOVE_FILE_ITEM_REQUEST:
      itemIndex = state.list.findIndex((l) => l._id === action.payload._id);
      newList = [...state.list];

      if (itemIndex >= 0) {
        newList[itemIndex].uploading = false;
        newList[itemIndex].upload_progress = 100;
      }

      return {
        ...state,
        loading: false,
        list: newList,
      };

    case ItemsTypes.ITEM_CHANGE_CURRENT_FLOW:
      return { ...state, currentFlow: action.payload };
    case ItemsTypes.LIST_ALL_ITEMS_SUCCESS:
      return { ...state, allItems: action.payload };
    case ItemsTypes.UPDATE_ALL_ITEMS: {
      const { allItems } = state;
      const { item, isRemove } = action.payload;
      const { _id: newItemId } = item;
      const newAllItems = [...allItems];

      if (!isRemove) {
        const getItem = newAllItems.find(({ _id }) => _id === newItemId);
        if (!getItem) {
          newAllItems.push(item);
        } else {
          const index = newAllItems.indexOf(getItem);
          newAllItems[index] = { ...item };
        }
        return { ...state, allItems: newAllItems };
      }

      return {
        ...state,
        allItems: newAllItems.filter(({ _id }) => _id !== newItemId),
      };
    }

    case ItemsTypes.RESET_ITEMS:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
