import { call, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import api from '../../services/api';
import { TriggerActions } from '../actions/triggers';

export function* load(action) {
  const { payload } = action;
  try {
    const response = yield call(
      api.get,
      `/v1/bots/escape/list/${payload.botId}`
    );
    yield put(TriggerActions.loadSucces(response.data));
  } catch (error) {
    toastr.error('Não foi possivel carregar seus gatilhos de rota de fuga');
  }
}

export function* save(action) {
  const { payload } = action;

  try {
    yield call(api.put, `/v1/bots/escape/update/${payload.botId}`, {
      triggers: payload.triggers,
      maxTimeWithoutResponse: payload.maxTimeWithoutResponse,
    });
    toastr.success('Sucesso', 'Seu bot foi salvo');
  } catch (error) {
    toastr.error('Não foi possivel salvar o seu bot');
  }
}
