import * as authActions from './auth';
import * as commonActions from './common';
import * as previewBotActions from './preview-bot';
import * as itemsActions from './items';
import * as botsActions from './bots';
import * as companyActions from './companies';
import * as userActions from './users';
import * as IaActions from './intelligence';
import * as permissionActions from './permissions';
import * as connectionActions from './connectors';
import * as filterActions from './filter';
import * as debounceActions from './debounce';
import * as reportsActions from './reports';
import * as facebookMessengerActions from './facebook-messenger';
import * as variablesActions from './variables';
import * as tagsActions from './tags';
import * as notificationActions from './notifications';
import * as templateBotActions from './template-bot';
import * as newBotActions from './new-bot';
import * as botCreatorActions from './bot-creator';
import * as triggerActions from './triggers';
import * as voiceBotsActions from './voice-bots';
import * as voiceBotsTtsActions from './voice-bots-tts';
import * as configPage from './page-config';
import * as iaReportsActions from './iaReports';
import * as recaptchaActions from './reCaptcha';
import * as registerFromActions from './register-from';
import * as rdStationActions from './rd-station';

export default {
  ...authActions,
  ...commonActions,
  ...previewBotActions,
  ...itemsActions,
  ...botsActions,
  ...IaActions,
  ...companyActions,
  ...userActions,
  ...permissionActions,
  ...connectionActions,
  ...filterActions,
  ...debounceActions,
  ...reportsActions,
  ...variablesActions,
  ...facebookMessengerActions,
  ...tagsActions,
  ...notificationActions,
  ...templateBotActions,
  ...newBotActions,
  ...botCreatorActions,
  ...triggerActions,
  ...voiceBotsActions,
  ...voiceBotsTtsActions,
  ...configPage,
  ...iaReportsActions,
  ...recaptchaActions,
  ...registerFromActions,
  ...rdStationActions,
};
