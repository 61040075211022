export const VariableTypes = {
  LIST: 'variable/LIST',
  LIST_SUCCESS: 'variable/LIST_SUCCESS',
  CREATE: 'variable/CREATE',
  CREATE_SUCCESS: 'variable/CREATE_SUCCESS',
  EDIT: 'variable/EDIT',
  EDIT_SUCCESS: 'variable/EDIT_SUCCESS',
  REMOVE: 'variable/REMOVE',
  REMOVE_SUCCESS: 'variable/REMOVE_SUCCESS',
};

export const VariableActions = {
  list: ({ botId }) => {
    return {
      type: VariableTypes.LIST,
      payload: { botId },
    };
  },

  create: (data) => {
    return {
      type: VariableTypes.CREATE,
      payload: data,
    };
  },

  edit: (data) => {
    return {
      type: VariableTypes.EDIT,
      payload: data,
    };
  },

  remove: ({ id }) => {
    return {
      type: VariableTypes.REMOVE,
      payload: { id },
    };
  },
};
