import queryString from 'query-string';
import { call, put, select } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import history from '../../routes/history';
import { commonActions } from '../actions/common';
import api from '../../services/api';
import { CompanyTypes } from '../actions/companies';
import { UserActions } from '../actions/users';

export function* getCompany(action) {
  try {
    const { payload } = action;
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const { data } = yield call(api.get, `v1/companies/${payload.companyId}`);

    yield put({
      type: CompanyTypes.GET_COMPANY_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error(e);
    toastr.error('Erro', 'Ocorreu um erro ao buscar a empresa');
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* changePlan(action) {
  try {
    const { payload } = action;

    const { data } = yield call(api.post, `v1/companies/updatePlan`, payload);
    yield put({
      type: CompanyTypes.SET_PLAN_COMPANY_SUCCESS,
      payload: data,
    });
    toastr.success('Sucesso!', 'Plano modificado');
  } catch (e) {
    console.error(e);
    if (e?.response?.data) {
      toastr.error('Erro', e.response.data.message);
    } else {
      toastr.error('Erro', 'Ocorreu um erro ao trocar de plano');
    }
  }
}

export function* createAdditionalMessage(action) {
  const { payload } = action;
  try {
    const { data } = yield call(
      api.post,
      `v1/companies/addAdditionalMessage`,
      payload
    );

    yield put({
      type: CompanyTypes.SET_PLAN_COMPANY_SUCCESS,
      payload: data,
    });

    toastr.success('Sucesso', 'Mensagem adicional salva com sucesso');
  } catch (e) {
    console.error(e);
    toastr.error('Erro', 'Ocorreu um erro ao atualizar o alerta');
  }
}

export function* createNewPlan(action) {
  const { payload } = action;
  try {
    const { data } = yield call(
      api.post,
      `v1/companies/createNewPlan`,
      payload
    );

    yield put({
      type: CompanyTypes.SET_PLAN_COMPANY_SUCCESS,
      payload: data,
    });
    toastr.success('Sucesso!', 'Plano modificado');
  } catch (e) {
    console.error(e);
    toastr.error('Erro', 'Ocorreu um erro ao atualizar o alerta');
  }
}

export function* updatePlan(action) {
  try {
    yield put({ type: commonActions.LOADING, payload: { loading: true } });

    const { payload } = action;

    const { data } = yield call(api.post, `v1/companies/updatePlan`, payload);

    yield put({
      type: CompanyTypes.GET_COMPANY_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error(e);
    if (e?.response?.data) {
      toastr.error('Erro', e.response.data.message);
    } else {
      toastr.error('Erro', 'Ocorreu um erro ao modificar o plano');
    }
  } finally {
    yield put({ type: commonActions.LOADING, payload: { loading: false } });
  }
}

export function* getCompanies(action) {
  try {
    const params =
      action && action.payload && action.payload.searchTerm
        ? `?term=${action.payload.searchTerm}`
        : '';
    const { data } = yield call(api.get, `v1/companies${params}`);
    yield put({
      type: CompanyTypes.GET_COMPANIES_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log('getCompanies erro: ', e);
    toastr.error('Erro', 'Ocorreu um erro obtendo as empresas.');
  }
}

export function* createCompany(action) {
  try {
    const { data } = yield call(api.post, `v1/companies`, action.payload);
    yield put({
      type: CompanyTypes.CREATE_COMPANY_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({ type: CompanyTypes.GET_COMPANIES_REQUEST });
  } catch (e) {
    console.log('createCompany erro: ', e);
    toastr.error('Erro', 'Ocorreu um erro criando uma empresa.');
  }
}

export function* updateCompany(action) {
  try {
    const { data } = yield call(
      api.put,
      `v1/companies/${action.payload._id}`,
      action.payload
    );
    yield put({
      type: CompanyTypes.UPDATE_COMPANY_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({ type: CompanyTypes.GET_COMPANIES_REQUEST });
    toastr.success('Sucesso', 'Dados atualizados!');
  } catch (e) {
    console.log('updateCompany erro: ', e);
    toastr.error('Erro', 'Ocorreu um erro atualizando uma empresa.');
  }
}

export function* updateSelectedCompany(action) {
  try {
    const { data } = yield call(
      api.put,
      `v1/companies/${action.payload._id}`,
      action.payload
    );

    const signedUser = yield select((state) => state.users.signedUser);
    const newSignedUserState = { ...signedUser, selectedCompany: data };

    yield put({
      type: CompanyTypes.UPDATE_SELECTED_COMPANY_SUCCESS,
      payload: data,
    });

    yield put(UserActions.updateSignedUserState(newSignedUserState));

    toastr.success('Sucesso', 'Dados atualizados!');
  } catch (e) {
    console.log('updateSelectedCompany erro: ', e);
    toastr.error('Erro', 'Ocorreu um erro atualizando uma empresa.');
  }
}

export function* getOrganizations(action) {
  try {
    const params = queryString.stringify(action.payload, {
      skipNull: true,
    });

    const url = `v1/companies/organization?${params}`;

    const { data } = yield call(api.get, url);

    yield put({ type: CompanyTypes.GET_ORGANIZATIONS_SUCCESS, payload: data });
  } catch (e) {
    console.log('getOrganizations erro: ', e);
    toastr.error('Erro', 'Ocorreu um erro obtendo as organizações.');
  }
}

export function* createOrganization(action) {
  try {
    yield call(api.post, `v1/companies/organization`, action.payload);
    yield put({ type: CompanyTypes.GET_ORGANIZATIONS });
  } catch (e) {
    console.log('createOrganization erro: ', e);
    toastr.error('Erro', 'Ocorreu um erro criando organização.');
  }
}

export function* removeOrganization(action) {
  try {
    yield call(api.delete, `/v1/organizations/delete/${action.payload}`);
    yield put({ type: CompanyTypes.GET_ORGANIZATIONS });
    toastr.success('Sucesso', 'Organização excluida com sucesso.');
  } catch (e) {
    console.log('createOrganization erro: ', e);
    toastr.error('Erro', 'Você não pode excluir uma empresa com bots ativos.');
  }
}

export function* getHistoryActions(action) {
  const { companyId, ...parameters } = action.payload;

  try {
    const queryUrl = Object.keys(parameters)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(parameters[key])}`
      )
      .join('&');

    const { data } = yield call(
      api.get,
      `/v1/companies/historyActions/${companyId}${
        !queryUrl ? '' : `?${queryUrl}`
      }`
    );

    yield put({
      type: CompanyTypes.GET_HISTORY_ACTIONS_SUCCESS,
      payload: data,
    });
    toastr.success('Sucesso', 'Operação realizada com sucesso');
  } catch (e) {
    console.log(e);
    toastr.error('Erro', 'Não foi possível recuperar dados da auditoria');
  }
}

export function* updateOrganization({ payload }) {
  const {
    companyId,
    organizationId,
    updateData,
    successMessage,
    errorMessage,
  } = payload;

  try {
    const { data } = yield call(
      api.put,
      `v1/companies/organization/${organizationId}`,
      updateData
    );

    yield put({
      type: CompanyTypes.GET_ORGANIZATIONS,
      payload: { companyId },
    });
    toastr.success('Sucesso', successMessage || '');
    history.push('/home');
  } catch (error) {
    console.log(error);
    toastr.error('Erro', errorMessage || '');
  }
}
