export const facebookMessengerTypes = {
  SET_FB_AUTH_STATE: 'SET_FB_AUTH_STATE',
  SET_FB_AUTH_STATE_SUCCESS: 'SET_FB_AUTH_STATE_SUCCESS',
  SET_FB_SELECTED_PAGE: 'SET_FB_SELECTED_PAGE',
  SET_FB_SELECTED_PAGE_SUCCESS: 'SET_FB_SELECTED_PAGE_SUCCESS',
};

export const setAuthState = (newAuthState) => ({
  type: facebookMessengerTypes.SET_FB_AUTH_STATE,
  payload: { ...newAuthState },
});

export const setSelectedFacebookPage = (newSelectedFacebookPage) => ({
  type: facebookMessengerTypes.SET_FB_SELECTED_PAGE,
  payload: { ...newSelectedFacebookPage },
});
