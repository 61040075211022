import { facebookMessengerTypes } from '../actions/facebook-messenger';

const INITIAL_STATE = {
  facebookAuth: {
    id: '',
    userID: '',
    accessToken: '',
    name: '',
    expiresIn: 0,
    picture: {
      data: {
        height: 0,
        width: 0,
        url: '',
      },
    },
  },
  selectedFacebookPage: {},
};

export default function facebookMessenger(state = INITIAL_STATE, action) {
  switch (action.type) {
    case facebookMessengerTypes.SET_FB_AUTH_STATE_SUCCESS:
      return { ...state, facebookAuth: action.payload };
    case facebookMessengerTypes.SET_FB_SELECTED_PAGE_SUCCESS:
      return { ...state, selectedFacebookPage: action.payload };
    default:
      return state;
  }
}
