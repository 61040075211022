import { PlanTypes } from '../actions/plans';

/* Reducer */

const INITIAL_STATE = {
  list: [],
  historyCompanyPlan: [],
  alerts: {},
  planConsumption: {},
};

export default function plans(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PlanTypes.GET_PLANS_REQUEST_SUCCESS:
      return { ...state, list: action.payload };
    case PlanTypes.GET_HISTORY_COMPANY_PLAN_REQUEST_SUCCESS:
      return { ...state, historyCompanyPlan: action.payload };
    case PlanTypes.PUT_ALERTS_SUCCESS:
      return { ...state, alerts: action.payload };
    case PlanTypes.GET_PLAN_CONSUMPTION_SUCCESS:
      return { ...state, planConsumption: action.payload };
    default:
      return state;
  }
}
