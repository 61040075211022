export const commonActions = {
  LOADING: 'common/LOADING',
  LOADING_START: 'common/LOADING_START',
  LOADING_FINISH: 'common/LOADING_FINISH',
};

export const setLoading = (loading) => ({
  type: commonActions.LOADING,
  payload: {
    loading,
  },
});

export const commonLoadingStart = () => ({
  type: commonActions.LOADING_START,
});
export const commonLoadingFinish = () => ({
  type: commonActions.LOADING_FINISH,
});
