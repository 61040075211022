export const ConfigPageTypes = {
    SET_MENU_PAGE: 'page-config/SET_MENU_PAGE',
    SET_MENU_PAGE_SUCCESS:
      'bot-creator/SET_MENU_PAGE_SUCCESS',
    SET_SUB_MENU_PAGE: 'bot-creator/SET_SUB_MENU_PAGE',
    SET_SUB_MENU_PAGE_SUCCESS:
      'bot-creator/SET_SUB_MENU_PAGE_SUCCESS',
  };
  
  export const setPageConfig = (payload) => ({
    type: ConfigPageTypes.SET_MENU_PAGE,
    payload,
  });
  
  export const setSubPageConfig = (payload) => ({
    type: ConfigPageTypes.SET_SUB_MENU_PAGE,
    payload,
  });
  