export const ReportsTypes = {
  GET_CLIENTS_REPORTS: 'bot/GET_CLIENTS_REPORTS',
  GET_CLIENTS_REPORTS_SUCCESS: 'bot/GET_CLIENTS_REPORTS_SUCCESS',
  GET_CLIENTS_REPORTS_GRAPHIC: 'bot/GET_CLIENTS_REPORTS_GRAPHIC',
  GET_CLIENTS_REPORTS_GRAPHIC_SUCCESS:
    'bot/GET_CLIENTS_REPORTS_GRAPHIC_SUCCESS',
  GET_SESSIONS_REPORTS: 'bot/GET_SESSIONS_REPORTS',
  GET_SESSIONS_REPORTS_SUCCESS: 'bot/GET_SESSIONS_REPORTS_SUCCESS',
  GET_SESSIONS_REPORTS_GRAPHIC: 'bot/GET_SESSIONS_REPORTS_GRAPHIC',
  GET_SESSIONS_REPORTS_GRAPHIC_SUCCESS:
    'bot/GET_SESSIONS_REPORTS_GRAPHIC_SUCCESS',
  GET_ATTENDANCE_TIME_REPORTS: 'bot/GET_ATTENDANCE_TIME_REPORTS',
  GET_ATTENDANCE_TIME_REPORTS_SUCCESS:
    'bot/GET_ATTENDANCE_TIME_REPORTS_SUCCESS',
  GET_ATTENDANCE_TIME_REPORTS_GRAPHIC:
    'bot/GET_ATTENDANCE_TIME_REPORTS_GRAPHIC',
  GET_ATTENDANCE_TIME_REPORTS_GRAPHIC_SUCCESS:
    'bot/GET_ATTENDANCE_TIME_REPORTS_GRAPHIC_SUCCESS',
  GET_AMOUNT_MESSAGE_CHANNEL_REPORTS: 'bot/GET_AMOUNT_MESSAGE_CHANNEL_REPORTS',
  GET_AMOUNT_MESSAGE_CHANNEL_REPORTS_SUCCESS:
    'bot/GET_AMOUNT_MESSAGE_CHANNEL_REPORTS_SUCCESS',
  GET_TOTAL_MESSAGE_CHANNEL_REPORTS: 'bot/GET_TOTAL_MESSAGE_CHANNEL_REPORTS',
  GET_TOTAL_MESSAGE_CHANNEL_REPORTS_SUCCESS:
    'bot/GET_TOTAL_MESSAGE_CHANNEL_REPORTS_SUCCESS',
  GET_BOT_MESSAGES: 'bot/GET_BOT_MESSAGES',
  GET_BOT_MESSAGES_SUCCESS: 'bot/GET_BOT_MESSAGES_SUCCESS',
  GET_BOT_MESSAGES_AVG: 'bot/GET_BOT_MESSAGES_AVG',
  GET_BOT_MESSAGES_AVG_SUCCESS: 'bot/GET_BOT_MESSAGES_AVG_SUCCESS',
  GET_ORG_BOTS_COUNT: 'bot/GET_ORG_BOTS_COUNT',
  GET_ORG_BOTS_COUNT_SUCCESS: 'bot/GET_ORG_BOTS_COUNT_SUCCESS',
  GET_ORG_BOTS_GRAPHIC: 'bot/GET_ORG_BOTS_GRAPHIC',
  GET_ORG_BOTS_GRAPHIC_SUCCESS: 'bot/GET_ORG_BOTS_GRAPHIC_SUCCESS',
  GET_ORG_TOTAL_MESSAGES: 'bot/GET_ORG_TOTAL_MESSAGES',
  GET_ORG_TOTAL_MESSAGES_SUCCESS: 'bot/GET_ORG_TOTAL_MESSAGES_SUCCESS',
  GET_ORG_TOTAL_MESSAGES_GRAPHIC: 'bot/GET_ORG_TOTAL_MESSAGES_GRAPHIC',
  GET_ORG_TOTAL_MESSAGES_GRAPHIC_SUCCESS:
    'bot/GET_ORG_TOTAL_MESSAGES_GRAPHIC_SUCCESS',
  GET_ORG_TOTAL_SESSIONS: 'bot/GET_ORG_TOTAL_SESSIONS',
  GET_ORG_TOTAL_SESSIONS_SUCCESS: 'bot/GET_ORG_TOTAL_SESSIONS_SUCCESS',
  GET_ORG_TOTAL_SESSIONS_GRAPHIC: 'bot/GET_ORG_TOTAL_SESSIONS_GRAPHIC',
  GET_ORG_TOTAL_SESSIONS_GRAPHIC_SUCCESS:
    'bot/GET_ORG_TOTAL_SESSIONS_GRAPHIC_SUCCESS',
  GET_ORG_TOTAL_USERS: 'bot/GET_ORG_TOTAL_USERS',
  GET_ORG_TOTAL_USERS_SUCCESS: 'bot/GET_ORG_TOTAL_USERS_SUCCESS',
  GET_ORG_TOTAL_USERS_GRAPHIC: 'bot/GET_ORG_TOTAL_USERS_GRAPHIC',
  GET_ORG_TOTAL_USERS_GRAPHIC_SUCCESS:
    'bot/GET_ORG_TOTAL_USERS_GRAPHIC_SUCCESS',
  GET_ORG_LIST_USERS: 'bot/GET_ORG_LIST_USERS',
  GET_ORG_LIST_USERS_SUCCESS: 'bot/GET_ORG_LIST_USERS_SUCCESS',
  GET_ORG_MESSAGES_BY_CHANNEL: 'bot/GET_ORG_MESSAGES_BY_CHANNEL',
  GET_ORG_MESSAGES_BY_CHANNEL_SUCCESS:
    'bot/GET_ORG_MESSAGES_BY_CHANNEL_SUCCESS',
  GET_ORG_MESSAGES_BY_CHANNEL_GRAPHIC:
    'bot/GET_ORG_MESSAGES_BY_CHANNEL_GRAPHIC',
  GET_ORG_MESSAGES_BY_CHANNEL_GRAPHIC_SUCCESS:
    'bot/GET_ORG_MESSAGES_BY_CHANNEL_GRAPHIC_SUCCESS',
  GET_ORG_USERS_BOTS: 'bot/GET_ORG_USERS_BOTS',
  GET_ORG_USERS_BOTS_SUCCESS: 'bot/GET_ORG_USERS_BOTS_SUCCESS',
  GET_ORG_USERS_BOTS_AVERAGE: 'bot/GET_ORG_USERS_BOTS_AVERAGE',
  GET_ORG_USERS_BOTS_AVERAGE_SUCCESS: 'bot/GET_ORG_USERS_BOTS_AVERAGE_SUCCESS',
  CREATE_DASHBOARD_REPORT: 'CREATE_DASHBOARD_REPORT',
  CREATE_DASHBOARD_REPORT_SUCCESS: 'CREATE_DASHBOARD_REPORT_SUCCESS',
  GET_DASHBOARD_REPORT: 'GET_DASHBOARD_REPORT',
  GET_DASHBOARD_REPORT_SUCCESS: 'GET_DASHBOARD_REPORT_SUCCESS',
  UPDATE_DASHBOARD_REPORT: 'UPDATE_DASHBOARD_REPORT',
  UPDATE_DASHBOARD_REPORT_SUCCESS: 'UPDATE_DASHBOARD_REPORT_SUCCESS',
  GET_COMPANY_MESSAGES_PER_BOT: 'GET_COMPANY_MESSAGES_PER_BOT',
  GET_COMPANY_MESSAGES_PER_BOT_SUCCESS: 'GET_COMPANY_MESSAGES_PER_BOT_SUCCESS',
  GET_SESSION_ATTENDANCE_TIME_REPORTS:
    'bot/GET_SESSION_ATTENDANCE_TIME_REPORTS',
  GET_SESSION_ATTENDANCE_TIME_REPORTS_SUCCESS:
    'bot/GET_SESSION_ATTENDANCE_TIME_REPORTS_SUCESS',
  GET_BOT_SESSION_TAGS: 'bot/GET_BOT_SESSION_TAGS',
  GET_BOT_SESSION_TAGS_SUCCESS: 'bot/GET_BOT_SESSION_TAGS_SUCCESS',
  GET_BOT_CUSTOM_REPORTS: 'bot/GET_BOT_CUSTOM_REPORTS',
  GET_BOT_CUSTOM_REPORTS_SUCCESS: 'bot/GET_BOT_CUSTOM_REPORTS_SUCCESS',
  CREATE_BOT_CUSTOM_REPORT: 'bot/CREATE_BOT_CUSTOM_REPORT',
  CREATE_BOT_CUSTOM_REPORT_SUCCESS: 'bot/CREATE_BOT_CUSTOM_REPORT_SUCCESS',
  GET_ERROR_LOGS_REQUEST: 'bot/GET_ERROR_LOGS_REQUEST',
  GET_ERROR_LOGS_SUCCESS: 'bot/GET_ERROR_LOGS_SUCCESS',
};

export const getClientsReports = (botId, token, filters) => ({
  type: ReportsTypes.GET_CLIENTS_REPORTS,
  payload: { botId, token, filters },
});

export const getClientsGraphicReports = (botId, token, filters) => ({
  type: ReportsTypes.GET_CLIENTS_REPORTS_GRAPHIC,
  payload: { botId, token, filters },
});

export const getBotMessagesByChannel = (botId, token, filters) => ({
  type: ReportsTypes.GET_AMOUNT_MESSAGE_CHANNEL_REPORTS,
  payload: { botId, token, filters },
});

export const getBotTotalMessagesByChannel = (botId, token, filters) => ({
  type: ReportsTypes.GET_TOTAL_MESSAGE_CHANNEL_REPORTS,
  payload: { botId, token, filters },
});

export const getBotMessages = (botId, token, filters) => ({
  type: ReportsTypes.GET_BOT_MESSAGES,
  payload: { botId, token, filters },
});

export const getBotMessagesWithAvg = (botId, token, filters) => ({
  type: ReportsTypes.GET_BOT_MESSAGES_AVG,
  payload: { botId, token, filters },
});

export const getBotSessions = (botId, token, filters) => ({
  type: ReportsTypes.GET_SESSIONS_REPORTS,
  payload: { botId, token, filters },
});

export const getBotSessionsGraphic = (botId, token, filters) => ({
  type: ReportsTypes.GET_SESSIONS_REPORTS_GRAPHIC,
  payload: { botId, token, filters },
});

export const getAttendanceTime = (botId, token, filters) => ({
  type: ReportsTypes.GET_ATTENDANCE_TIME_REPORTS,
  payload: { botId, token, filters },
});

export const getSessionAttendanceTime = (botId, token, filters) => ({
  type: ReportsTypes.GET_SESSION_ATTENDANCE_TIME_REPORTS,
  payload: { botId, token, filters },
});

export const getBotAttendanceTimeGraphic = (botId, token, filters) => ({
  type: ReportsTypes.GET_ATTENDANCE_TIME_REPORTS_GRAPHIC,
  payload: { botId, token, filters },
});

export const getCompanyBotsCount = (orgId, token) => ({
  type: ReportsTypes.GET_ORG_BOTS_COUNT,
  payload: { orgId, token },
});

export const getCompanyBotsGraphic = (orgId, token, date, timezone) => ({
  type: ReportsTypes.GET_ORG_BOTS_GRAPHIC,
  payload: { orgId, token, date, timezone },
});

export const getCompanyTotalMessageCount = (orgId, token, date, timezone) => ({
  type: ReportsTypes.GET_ORG_TOTAL_MESSAGES,
  payload: { orgId, token, date, timezone },
});

export const getCompanyTotalMessagesGraphic = (
  orgId,
  token,
  date,
  timezone
) => ({
  type: ReportsTypes.GET_ORG_TOTAL_MESSAGES_GRAPHIC,
  payload: { orgId, token, date, timezone },
});

export const getCompanyTotalSessionsCount = (orgId, token, date, timezone) => ({
  type: ReportsTypes.GET_ORG_TOTAL_SESSIONS,
  payload: { orgId, token, date, timezone },
});

export const getCompanyTotalSessionsGraphic = (
  orgId,
  token,
  date,
  timezone
) => ({
  type: ReportsTypes.GET_ORG_TOTAL_SESSIONS_GRAPHIC,
  payload: { orgId, token, date, timezone },
});

export const getCompanyTotalUsersCount = (orgId, token) => ({
  type: ReportsTypes.GET_ORG_TOTAL_USERS,
  payload: { orgId, token },
});

export const getCompanyTotalUsersGraphic = (orgId, token, date, timezone) => ({
  type: ReportsTypes.GET_ORG_TOTAL_USERS_GRAPHIC,
  payload: { orgId, token, date, timezone },
});

export const getCompanyListUsers = (orgId, token) => ({
  type: ReportsTypes.GET_ORG_LIST_USERS,
  payload: { orgId, token },
});

export const getCompanyMessagesByChannel = (orgId, token, date, timezone) => ({
  type: ReportsTypes.GET_ORG_MESSAGES_BY_CHANNEL,
  payload: { orgId, token, date, timezone },
});

export const getCompanyMessagesByChannelGraphic = (
  orgId,
  token,
  date,
  timezone
) => ({
  type: ReportsTypes.GET_ORG_MESSAGES_BY_CHANNEL_GRAPHIC,
  payload: { orgId, token, date, timezone },
});

export const getCompanyUsersBotsTotal = (orgId, token) => ({
  type: ReportsTypes.GET_ORG_USERS_BOTS,
  payload: { orgId, token },
});

export const getCompanyUsersBotsAverage = (orgId, token) => ({
  type: ReportsTypes.GET_ORG_USERS_BOTS_AVERAGE,
  payload: { orgId, token },
});

export const createDashboardReport = (body) => ({
  type: ReportsTypes.CREATE_DASHBOARD_REPORT,
  payload: body,
});

export const getDashboardReport = (userId, type, token) => ({
  type: ReportsTypes.GET_DASHBOARD_REPORT,
  payload: { userId, type, token },
});

export const updateDashboardReport = (body) => ({
  type: ReportsTypes.UPDATE_DASHBOARD_REPORT,
  payload: body,
});

export const getCompanyMessagesPerBot = (payload) => ({
  type: ReportsTypes.GET_COMPANY_MESSAGES_PER_BOT,
  payload,
});

export const getBotSessionTags = (payload) => ({
  type: ReportsTypes.GET_BOT_SESSION_TAGS,
  payload,
});

export const getBotCustomReports = (botId, token) => ({
  type: ReportsTypes.GET_BOT_CUSTOM_REPORTS,
  payload: { botId, token },
});

export const createBotCustomReport = (payload) => ({
  type: ReportsTypes.CREATE_BOT_CUSTOM_REPORT,
  payload,
});

export const getErrorLogs = (payload) => ({
  type: ReportsTypes.GET_ERROR_LOGS_REQUEST,
  payload,
});
