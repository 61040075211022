import { RdStationTypes } from '../actions/rd-station';

const initialState = {
  fields: [],
  authData: {},
};

export default function rdStationState(state = initialState, action) {
  switch (action.type) {
    case RdStationTypes.GET_FIELDS_SUCCESS:
      return { ...state, fields: action.payload };
    case RdStationTypes.GENERATE_ACCESS_TOKEN_SUCCESS:
      return { ...state, authData: action.payload };
    case RdStationTypes.RESET_AUTH_DATA:
      return { ...state, authData: null };
    default:
      return { ...state };
  }
}
